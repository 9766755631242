.cooming_soon_main {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
}
.dark.cooming_soon_main {
  background-color: #1b1f28;
}

.btn.btn-primary-v.btn-custom {
  background: #fa501c;
  border: 1px solid #fa501c30 !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  padding: 8px 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 3px !important;
  min-height: 43px;
  color: #fff;
  transition: 0.3s ease-in-out;
  min-width: 100px;
  max-width: fit-content;
}



.cooming_soon_main_inner p {
  color: #9ea4ab;
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 991px) {
  .cooming_soon_main_inner p {
    width: 100%;
  }
}
.active-link {
  color: red; /* Change this to the desired color */
}
.pricing_table_v label {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: end;
}

body.dark .cooming_soon_main {
  background-color: #1b1f28;
}

body.dark .cooming_soon_main h2 {
  color: white !important;
}
body.dark .dark-theme-img {
  display: block !important;
}
body.dark .light-theme-img {
  display: none !important;
}
.dark-theme-img {
  display: none !important;
}
.light-theme-img {
  display: block !important;
}
.cooming_soon_main h2.text-white {
  color: black !important;
}
