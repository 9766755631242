.market-trade input {
  border: 1px solid #e0e3eb;
  font-weight: 300;
}
.market_trade_input_block{
  border-radius: 5px;
  border: 1px solid transparent;
}
tr td.no-data{
  width: unset !important;
  border-top: 0 !important;
}
.market-history tr td.no-data{
  color: #696a6a;
}
.market_trade_input_block.active{
  border-color :#ff231f;
}
.market-trade input::-webkit-outer-spin-button,
.market-trade input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.market-trade input:focus {
  box-shadow: none;
  border-color: #e0e3eb;
}
.market-trade {
  // border: 1px solid #e0e3eb;
  position: relative;
  // border-left: 0px !important;
  height: auto;
  // border-right: 0px !important;
}
.trading_chart_section{
  border-bottom: 1px solid #e0e3eb;
}
.market-trade .tab-content {
  // height: 350px;
  height: fit-content;
  // height: auto;
  overflow-y: auto;
  padding: 14px;
}

.market-trade .nav .nav-item .nav-link.active {
  background: #fff;
  color: #007bff;
}

.market-trade .nav .nav-item .nav-link {
  color: #4a4a4a;
  padding: 0;
}

.market-trade .input-group-text {
  // background: #eff2f6;
  background: #fff;
  color: #4a4a4a;
  font-weight: 300;
}

.market-trade .input-group {
  margin-bottom: 14px;
}
.market-trade .nav-tabs .nav-link.active,
.market-trade .nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #007bff;
}
.market-trade .nav-tabs .nav-link {
  color: #4a4a4a;
  padding-bottom: 0px;
  padding-top: 12px;
}
.market-trade .nav-tabs {
  border-bottom: 1px solid transparent;
}
.market-trade-list li a {
  background: #eff2f6;
  color: #4a4a4a;
  font-weight: 300;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;

  display: flex;
  justify-content: center;
  font-size: 12px;
}
.market-trade-list li span {
    color: #959597;
    font-size: 12px;
    margin-left: 7px;
    margin-right: 7px;
    padding-top: 3px;
    line-height: 12px;
}
.market-trade-list li {
  display: inline-block;
  width: 25%;
  // margin-right: 7px;
  // margin-bottom: 7px;
  // padding-left: 11px;
}
.market-trade-list li:last-child {
  margin-right: 0;
}
.market-trade-buy .market-trade-list li a:hover {
  text-decoration: none;
  // background: #26a69a;
  background: #1EC6F6;
  color: #fff;
}
.market-trade-sell .market-trade-list li a:hover {
  text-decoration: none;
  // background: #ef5350;
    background: #1EC6F6;
  color: #fff;
}

.market-trade button.buy,
.market-trade a.buy,
.market-trade a.sell,
  .market-trade button.sell {
  // background: #26de81;
  background: #03BA8E;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  padding-bottom:8px;
  // margin-top: 30px;
  width: 100%;
  // box-shadow: 0px 0px 15px 0px #26de81ad;
  border-radius: 8px;
}
.market-trade a.sell,
  .market-trade button.sell {
  background: #E33442;
  // box-shadow: 0px 0px 15px 0px #ff231fad;
}
.market-trade p {
  margin: 5px 0;
  font-size: 13px;
  color: #4a4a4a;
}
.market-trade p span {
  float: right;
}

.market-trade-list {
  margin-bottom: 15px;
  width: 100%;
}
.market-trade-item {
  width: 100%;
}

.market-trade-buy,
.market-trade-sell {
  width: 50%;
}

.market-trade-buy {
  padding: 0 15px 0 0;
}

.market-trade-sell {
  padding: 0 0 0 15px;
  border-left: 1px solid #e0e3eb;
}

.active_percentage_buy {
  text-decoration: none !important;
  // background: #26a69a !important;
  background: #1EC6F6 !important;
  color: #fff !important;
}
.dark .active_percentage_buy {
  // background: #26de81 !important;
  background: #1EC6F6 !important;
}
.active_percentage_text{
color: #1EC6F6 !important;
}
.active_percentage_sell {
  text-decoration: none !important;
  // background: #ef5350 !important;
  background: #1EC6F6 !important;
  color: #fff !important;
}
.dark .active_percentage_sell {
  // background: #ff231f !important;
  background: #1EC6F6 !important;
}

.market-trade button:disabled{
  cursor: not-allowed;
}
.modal-content {
  color:#4a4a4a;
  background-color:#eff2f6;;
}


