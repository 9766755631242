.p2p .p2p_main_banner_section {
  // padding-top: 1.5rem !important;
  padding-top: 1.5rem;
}
.p2p .p2p_main_banner_section {
  padding-left: 15px;
  padding-right: 15px;
}
.p2p .p2p_main_banner_section .nav.nav-tabs {
  border: none !important;
}
.p2p_main_banner_section .nav-tabs .nav-link.disabled {
  color: #ffffff !important;
  background-color: transparent;
  /* border-color: transparent; */
  // border-right: 1px solid #768088 !important;
  border-radius: 0px !important;
  font-size: 2rem !important;
  padding-top: 0px !important;
}
.p2p_main_banner_section a.nav-link {
  //   padding-bottom: 0px !important;
  //   padding-top: 10px !important;
  font-size: 14px !important;
  color: #9ea4ab;
  //   font-size: 15px !important;
  font-weight: 400 !important;
  // padding: 10px 0px 0px 0px !important;
  // margin: 0 13px !important;
  position: relative;
}
.dark .p2p_main_banner_section .nav-tabs .nav-link.active {
  background: transparent;
  color: #fa501c !important;
  font-size: 14px !important;
  //   border-bottom: 1px solid #fa501c !important;
  border: 1px solid rgba(65, 70, 77, 0.5019607843) !important;
}
.p2p a.tablinks.active {
  background: transparent;
  color: #fa501c !important;
  font-size: 15px !important;
  //   border-bottom: 1px solid #fa501c !important;
}
.p2p a.tablinks {
  color: #9ea4ab;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  margin: 0 13px;
  position: relative;
  font-size: 14px !important;
}

.p2p_main_banner_section .p2p-transactions-form label {
  background: none;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px;
  border-radius: 50px;
  cursor: pointer;
  padding: 4px 25px;
  border: 1px solid #ffffff6e;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 300;
}

.p2p_main_banner_section
  .p2p-transactions-form
  .p2p-transaction-type
  label:hover {
  background: #fa501c24 !important;
  border: 1px solid #fa501c24 !important;
  color: #fa501c !important;
}

.dark
  .p2p_main_banner_section
  .p2p-transactions-form
  .p2p-transaction-type
  input:checked
  + label {
  background: #fa501c24 !important;
  color: #fa501c !important;
  transition: 0.3s ease-in-out;
  border: 1px solid #fa501c24 !important;
  /* background: none;
  background-image: none;
  background-image: none;
  background-image: none; */
  /* background-image: linear-gradient(to right, #5865b9 0%, #1c91e2 51%, #22dcfd 100%); */
  padding: 4px 25px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
}

// .p2p-transactions-form .p2p_transactions_form_buy_sell input:checked + label {
//   color: #04b98e !important;
//   background-color: #04b98e3d !important;
// }

.dark
  .p2p_main_banner_section
  .p2p-transactions-form
  .p2p_transactions_form_buy_sell
  input:checked
  + label {
  color: #04b98e !important;
  background-color: #04b98e3d !important;
}

.dark
  .p2p_main_banner_section
  .p2p-transactions-form
  .p2p_transactions_form_buy_sell
  input:checked
  + label.sell-btn-label {
  background-color: #fa501c24 !important;
  color: #fa501c !important;
  border: 1px solid rgba(250, 80, 28, 0.1411764706) !important;
}
body.dark .p2p_main_banner_section .btn.btn-gray-v {
  background: #2c2e33;
  border: 1px solid #41464d;
  color: #fff;
}
.vynksafe_new_style.dark .p2p-transactions-form label.btn.btn-gray-v {
  font-weight: 400;
  color: #fff !important;
}

/* FORM */

.saving-transactions-form .transaction-type input,
.saving-transactions-form .saving-time-period input,
.saving-transactions-form .payment-type input {
  display: none;
}

.saving-transactions-form label {
  background: none;
  height: 100%;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px;
  border-radius: 50px;
  cursor: pointer;
  padding: 4px 25px;
  /* border: 1px solid #ffffff6e; */
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 400;
  padding: 4px !important;
  min-width: 80px;
  border: 1px solid #fff0 !important;
}

.dark .vynksafe_new_style .p2p-transactions-form label.btn.btn-gray-v {
  font-weight: 400;
  color: #9ea4ab;
}

.saving-transactions-form .transaction-type input:checked + label,
.saving-transactions-form .saving-time-period input:checked + label,
.saving-transactions-form .payment-type input:checked + label {
  border: 1px solid #5468bc;
  background: none;
  background-image: none;
  background-image: none;
  background-image: linear-gradient(
    to right,
    #5865b9 0%,
    #1c91e2 51%,
    #22dcfd 100%
  );
  padding: 4px 25px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 300;
}

.wallet_network_select.custom_Curency-box.css-b62m3t-container {
  min-width: 180px !important;
  font-size: 14px !important;
}

.p2p_main_banner_section .refresh-btn {
  font-size: 14px;
  background: #fa501c24;
  color: #131722;
  border: 1px solid #fa501c24 !important;
}
.dark .p2p_main_banner_section .refresh-btn {
  font-size: 14px;
  background: #2c2e3369;
  border: 1px solid #41464d80 !important;
  color: #9ea4ab;
}
.refresh-btn {
  background: none;
  border: 1px solid #ffffff47;
  border-radius: 3px;
  padding: 6px 15px;
  color: #fff;
}

.p2p_main_banner_section .refresh-btn:hover {
  background: #fa501c;
  color: #fff;
}
.p2p-transactions-form .p2p-transaction-type label:hover {
  background: #fa501c24 !important;
  border: 1px solid #fa501c24 !important;
  color: #fa501c !important;
}
.p2p-transactions-form .p2p_transactions_form_buy_sell input:checked + label {
  color: #04b98e !important;
  background-color: #04b98e3d !important;
}

.p2p-transactions-form
  .p2p_transactions_form_buy_sell
  input:checked
  + label.sell-btn-label {
  background-color: #fa501c24 !important;
  color: #fa501c !important;
}
.horizontal_border {
  background: #ffffff42;
  width: 1px;
  height: 30px;
}
.p2p_manuel_inner_border {
  border-right: 1px solid #ffffff3b;
}

.p2p_manuel_inner_border p {
  line-height: normal;
}
.p2p_manuel_inner_border {
  border-right: none;
  border-bottom: 1px solid #ffffff3b;
  padding-bottom: 25px;
}

.p2p_main_banner_section ul.p2p_browse_trades_offers_des {
  flex-direction: row;
}
// .dark-gray-bg-v {
//   background-color: #131722 !important;
// }

.p2p_main_banner_section .load_more_btn {
  border-radius: 3px;
  padding: 10px;
  min-width: 120px;
  font-size: 14px;
  line-height: normal;
  background: #fa501c24;
  color: #131722;
  border: 1px solid #fa501c24 !important;
}
.dark .p2p_main_banner_section .load_more_btn {
  border-radius: 3px;
  padding: 10px;
  min-width: 120px;
  font-size: 14px;
  color: #9ea4ab;
  line-height: normal;
  background: #2c2e33;
  border: 1px solid #41464d !important;
}
.p2p_main_banner_section .load_more_btn:hover,
.p2p_main_banner_section .load_more_btn:active,
.p2p_main_banner_section .load_more_btn:focus {
  background: #fa501c !important;
  border: 1px solid #fa501c !important;
  color: #fff !important;
}
.css-13cymwt-control {
  box-shadow: none !important;
}

.p2p_main_banner_section .card {
  background-color: transparent;
  border-left: none !important;
  border-bottom: none !important;
  border-right: none !important;
}
.p2p_main_banner_section .card-header {
  padding: 0px !important;
}
.dark .p2p_mainTable {
  background-color: #1b1f28;
}
.p2p_mainTable {
  background-color: #ffeee9;
}

.dark .p2p_main_banner_section .card {
  border-top: 2px solid #ffffff17;
  padding-top: 8px;
  padding-bottom: 8px;
}
.offer_list_head h6 span {
  font-size: 12px !important;
}
.gray_colour {
  /* color: #9e9e9e; */
  /* color: #ffffff91 !important; */
  color: #9ea4ab !important;
  font-size: 14px;
}

.p2p_list_style.p2p_list_style_banktransfer {
  /* gap: 15px 40px; */
  gap: 3px 30px;
  display: flex;
  flex-wrap: wrap;
}
.p2p_list_style.p2p_payment_width_i {
  padding-left: 20px;
}
.p2p_list_style ul li {
  color: #fff;
}
.p2p_list_style ul li::before {
  content: "\2022";
  color: #616ad5;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 0em;
  font-size: 20px;
}
.dark .p2p_list_style.p2p_list_style_banktransfer label {
  color: #fff;
}
.p2p_list_style.p2p_list_style_banktransfer label {
  /* font-size: 11px; */
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  /* background: #7474ce6e; */
  /* border-radius: 50px; */
  /* padding: 1px 8px; */
  margin-bottom: 5px;
  position: relative;
  padding-left: 22px;
}
.p2p_list_style.p2p_list_style_banktransfer label::before {
  content: "";
  background-image: url("https://mlm.pixelsoftwares.com/vynksafe/vynksafe.com/assets/img/circle_bullet.svg");
  background-size: contain;
  background-position: center;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 4px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  /* background-color: #7474ce !important; */
  background-color: #2d3340 !important;
  border-radius: 0.25rem;
}
.p2p_list_style.p2p_list_style_banktransfer
  .bs-tooltip-auto[x-placement^="top"]
  .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  /* border-top-color: #7474ce; */
  border-top-color: #2d3340;
}

.p2p_buy_btn_bg {
  /* background-image: linear-gradient(to right, #5a2aac 0%, #79149d 51%, #79149d 100%); */
  /* background: #31BE83; */
  background: #04b98e;
  /* padding: 10px; */
  padding: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  /* width: 150px; */
  width: 110px;
  border-radius: 3px;
  line-height: normal;
  color: #fff !important;
}

.p2p_buy_btn_bg:hover {
  background: #0ecb81;
}

.p2p_sell_btn_bg {
  background: #dc3545;
  padding: 7px;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  min-width: 110px;
  border-radius: 3px;
  color: #fff !important;
}

.p2p_sell_btn_bg,
.p2p_formsell_btn_bg {
  /* background: #dc3545 !important; */
  background: #fa501c !important;
}

.dark .p2p_sell_btn_bg:hover,
.dark .p2p_formsell_btn_bg:hover {
  background: #fff !important;
  color: #fa501c !important;
  border: 1px solid #fff;
}

.p2p_limited_btn_bg {
  background: none;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  width: 150px;
  border: 1px solid #ffffff54;
  border-radius: 3px;
}

.p2p_limited_btn_bg:hover {
  // background-image: linear-gradient(
  //   to right,
  //   #5a2aac 0%,
  //   #79149d 51%,
  //   #79149d 100%
  // );
  padding: 10px 18px;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  width: 150px;
  border: 1px solid #fff0;
  color: #fff;
}

.p2p_collapse_body p {
  line-height: 22px;
}
.accordion.p2p_accordion.offer_list p {
  font-size: 14px;
  word-break: break-word;
}

.p2p_collapse_body .justify_content_mobile_between h6,
.p2p_collapse_body .justify_content_mobile_between h6 span {
  font-size: 14px;
  word-break: break-word;
}
.dark .p2p_form_section .form-group label {
  font-size: 14px;
  color: white;
}
.p2p_form_section .form-group label {
  font-size: 14px;
  color: #131722;
}
.dark .p2p_collapse_body.card-body {
  background-color: #131722;
}

.dark a {
  color: white;
  text-decoration: none;
  // background-color: transparent;
}
a {
  color: rgb(39, 38, 38);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.p2p_form_section {
  /* background: #4a0d635e; */
  padding: 30px;
  /* border-radius: 10px; */
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fa501c30 !important;
}
.dark .p2p_form_section {
  /* background: #4a0d635e; */
  padding: 30px;
  /* border-radius: 10px; */
  border-radius: 3px;
  background: #1b1f28;
  border: 1px solid #41464d !important;
}

.p2p_form_section input.form-control {
  background: none;
  border: 1px solid #ffffff2b;
  border-radius: 10px;
  height: 45px;
}

.p2p_form_section input.form-control::placeholder {
  color: #696a6f !important;
}
.p2p_accordion .p2p_form_section .p2p_form_inner_btn button.p2p_cancel_btn {
  width: 48%;
  background: none;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ffffff36;
}
.p2p_accordion
  .p2p_form_section
  .p2p_form_inner_btn
  button.p2p_cancel_btn:hover {
  background: #ffffff36;
}

.p2p_accordion
  .p2p_form_section
  .p2p_form_inner_btn
  button.p2p_cancel_btn:hover {
  background: #ffffff36;
}
.dark .p2p_form_section {
  padding: 15px;
  margin-top: 20px !important;
  background: #1b1f28 !important;
  border: 1px solid #41464d !important;
}
.p2p_form_section .p2p_will_receive_input input.form-control {
  background: none;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 15px;
}
.dark .p2p_form_section .p2p_will_receive_input input.form-control {
  background: none;
  border: 1px solid #ffffff2b !important;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 15px;
}
.p2p_will_receive_input span {
  right: 0px;
  padding-left: 0;
  padding-right: 15px;
  position: absolute;
  font-size: 16px;
}

.p2p_will_receive_input span button {
  background: none;
  border: none;
  color: #fa501c;
}
.p2p_will_receive_input {
  width: auto;
  position: relative;
  align-items: center;
  display: flex;
}
.p2p_form_section span#basic-addon1 {
  background: transparent !important;
}

.p2p_form_section p,
.p2p_form_section span {
  // font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  color: #000000;
  margin: 0px;
  // font-family: "Poppins Light";
  transition: color 0.4s ease;
}
.dark .p2p_form_section p,
.dark .p2p_form_section span {
  // font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  color: #e4e4e4;
  margin: 0px;
  // font-family: "Poppins Light";
  transition: color 0.4s ease;
}
.p2p_form_inner_btn.d-flex {
  justify-content: space-between;
}

.p2p_form_inner_btn button {
  width: 48%;
  /* background-image: linear-gradient(to right, #5a2aac 0%, #79149d 51%, #79149d 100%); */
  background: #04b98e;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  font-size: 14px;
  border-radius: 3px;
  color: #fff !important;
}

.p2p_form_inner_btn button:hover {
  background: #07b36f;
}
.p2p_form_inner_btn button.p2p_cancel_btn:hover {
  background: #fa501c;
  color: #fff !important;
}

.dark .p2p_form_inner_btn button.p2p_cancel_btn {
  width: 48%;
  background: #2c2e3369;
  border: 1px solid #41464d80 !important;
  border-radius: 3px !important;
  padding: 10px;
  font-size: 14px;
  color: #ffff !important;
}
.p2p_form_inner_btn button.p2p_cancel_btn {
  width: 48%;
  background: #fff4f1;
  color: #000000 !important;
  border: 1px solid #fa501c30 !important;
  border-radius: 3px !important;
  padding: 10px;
  font-size: 14px;
}
button#purchaseBtn_3 {
  border: none;
}
.offer_list_head.card-header {
  border-bottom: none;
}

.p2p_filter_section .tab.active_trades_main_tabs .tablinks::after,
.p2p_filter_section .tab.active_trades_main_tabs .tablinks:hover::after {
  position: absolute;
  bottom: -7px;
  content: "";
  background: #fa501c;
  height: 1px;
  width: 0px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out 0s;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}
.dark .p2p_filter_section .tab.active_trades_main_tabs .tablinks.active,
.dark .p2p_filter_section .tab.active_trades_main_tabs .tablinks:hover {
  color: #fa501c !important;
}
.dark .p2p_filter_section .tab.active_trades_main_tabs .tablinks {
  color: #9ea4ab !important;
}
.p2p_filter_section .tab.active_trades_main_tabs .tablinks.active::after {
  width: 100%;
}
.horizontal_border {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.p2p .tab.active_trades_left_btn.active_trades_main_tabs {
  margin-bottom: 12px;
}
.text-right {
  text-align: right !important;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.transfer_btn {
  color: #ed4442 !important;
}

.dark a.theme-btn-2.theme-btn.p2p_buy_btn_bg {
  background-color: #04b98e !important;
}
.pending {
  color: #f4d52a;
}
.completed {
  color: #2cbd37;
}

.offer_status_summary_section .p2p_offer_category ul li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  height: 22px;
}
.dark .offer_status_summary_section .p2p_offer_category ul li span {
  font-size: 14px;
  text-align: right;
  word-break: break-all;
  color: #e4e4e4;
  line-height: 28px;
  // font-family: 'Poppins Light';
  transition: color 0.4s ease;
  font-weight: 300;
}
a .transfer_btn {
  color: #ed4442 !important;
}
.p2p_create_offer_border {
  border-bottom: 3px dotted #ffffff2e;
  margin-bottom: 36px;
}
.wallet_network_select .custom_select__control {
  border-color: rgba(250, 80, 28, 0.1882352941) !important;
  padding: 2px;
  background: #fff4f1 !important;
}
.dark.wallet_network_select .custom_select__menu {
  background: #0e111c !important;
}
.wallet_network_select .custom_select__menu {
  background: #fff !important;
}
.p2p_search input {
  border-color: rgba(250, 80, 28, 0.1882352941) !important;
  background: #fff4f1 !important;
  height: 42px !important;
}
.order_status_chat_section .card-footer {
  padding: 0px;
  border: none;
  background: none;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.text-muted {
  color: #6c757d !important;
}
#chat2 .form-control {
  border-color: #ffffff38;
  background: none;
  font-size: 14px;
  height: auto;
  // padding: 10px 20px;
  padding: 5px 12px;
  scrollbar-width: thin;
}
.dark #chat2 .form-control {
  border-color: #ffffff38 !important;
  background: none !important;
  font-size: 14px;
  height: auto;
  // padding: 10px 20px;
  padding: 5px 12px;
  scrollbar-width: thin;
}
#message_txt {
  border-radius: 5px;
}
textarea#message_txt:focus {
  box-shadow: none;
}
.file_attach_div {
  display: inline;
  width: auto;
}
.file_attach_input {
  position: absolute;
  width: 14px;
  opacity: 0;
  cursor: pointer !important;
}
#sendMessage_btn {
  background-color: #fa501c;
  padding: 5px;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}
.user_chat_chat {
  width: 100%;
}
.client_chat_chat {
  width: 100%;
}
.user_chat_chat p.text-white {
  color: #fff !important;
  background: #ffffff0f;
  border-radius: 8px 8px 0px 8px;
  text-align: left;
  line-height: normal;
  width: fit-content;
  float: right;
  max-width: 344px;
  margin-left: 8px;
  white-space: pre-wrap;
  word-break: break-word;
}
.chat_timing.small {
  // float: right;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  line-height: initial;
  padding: 2px 2px 0px 0px;
}
.client_chat_chat p {
  border-radius: 8px 8px 8px 0px;
  max-width: 344px;
  background: rgb(250, 80, 28) none repeat scroll 0% 0%;
  line-height: normal;
  padding: 10px 10px;
  margin-left: 8px;
  width: fit-content;
  white-space: pre-wrap;
  word-break: break-word;
}
.client_chat_chat p.text-muted {
  background: none;
  line-height: normal;
  padding: 2px 10px 6px 0px;
}

.p2pOfferViewModal .p2p_paymentReleaseModal_btns button {
  width: 48% !important;
  font-size: 16px;
  min-width: 150px;
}
.p2pOfferViewModal .p2p_paymentReleaseModal_btns {
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.dark .p2pOfferViewModal .p2p_paymentReleaseModal_btns button.p2p_buy_btn_bg {
  background: #fa501c !important;
  color: #fff !important;
}
.dark
  .p2pOfferViewModal
  .p2p_paymentReleaseModal_btns
  button.p2p_buy_btn_bg:hover {
  background: #fff !important;
  color: #fa501c !important;
}

.dark .p2p_create_offer_step_btn .p2p_buy_btn_bg.previous_button {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #9ea4ab !important;
}
.p2p_create_offer_step_btn .p2p_buy_btn_bg.previous_button {
  background: #fff4f1 !important;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
  color: #fa501c !important;
}
.dark .p2p_create_offer_step_btn .p2p_buy_btn_bg.previous_button:hover {
  background: #fa501c !important;
  color: #fff !important;
}
.p2p_create_offer_step_btn .p2p_buy_btn_bg.previous_button:hover {
  background: #fff !important;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
  color: #fa501c !important;
}

.p2p_create_offer_step_btn .p2p_buy_btn_bg.next_button {
  background: #fa501c !important;
  color: #fff !important;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
}
.p2p_create_offer_step_btn .p2p_buy_btn_bg.next_button:hover {
  background: #fff !important;
  color: #fa501c !important;
}

.dark .p2p_buy_create_offer_main .buttons button#step_1_btn:focus {
  background-color: #fa501c !important;
  color: #fff !important;
}
textarea#feedback_text {
  height: auto !important;
}
.Restricted {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: white !important;
}
.Restricted:hover {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: white !important;
}

//first modal
.p2p_modal_all-steps {
  text-align: center;
  margin-bottom: 20px;
}
.dark #p2pregForm {
  background-color: #131722;
  margin: 0px auto;
  padding: 0px;
  border-radius: 3px;
}
#p2pregForm {
  background-color: #ffffff;
  margin: 0px auto;
  padding: 0px;
  border-radius: 3px;
}
.p2p_modal_step.active {
  opacity: 1;
}
.p2p_modal_step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #1c1f59;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}
.text-center {
  text-align: center !important;
}

.p2p_quick_tutorial_steps_modal p {
  font-size: 12px;
  line-height: normal;
  color: #9e9e9e;
}
.p2p_buy_crypto_ul .p2p_buy_crypto_ul_after::after {
  position: relative;
  content: "";
  left: 23px;
  right: 0;
  margin: 0;
  border-left: 3px dotted #c2c0c07a;
  width: 10px;
  bottom: 0;
  display: block;
  /* height: 40px; */
  height: 100%;
  z-index: 0;
}
.modal-height {
  max-width: 400px !important;
}


.p2p_quick_tutorial_steps_modal .p2p_buy_crypto_ul .p2p_buy_crypto_ul_after img,
.p2p_quick_tutorial_steps_modal .p2p_modal_tab ul li img {
  z-index: 999 !important;
  position: relative;
}
