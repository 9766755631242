.market-pairs {
  border-bottom: 1px solid #e0e3eb;
  padding-top: 14px;
  border-radius: 2px;
  //height: 40%;
  //height: 450px;
  //overflow: auto;
}
.market-pairs .pair_tab_block .mytab_content_block {
  height: 360px;
  overflow: auto;
}

.market-pairs .input-group {
  padding: 0 14px;
}
.market-pairs span {
  background: transparent;
}
.market-pairs input {
  border-color: #e0e3eb;
  //border-left: 0;
  font-weight: 300;
}
.login_first_block {
  border: 1px solid #e0e3eb;
  border-radius: 5px;
}
.dark .login_first_block {
  border-color: #393c42;
}
.without_login_fav .inner_login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.dark .without_login_fav .inner_login p {
  color: #fff;
}
.without_login_fav .inner_login p {
  color: #838897;
  padding: 0 5px;
  margin-bottom: 0;
  margin-top: 0;
}
// .without_login_fav a {
//   // color: #838897;
// }
// .dark .without_login_fav a {
//   // color: #fff;
// }
.market-trade .without_login_fav p {
  margin-top: 2px;
}
.market-pairs .without_login_fav {
  margin: 20px 0;
}
.market-pairs input:focus {
  box-shadow: none;
  border-color: #e0e3eb;
}
.market-pairs .nav {
  margin-top: 14px;
  /*background: #f5f9fc;*/
  border-bottom: transparent;
}
.market-pairs .nav-link {
  color: #4a4a4a;
  padding: 10px 10px;
}
.market-pairs .nav-link:hover {
  border-color: transparent;
}
.market-pairs .nav-link.active {
  color: #fa501c !important;
  background: transparent !important;
  border-color: transparent !important;
}
.table {
  margin-bottom: 0;
}
.table td {
  font-size: 12px;
  //padding: 10px 15px;
  padding: 4px 15px;
  cursor: pointer;
}
.market-pairs tbody tr td i {
  color: #75869696;
}
.star-active tbody tr td i {
  color: #007bff;
}
.star_active {
  /*color: #007bff !important;*/
  color: #1ec6f6 !important;
}
.market-pairs thead tr th,
.market-pairs tbody tr td {
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 12px;
  padding: 7px 10px !important;
}
.market-pairs thead tr th:nth-child(2),
.market-pairs thead tr th:last-child,
.market-pairs tbody tr td:last-child,
.market-pairs tbody tr td:nth-child(2) {
  text-align: right;
}
// .market-pairs tbody {
//   height: 800px;
//   overflow-y: auto;
//   display: block;
// }
// .no-fluid .market-pairs tbody {
//   height: 815px;
// }
.market-pairs table,
.market-pairs tr {
  width: 100%;
  // table-layout: fixed;
}
// .market-pairs tr {
//   float: left;
//   clear: both;
//   overflow: hidden;
// }
// .market-pairs thead th,
// .market-pairs tbody td {
//   width: 65%;
// }
.market-pairs .tab-content {
  overflow: auto;
  scrollbar-width: none;
}
.market-pairs .nav {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.tabbar_block_scroll {
  position: relative;
}
.market-pairs .tab-content .tab-pane {
  height: 100%;
}
.mytabs_block .nav.nav-pills {
  position: relative;
  scrollbar-width: none;
  padding: 0 10px;
}
.mytabs_block .nav.nav-pills::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
.custom_tab_btn {
  top: 0;
  border: 0;
  z-index: 3;
  padding: 0;
  width: 25px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.custom_tab_btn.next {
  right: 0;
  background: linear-gradient(-90deg, #758595, transparent);
}
.custom_tab_btn.prev {
  left: 0;
  background: linear-gradient(90deg, #758595, transparent);
}
.dark .custom_tab_btn.next {
  background: linear-gradient(-90deg, rgb(42 46 57), transparent);
}
.dark .custom_tab_btn.prev {
  background: linear-gradient(90deg, rgb(42 46 57), transparent);
}
