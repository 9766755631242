.settings .card {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  border-radius: 15px;
  border: none;
  margin-bottom: 15px;
}

.settings-nav {
  //box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  //border-radius: 5px;
}

.settings-nav .nav-item {
  width: 100%;
}

.settings-nav .nav-link {
  border-radius: 0;
  //text-transform: uppercase;
  color: #18214d;
  //font-weight: 600;
  margin: 2px 0;
  padding: 10px 20px;
}

.settings-nav .nav-link i {
  margin-right: 10px;
}

.wallet .settings-nav .nav-link i {
  margin-right: 5px;
}

.settings .card-title {
  font-size: 20px;
  margin-bottom: 30px;
}

.settings-profile .col-md-6 {
  margin-bottom: 20px;
}

.settings-profile input,
.settings-profile select {
  border: 1px solid #e0e3eb;
  border-radius: 5px;
  font-size: 14px;
  height: 45px;
}
.settings-profile input:focus,
.settings-profile select:focus{
  box-shadow: none !important;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: none !important;
  box-shadow: none !important;
  outline: none;
}
.settings-profile input[type="submit"] {
  background: #fa501c;
  color: #fff;
  font-weight: 600;
  padding: 10px 25px;
  height: inherit;
  font-size: 16px;
  margin-top: 10px;
}
.settings-profile input[type="submit"]:hover {
  background: #fff;
  color: #fa501c;
  border-color: #fa501c;
}
.dark .settings-profile input[type=submit]:hover {
  border: 1px solid #fff;
}

.settings-profile .custom-file {
  display: inline-block;
  width: 20%;
  margin-left: 20px;
}

.settings-profile .custom-file label {
  border: 1px solid #e0e3eb;
}

.settings-profile .custom-file label:after {
  background: #fa501c;
  color: #fff;
  font-weight: 600;
}

.settings .wallet .nav-pills img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.settings .wallet .nav-pills h2 {
  margin-bottom: 0;
  line-height: 1;
  color: #18214d;
  font-size: 16px;
}

.settings .wallet .nav-pills p {
  margin-bottom: 0;
  color: #18214d;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 10px;
}

.settings .wallet .nav-pills h3 {
  font-family: 1rem !important;
  margin-bottom: 0;
}

.settings .wallet .nav-pills a.active {
  color: #fa501c !important;
}

.settings .wallet .nav-pills a {
  border-top: 1px solid #f0f3fa;
  padding: 10px;
  color: #6c757d !important;
  // background: transparent !important;
}

.settings .wallet .nav-pills a:first-child,
.settings-nav .nav-link:first-child {
  border-top: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.settings .wallet .nav-pills a:last-child,
.settings .settings-nav .nav-link:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.settings .wallet .nav-pills h3 {
  color: #18214d;
  margin-bottom: 0;
}

.settings .wallet .nav-pills {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  border-radius: 5px;
}

.settings .wallet .nav-pills a {
  border-radius: 0;
}

.settings .wallet .nav-pills a.active h2,
.settings .wallet .nav-pills a.active h3,
.settings .wallet .nav-pills a.active p {
  color: #ffffff;
}

.settings .wallet .tab-content li i {
  font-size: 22px;
  // color: #0f7dff;
  background: rgba(15, 125, 255, 0.13);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 45px;
  margin-right: 15px;
}

.settings .wallet .tab-content h2 {
  margin-bottom: 0;
  font-size: 18px;
}

.settings .wallet .tab-content h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.settings .wallet .tab-content li:first-child {
  border-bottom: 1px solid #f0f3fa;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.settings .wallet .tab-content button.green {
  background: #26de81;
  color: #ffffff !important;
  box-shadow: 0px 0px 15px 0px #26de81ad;
}

.settings .wallet .tab-content button.red {
  background: #ff231f;
  box-shadow: 0px 0px 15px 0px #ff231fad;
  color: #ffffff !important;
}

.settings .wallet .tab-content button {
  padding: 12px 60px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.settings .wallet .tab-content button:focus {
  box-shadow: none;
}

.settings .wallet .tab-content ul {
  margin: 0 0 0px;
}

.settings .wallet-address input {
  padding: 25px 15px;
  font-size: 14px;
}

.settings .wallet-address .input-group-prepend button {
  padding: 13px 20px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.settings .wallet-address input {
  border: 1px solid #e0e3eb;
}

.settings .wallet-history i {
  margin-left: 14px;
}

.settings .wallet-address .input-group {
  padding-top: 30px;
}

.settings .wallet .tab-content h2.wallet-title {
  color: #18214d;
  font-size: 16px;
}

.settings-notification li {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f0f3fa;
  //align-items: center;
}

.settings-notification li:first-child {
  padding-top: 0;
}

.settings-notification li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.settings-notification li span {
  color: #5e728f;
}

.settings-notification li p {
  //margin-bottom: 0;
}

.settings .custom-control-label {
  cursor: pointer;
}

.wallet-history .ion-md-trash {
  font-size: 16px;
  cursor: pointer;
}

.wallet-history {
  overflow-x: auto;
}

.custom-control-label {
  color: #000 !important;
}

.dark .custom-control-label {
  color: #fff !important;
}

// .dark .sign_up_page .form-access .custom-control-label::before {
//   border-color: transparent !important;
// }

.sign_up_page .form-access .custom-control-label::before {
  border-color: #cbd2df !important;
}

.sign_up_page .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}
.sign_up_page .custom-control-input:focus .custom-control-label::before {
  box-shadow: none !important;
}

.sign_up_page .custom_select-box input {
  height: unset !important;
}
.form-access .form-group input, .dark .form-access .sign_up_page .custom_select-box input {
  height: unset !important;
}
.sign_up_page .custom_select-box span {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.option_block {
  display: flex;
  align-items: center;
}

.option_block img {
  margin-right: 10px;
  max-width: 20px;
}

.sign_up_page .custom-control {
  z-index: 0;
}

// .custom_selectbox .css-13cymwt-control {
//   border-color: #e0e3eb;
// }
// .custom_selectbox .css-13cymwt-control:hover {
//   border-color: #e0e3eb;
// }
// .custom_selectbox .css-t3ipsp-control:hover {
//   border-color: #e0e3eb;
//   box-shadow: unset;
// }
// .custom_selectbox #react-select-5-placeholder,
// .custom_selectbox #react-select-3-placeholder {
//   color: #aeb2c1;
// }
// .dark .custom_selectbox #react-select-5-placeholder,
// .dark .custom_selectbox #react-select-3-placeholder {
//   color: #69727a;
// }
// .custom_selectbox .css-1u9des2-indicatorSeparator {
//   background-color: rgba(174, 178, 193, 0.388);
// }
// .dark .custom_selectbox .css-13cymwt-control {
//   border-color: #2a2e39;
//   background: #2a2e39;
// }
// .dark .sign_up_page .form-access .custom_selectbox input {
//   border: unset !important;
//   border-color: transparent !important;
//   color: #ffffff !important;
// }
// .dark .sign_up_page .custom_selectbox .css-t3ipsp-control{
//   background-color: #2a2e39 !important;
// }
// .dark .sign_up_page .custom_selectbox span{
//   // color: #69727a;
//   // font-weight: 400;
// }
// .dark .custom_selectbox .css-t3ipsp-control{
//   box-shadow: unset;
//   border-color: transparent;
// }
// .dark .custom_selectbox #react-select-5-listbox,
// .dark .custom_selectbox #react-select-3-listbox{
//   background-color: #2a2e39 !important;
//   display: block !important;
// }

// .dark .custom_selectbox #react-select-9-listbox,
// .dark .custom_selectbox #react-select-7-listbox,
// .dark .custom_selectbox #react-select-3-listbox > div > div,
// .dark .custom_selectbox #react-select-5-listbox > div > div{
//   background-color: #2a2e39 !important;
// }

// .custom_selectbox #react-select-3-listbox > div > div,
// .custom_selectbox #react-select-5-listbox > div > div{
//   background-color: transparent;
// }

// .dark .custom_selectbox #react-select-5-listbox > div >  div:hover,
// .dark .custom_selectbox #react-select-3-listbox > div > div:hover{
//   background-color: #1d1f25 !important;
// }
// .custom_selectbox #react-select-5-listbox > div >  div:hover,
// .custom_selectbox #react-select-3-listbox > div > div:hover{
//   background-color: #86a2e4 !important;
// }
// // .dark .option_block{
// //   background:#2a2e39 ;
// // }
// .dark .option_block span{
//   color: #fff !important;
// }
// .option_block span{
//   color: #000 !important;
// }
.sign_form_submit:disabled {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.password_block {
  position: relative;
}

.form-access .password_block input {
  padding-right: 40px;
}

.password_block .form-control.is-invalid {
  background: unset;
}

.password_block .form-control.is-invalid ~ .password_hideshow_btn {
  top: 35%;
}

.form-access .password_hideshow_btn {
  right: 10px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}

.form-access .password_hideshow_btn svg path {
  fill: #b2b2b2;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.form-access .password_hideshow_btn:hover svg path {
  fill: #2a2e39;
}

.dark .form-access .password_hideshow_btn svg path {
  fill: #858585;
}

.dark .form-access .password_hideshow_btn:hover svg path {
  fill: #d1d1d1;
}

.countdown-text p {
  margin: 0;
}

.countdown-text .btn {
  padding: 0;
  color: #007bff;
  margin: 0;
  margin-left: 5px;
  width: unset;
}

.countdown-text .btn:disabled {
  cursor: not-allowed;
}

.countdown-text .c_inner {
  margin-top: 15px;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.resend_btn h2 {
  margin-bottom: 0;
  margin-top: 10px;
}

.error-message {
  font-size: 12px;
  max-width: 290px;
  display: block;
  font-weight: 500;
}

.error-active {
  border-color: red !important;
}

.confirm_password #eye_hide {
  top: 35%;
}

.date-picker-custom {
  display: flex;
  background: transparent;
  color: #4a4a4a;
  border-radius: 4px;
  margin: 2px 2px;
}

.dark .date-picker-custom {
  background: transparent;
  color: #5e728f !important;
}

.date-picker-custom .react-daterange-picker__wrapper {
  border: 1px solid #fa501c30 !important;
  border-radius: 4px;
  flex-grow: 0;
  height: 42px;
  font-size: 12px;
  color: #4c728f;
  margin-top: -3px;
}

.dark .date-picker-custom .react-daterange-picker__wrapper {
  border: 1px solid #41464d !important;
  border-radius: 4px;
  margin-top: -2px;
}

.date-picker-custom .react-daterange-picker__inputGroup {
  padding: 5px 10px;
  margin-top: -5px;
}

.date-picker-custom .react-daterange-picker__inputGroup__leadingZero {
  display: inline-block;
  padding: 0 5px;
}

.date-picker-custom input {
  padding: 2px;
  pointer-events: none;
}

.dark .date-picker-custom input {
  background: transparent;
  color: #5e728f;
  padding: 2px;
}

.react-daterange-picker__calendar-button {
  font-size: 19px;
  padding: 0px;
  color: #4e4e4e !important;
}

.dark .react-daterange-picker__calendar-button {
  font-size: 19px;
  padding: 0px;
  color: #d4d4d4 !important;
}

.react-daterange-picker__calendar-button:enabled:hover,
.react-daterange-picker__calendar-button:enabled:focus {
  color: #fa501c !important;
}

.react-daterange-picker__clear-button:enabled:hover,
.react-daterange-picker__clear-button:enabled:focus {
  color: rgb(226, 12, 12) !important;
}

.react-daterange-picker__clear-button {
  color: #4e4e4e !important;
}

.dark .react-daterange-picker__clear-button {
  color: #d4d4d4 !important;
}

.dark .react-daterange-picker__button__icon {
  // stroke:#e0dcdc;
  stroke: #6c757d;
}

.date-picker-custom .react-daterange-picker__button {
  padding: 5px 10px;
}

.dark .react-calendar {
  background: #1e222d;
  border: 0.5px solid #3a3a3a;
  color: #e0dcdc;
}

.dark .react-calendar__month-view__days__day {
  color: #e0dcdc;
}

.dark .react-calendar__year-view__months__month:disabled,
.dark .react-calendar__tile:disabled {
  background: #131722;
  color: #e0dcdc;
}

.dark .react-calendar__year-view__months__month,
.dark .react-calendar__navigation button {
  color: #e0dcdc;
}

.dark .react-calendar__navigation button:disabled {
  background: #131722;
  color: #e0dcdc;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}

.react-calendar__tile--now {
  background: #603eae;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar--selectRange .react-calendar__tile--hover,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #fa501c !important;
}

// .dark .wallet_network_select .custom_select__control {
//   background: none;
//   // border-color: #2a2e39;
//   border: 1px solid rgba(30, 198, 246, 0.4588235294);
//   border-radius: 6px;
//   overflow: hidden;
//   padding: 2px;
// }
//NEW DESIGN
.dark .wallet_network_select .custom_select__control {
  background: #2C2E3369;
  border: 1px solid #282C34 !important;
  border-radius: 3px;
  overflow: hidden;
  // padding: 2px;
}

.dark .wallet_network_select .custom_select__menu {
  background-color: #2D3340 !important;
  border: 1px solid #ffffff2e !important;
}

.wallet_network_select .custom_select__control {
  border-color: #fa501c30;
  border: 1px solid #fa501c30 !important;
  box-shadow: none;
}
// .wallet_network_select .custom_select__control {
//   border-color: #dbdbdb;
//   padding: 2px;
// }
//NEW
.wallet_network_select .custom_select__control {
  border-color: #fa501c30 !important;
  padding: 2px;
}

.dark .wallet_network_select .custom_select__single-value {
  // color: #fcfcfc;
  color: #9ea4ab;
}

.dark .form-group.market_trade_input_block input.form-control::placeholder {
  color: #9ea4ab;
}

.dark
  .form-group.market_trade_input_block
  input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9ea4ab;
}

.dark
  .form-group.market_trade_input_block
  input.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9ea4ab;
}

.dark .wallet_network_select .custom_select__menu .custom_select__menu-list,
.dark .wallet_network_select .custom_select__menu {
  background: #131722 !important;
  border: 1px solid #41464d !important;
}

.dark .wallet_network_select .custom_select__single-value {
  color: #fcfcfc;
}

.wallet_network_select .custom_select__menu .custom_select__menu-list,
.wallet_network_select .custom_select__menu {
  border-color: #fa501c30;
  border: 1px solid #fa501c30 !important;
  border-radius: 4px;
  box-shadow: none !important;
}

.dark
  .wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option {
  background-color: #2d3340;
  color: #fcfcfc;
}

.dark .wallet_network_select .custom_select__input-container {
  color: #fcfcfc !important;
  caret-color: transparent;
}


.dark
  .wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option--is-selected {
  background: #131722;
}

.wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option--is-selected {
  background: #fa501c30;
  color: #000;
}

.wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option:hover,
.wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option:focus {
  background: #fa501c30 !important;
}
.wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option:active {
  background: #fa501c30 !important;
}
.dark .wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option:hover,
.dark .wallet_network_select
  .custom_select__menu
  .custom_select__menu-list
  .custom_select__option:focus {
  background: #131722 !important;
}

button.card-title {
  background: no-repeat;
  border: none;
}

.list_border li {
  border-bottom: 1px solid rgb(53 57 70 / 20%);
  padding: 6px;
}

.dark .list_border li {
  border-bottom: 1px solid rgb(53 57 70 / 91%);
  padding: 6px;
}

.dark .pop_up_style {
  background: #6c757d59;
  border-radius: 10px;
}

.pop_up_style {
  background: rgb(108 117 125 / 8%);
  border-radius: 10px;
}

button#pageDropDown {
  display: none;
}

.dark .page-item.active .page-link {
  z-index: 3;
  color: #fa501c;
  background-color: rgba(30, 199, 246, 0);
  border-color: #41464d;
}

.dark .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffffff;
  background-color: #102b3b00;
  border: 1px solid #41464d;
}

.Wallet_tab {
  display: flex;
  border-bottom: transparent !important;
  // border-radius: 5px;
  // width: 90%;

  background: transparent !important;
}

.Wallet_tab a.nav-item.nav-link {
  color: #6c757d !important;
}

.Wallet_tab a.nav-item.nav-link.active {
  color: #fa501c !important;
}

// .nav-tabs .nav-link.active {
//   background: #7d94b5 !important;
//   color: white;
//   border-radius: 5px !important;
// }
.card_wallet {
  min-height: 625px;
}

.dark .WithDrawal_Right_Card {
  padding: 18px;
  border: 1px solid #292929;
  border-radius: 5px;
  background: #2a2e39;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.WithDrawal_Right_Card {
  padding: 18px;
  border: 1px solid rgb(95 95 95 / 15%);
  border-radius: 5px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-top: 10px !important;
  margin-right: 14px;
  border-radius: 0.25rem;
  /* height: 10px !important; */
}

.identification-notification ul li span.card-title {
  font-size: 18px !important;
}

.dark .pagination_button {
  background: #fa501c30 !important;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid #41464d;
  width: 130px;
  color: #fa501c;
}

// .pagination_button {
//   background: transparent !important;
//   border-radius: 30px;
//   padding: 5px;
//   border: 1px solid #fa501c;
//   width: 130px;
//   color: #4b4b4b;
// }
.pagination_button {
  background: #fff4f1 !important;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid #fa501c30 !important;
  width: 130px;
  color: #fa501c;
}

.dark .pagination_button:hover {
  background: #fa501c !important;
  color: #fff !important;
}

.pagination_button:hover {
  background: #fa501c !important;
  color: #fff !important;
}

.back_icon {
  background: #7c787800;
  padding: 2px 12px 2px 8px;
  border-radius: 5px;
  margin: 0px !important;
}

.back_icon:hover {
  transition: 0.5s;
  background: #7c787838;
  padding: 2px 12px 2px 8px;
  border-radius: 5px;
  margin: 0px !important;
}
.page-link:hover {
  color: #fa501c !important;
}


.wallet_network_select .custom_select__input-container .css-qbdosj-Input{
  padding: 0px !important;
}
.custom_select__value-container.css-1fdsijx-ValueContainer{
  padding-top: 0px;
  padding-bottom: 0px;
}
.custom_select__value-container.css-1fdsijx-ValueContainer input#react-select-9-input, .custom_select__input-container.css-qbdosj-Input input#react-select-7-input {
  height: auto !important;
  opacity: 0 !important;
}

.page-link:focus {
     box-shadow: none !important
}


input#react-select-2-input {
  height: 30px !important;
}