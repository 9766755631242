.market-order th {
  // width: 16%;
  white-space: nowrap;
  // background-color: rgb(117 134 150 / 14%);
}

.market-order tbody tr td {
  // width: 16%;
  white-space: nowrap;
}

.no-data {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center !important;
  color: #b9c2ca;
}
// .market-history.recent .no-data {
//   right: 30%;
// }

.text_primary_open{
color : #2e6db1;
}

.no-data i {
  font-size: 100px;
  display: block;
  line-height: 0px;
  color: #dce1e5;
}

.market-order-item {
  color: #758696;
  padding: 8px 15px;
}

.market-order {
  border-top: 0;
  min-height: 392px;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
}

// .market-history.market-order.mt15 .tab-content {
//  height: 346px;
//  overflow: auto;
//}
.market-order.mt15 .tab-content {
  height: 346px;
  overflow: auto;
}
.tab-content{
  position: relative;
}



// .pagination {
//   width: 100%;
//   justify-content: flex-end;
//   background: #1c2030;
// }
// .pagination .page-link {
//   color: #f8f4f8;
//   background-color: #131722;
//   border: 1px solid #2a2e39;
//   border-bottom: 0;
// }
// .pagination .page-link:hover {
//   color: #fff;
//   background-color: unset;
//   border-color: #2a2e39;
// }
// .pagination .page-link.active  {
//   color: #fff;
//   background-color: #2d3d4e;
//   border-color: transparent;
// }
// .pagination .page-link:last-child,
// .pagination .page-link:first-child{
//   padding: 8px 5px;
// }

tbody tr.no_data_tr:hover{
  background: transparent;
}
.dark tbody tr.no_data_tr:hover{
  background: transparent;
}
.loadmore_data {
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.dark .loadmore_data button {
  color: #4f5966;
  border: 1px solid #2a2e39;
  background-color: #131722;
}

.loadmore_data button {
  color: #707274;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #cacdcf;
  background-color: transparent;
}

.loadmore_data button:hover {
  color: #000;
}
.dark .loadmore_data button:hover {
  color: #dce1e59f;
}

.loadmore_data button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.loadmore_data button i {
  display: inline-block;
  padding-left: 5px;
}

.market-order .login_first_block {
  border: unset;
  height: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .market-order {
    min-height: 200px;
  }

  .market-order .login_first_block {
    height: 200px;
  }

  .market-order.mt15 .tab-content {
    height: 186px;
  }
}