// .form-access form {
//   box-shadow: rgb(0 0 0 / 39%) 0px 2px 14px -6px;
//   border-radius: 5px;
//   padding: 30px;
//   border: 1px solid #e0e3eb;
//   min-width: 350px;
// }

// Ligin Page
.dark .login-background {
  background-color: #1c2028;
}
.login-background {
  background-color: #ffeee9;
}
.form-access {
  width: 80%;
  border: none;
}

.form-access form {
  box-shadow: none;
  border-radius: 5px;
  padding: 30px;
  border: none;
  // min-width: 350px;
}
.form-access .form-group input,
.form-access input {
  border: 1px solid #fa501c30;
  height: 45px;
  color: #18214d;
  font-size: 14px;
}
.form-access .form-group input:focus {
  box-shadow: none;
}
.form-access a:hover {
  text-decoration: none;
}
.form-access span {
  // text-align: center;
  // display: block;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-weight: 400;
}
.form-access .text-right {
  margin-top: -5px;
  font-weight: 600;
}
.Welcome span.text-orange {
  font-size: 1.7rem;
  font-weight: 400;
}
label.form-label {
  font-size: 14px;
  font-weight: 400;
  color: #9ea4ab !important;
}
.form-access button {
  width: 100%;
  padding: 12px;
  font-weight: 600;
  margin-top: 20px;
}

.form-access .custom-control-label::before,
.form-access .custom-control-label::after {
  top: 2px;
}
.form-access .custom-control-label::before {
  border: #cbd2df solid 1px;
}

.form-access h2 {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}
.form-access h2 a {
  font-weight: 600;
}

.dark .basic_pages {
  // height: calc(100vh - 63px);
  background-color: #131721;
}
.basic_pages {
  height: calc(100vh - 63px);
  background-color: #fff;
}
.signinButton {
  width: 160px !important;
}


@media only screen and (max-width: 767px) {
  .basic_pages {
    height: auto;
  }
}