.dark,
.dark header {
  // background: #1B1F28;
  background:  #131722;
 
}

.dark .light-bb,
.dark .input-group-text,
.dark .nav-tabs {
  border: none;
  border-bottom: 1px solid #1c232f;
}

.dark .market-trade .nav-tabs {
  border: none;
}

.dark header nav p,
.dark header nav a {
  color: #ffffff !important;
}

.dark header nav a:hover {
  color: #fa501c;
}

.dark .nav-tabs .nav-link.active {
  background: transparent;
  color: #fa501c !important;
}

.dark header nav a.btn:hover {
  color: #ffffff;
}

.dark header nav .nav-item.active a {
  color: #fa501c;
}

.dark .market-pairs {
  border-bottom: 1px solid #1c232f;
  background: #131722;
}

// .dark tr {
//   background: #131722;
// }

.dark .market-pairs .table td,
.dark .market-pairs .table th {
  //border-top: 1px solid #1C232F;
}

.dark .market-history .table td,
.dark .market-history .table th {
  // border-top: 1px solid #1C232F;
  color: rgb(183, 189, 198);
}

// .dark .order-book thead tr {
//   border-bottom: 1px solid #1c232f;
// }

.dark .order-book,
.dark .market-news,
.dark .market-order {
  border: 1px solid #1c232f;
}

// .dark tbody tr:hover {
//   background: #1c232f;
// }

.dark .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #69727a;
}

.dark .form-control::-moz-placeholder {
  /* Firefox 19+ */
  // color: #69727a;
  // color: #6487bb;
  color: #c4cad0;
}

.dark .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #69727a;
}

.dark .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #69727a;
}

.dark tbody tr {
  color: #ffffff;
}

.dark thead th,
.dark .market-pairs .nav-link {
  // color: #4f5966;
  color: rgb(132, 142, 156);
}

.dark .market-pairs .nav-link.active,
.dark #STAR i {
  color: #ffffff;
}

.dark .green {
  color: rgb(14, 203, 129);
}

.dark .red {
  color: rgb(246, 70, 93) !important;
}

.dark .yellow {
  color: #ffc107 !important;
}

.dark .market-pairs .nav {
  // background: #1c2030;
}

.dark .market-pairs input,
.dark .market-pairs span {
  // border-color: #1C232F;
  border-color: #1c232f;
  // background: #1C232F;
  background: #1c232f;
}

.dark .market-pairs .icon.ion-md-search {
  color: #6487bb;
}

.dark .market-pairs input {
  color: #ffffff;
}

.dark .heading {
  // background: #1c2030;
  color: #ffffff;
}

.dark .ob-heading tr {
  border-top: 1px solid #1c232f !important;
  border-bottom: 1px solid #1c232f !important;
}

.dark .ob-heading tr:hover {
  background: #131722;
  cursor: default;
}

.dark .ob-heading span {
  // color: #4f5966;
  //color: #5E728F;
  color: rgb(132, 142, 156);
}

.dark .dropdown-menu {
  background: #131722;
  box-shadow: 0 0 10px 0 #131722;
  border: 1px solid #1c232f;
}

.dark .dropdown-item:focus,
.dark .dropdown-item:hover {
  background-color: #1c232f;
  color: #ffffff;
}

.dark .market-history .nav {
  // background: #1c2030;
}

.dark .market-history .nav-link.active {
  color: #ffffff;
}

.dark .market-history.market-order .nav-link.active {
  color: #1ec6f6;
}

.dark .market-order .nav-link.active {
  color: #fa501c !important;
  border: none !important;
}

.dark .market-order .nav-link {
  color: rgb(132, 142, 156);
}

.dark .market-history .nav-link {
  // color: #4f5966;
  color: rgb(132, 142, 156);
}

.dark .red-bg-80:after,
.dark .red-bg-60:after,
.dark .red-bg-40:after,
.dark .red-bg-20:after,
.dark .red-bg-10:after,
.dark .red-bg-8:after,
.dark .red-bg-5:after,
.dark .red-bg:after {
  background: #ff231f63;
}

.dark .market-news {
  background: #131722;
}

.dark .market-news li {
  border-bottom: 1px solid #1c232f;
}

.dark .market-news li:hover {
  background: #1c232f;
}

.dark .market-news li strong,
.dark .market-news li a {
  color: #c5cbce;
}

.dark .market-order {
  background: #131722;
}

.dark .no-data i,
.dark .no-data {
  color: #1c232f;
}

.dark .market-trade {
  // border: 1px solid #1C232F;
  background: #131722;
}

.dark .trading_chart_section {
  border: 1px solid #1c232f;
  background: #131722;
}

.dark .market-trade .nav .nav-item .nav-link.active {
  background: transparent;
  color: #ffffff;
}

.dark .market-trade .nav .nav-item .nav-link {
  color: #4f5966;
}

.dark .market-trade p {
  // color: #c5cbce;
  color: rgb(132, 142, 156);
}

.dark .market-trade p span {
  color: #c5cbce;
}

.dark .market-trade-list li a {
  background: #1c232f;
  color: #ffffff;
}

.dark .market-trade-list li span {
  color: #959597;
}

.dark .market-trade input {
  border: 1px solid #1c232f;
  background: #1c232f;
  color: #ffffff;
}

.dark .market-trade .input-group-text {
  background: #1c232f;
  color: #ffffff;
  border-color: #1c232f;
}

.dark .market-trade-buy .market-trade-list li a:hover {
  // background: #26de81;
  background: #1ec6f6;
  color: #fff;
}

.dark .market-trade-sell .market-trade-list li a:hover {
  // background: #ff231f;
  background: #1ec6f6;
  color: #fff;
}

.dark #darkDepthChart {
  background: #131722;
}

.dark .markets {
  background: #131722;
}

.dark .table td,
.dark table th {
  // border-top: 1px solid #1C232F;
}

.dark .markets-pair-list .nav {
  background: #1c2030;
}

.dark .markets-pair-list .nav-link.active,
.dark #STAR i {
  color: #ffffff;
}

.dark thead th,
.dark .markets-pair-list .nav-link {
  // color: #4f5966;
  //color: rgb(132, 142, 156);
  color: rgb(132, 142, 156);
}

.dark .markets-pair-list .load-more {
  border: 1px solid #1c232f;
  color: #ffffff;
}

.dark .markets-pair-list .load-more:hover {
  color: #fff;
  border: 1px solid #fa501c;
}

.dark .markets-content h2,
.dark .markets-content p {
  color: #ffffff;
}

.dark .markets-content span.green,
.dark .markets-content span.red {
  color: #fff;
}

.dark .market-trade-sell {
  border-left: 1px solid #1c232f;
}

.dark .markets-container {
  background: #1e222d;
}

.dark .sign-up-form h3 {
  border-top: 0;
  margin-top: 0;
  padding-top: 15px;
}

.dark header nav .nav-item.active a.btn:hover {
  color: #fff;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #fa501c !important;
  background-color: #fa501c !important;
}

.dark .dropdown-menu::before {
  background: #131722;
  border-top: 1px solid #1c232f;
  border-left: 1px solid #1c232f;
}

.dark .dropdown-menu p {
  color: #ffffff;
}

.dark .dropdown-footer {
  border-top: 1px solid #1c232f;
}

.dark .header-img-icon .profile-nav .nav-item .nav-link:hover {
  background-color: #1c232f;
  color: #ffffff;
}

// .dark .form-control,
// .dark .form-access .form-group input,
// .dark .form-access input,
// .dark .settings-profile select {
//   background-color: #1c232f;
//   // border: 1px solid #1C232F !important;
//   border: 1px solid #1c232f;
//   color: #ffffff;
// }

//NEW

// .dark .form-control,
// .dark .form-access .form-group input,
// .dark .form-access input,
// .dark .settings-profile select {
//   background-color: #2c2e33;
//   // border: 1px solid #1C232F !important;
//   border: 1px solid #1c232f;
//   color: #ffffff;
// }
.dark .form-control, .dark .form-access .form-group input, .dark .form-access input, .dark .settings-profile select {
  background-color: #1b1f28 ;
  border: 1px solid #282C34;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  height: 42px !important;
}
.dark .form-control:focus{
  border: 1px solid #41464d;
  color: #fff;
}
// .dark .form-access form {
//   padding: 30px;
//   border-radius: 5px;
//   min-width: 350px;
//   box-shadow: 0 0 10px 0 #131722;
//   border: 1px solid #1c232f;
// }

.dark .form-access form {
  padding: 30px;
  border-radius: 5px;
  // min-width: 350px;
  box-shadow: none;
  border: none;
}

.dark .form-access h2,
.dark .form-access {
  color: #ffffff;
}

.dark .form-access .custom-control-label::before {
  border: #1c232f solid 1px;
  background-color: #1c232f;
}

.dark .error-page a {
  border: 1px solid #1c232f;
  color: #ffffff;
}

.dark .error-page a:hover {
  border: 1px solid #fa501c;
  background: #fa501c;
  color: #ffffff;
}

.dark .error-page h2,
.dark .error-page p {
  color: #c5cbce;
}

.dark .wallet .nav-pills a,
.dark .wallet-history {
  border-top: 1px solid #1c232f;
}

.dark .wallet .nav {
  background: #1e222d;
}

.dark .wallet h2,
.dark .wallet h3,
.dark .wallet h4,
.dark .wallet p {
  color: #ffffff !important;
}

.dark .wallet button.green,
.dark .wallet button.red,
.dark .wallet .nav-pills a.active h2,
.dark .wallet .nav-pills a.active h3,
.dark .wallet .nav-pills a.active p {
  color: #ffffff;
}

.dark .wallet .tab-content li:first-child {
  border-bottom: 1px solid #1c232f;
}

.dark .wallet-history tr {
  background: #1e222d;
}

.dark .wallet-address input,
.dark .wallet-address input:focus {
  border: 1px solid #1c232f;
  background: #1c232f;
  box-shadow: none;
  color: #ffffff;
}

.dark .depth-chart-inner {
  border: 1px solid #1c232f;
}

.dark .markets-capital-item {
  box-shadow: 0 0 10px 0 #131722;
  border: 1px solid #1c232f;
}

.dark .markets-capital-item h2,
.dark .markets-capital-item h4 {
  color: #fff;
}

.dark .settings .card {
  //background: #1e222d;
  background: #0e121c !important;
}

.dark .settings .card-title {
  color: #fff;
  font-size: 20px !important;
  margin-bottom: 30px ;
}
.settings .card-title {
  font-size: 20px !important;
  margin-bottom: 30px;
}

.dark .settings .card-small-title {
  color: #fff;
}

.dark .settings-nav {
  //background: #1e222d;
}

.dark .settings-nav .nav-link {
  color: #5e728f;
}

.dark .settings-notification li {
  border-bottom: 1px solid #1c232f;
}

.dark .settings-notification li:last-child {
  border-bottom: 0;
}

.dark .settings-notification p {
  color: #ffffff;
}

.dark .settings-notification span {
  color: #4f5966;
}

.dark .settings label {
  color: #fff;
}

.dark .settings .custom-file-label {
  background: #1c232f;
  border: 1px solid #1c232f;
  color: #616d75;
}

.dark .settings-profile input[type="submit"] {
  border: 1px solid #fa501c;
}

.dark .market-carousel-item {
  border: 1px solid #1c232f;
}

.dark .market-carousel-item {
  color: #fff;
}

.dark .market-carousel-item .btn.buy:hover,
.dark .market-carousel-item .btn.sell:hover {
  color: #fff;
  border-color: #fff;
}

.dark .news-details,
.dark .page-content {
  color: #fff;
}

.dark .modal-content {
  color: #fff;
  // background-color: #1c232f;
  background-color: #131721;
  margin: auto;
}

.dark .modal-content .modal-header {
  border-bottom: 1px solid #4e4f51;
}

.dark .modal-content .modal-footer {
  border-top: 1px solid #4e4f51;
}

.dark .without_login_fav .inner_login a {
  color: #fa501c !important;
}
.without_login_fav .inner_login a {
  color: #fa501c !important;
}

.dark .market-trade .nav-tabs .nav-link.active,
.dark .market-trade .nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #fa501c !important;
}
.market-trade .nav-tabs .nav-link.active,
.market-trade .nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #fa501c !important;
}

.dark .order_cancel_btn {
  background-color: #1ec6f624;
  border: 0px solid #959699;
  color: #fff;
}

.dark .market-trade .nav-tabs .nav-link {
  color: rgb(132, 142, 156);
}

.dark .text-secondary {
  // color: rgb(132, 142, 156) !important;
  color: #5e728f !important;
}

.dark .trade_ctm_header span {
  color: rgb(132, 142, 156);
  font-size: 13px;
}

.dark .trade_ctm_header span.green {
  color: #03ba8e;
}

.dark .trade_ctm_header {
  border-top: 1px solid #1c232f;
  border-bottom: 1px solid #1c232f;
}

.trade_ctm_header {
  border-top: 1px solid #e0e3eb;
  border-bottom: 1px solid #e0e3eb;
}

.trade_ctm_header tr {
  display: block;
}

.dark .order-book tbody tr td:nth-child(2) {
  color: rgb(183, 189, 198);
}

.dark .order-book tbody tr td:last-child {
  color: rgb(183, 189, 198);
}

.dark .market-pairs tbody tr td:nth-child(2) {
  text-align: right;
  color: rgb(183, 189, 198);
}

.dark .settings .card.security-verification-card {
  background: #1c232f;
}

.dark .card-estimated-balance-section h3.card-estimated-balance {
  color: #fff;
}

.dark .card-estimated-balance-section h3.card-estimated-balance span,
.dark .spot-balance-section h3.card-estimated-balance span {
  color: #5e728f;
  margin-left: 10px;
}

.dark .profile-card-body {
  background: #1c232f;
  border-radius: 15px;
}

.dark .profile-card-body .profile_card hr {
  background-color: #3c495d78;
}

// .dark .market-tabs .nav-tabs .nav-link.active {
// color: #1EC6F6;
// background: #1ec6f633;
// border-radius: 5px;
// }

.market-tabs .nav-tabs .nav-link.active {
  color: #fa501c!important;
  // background: #1ec6f633;
  border-radius: 5px;
}

.dark .market-tabs tr {
  background: #13172200;
}

.dark .market-tabs .light-bb,
.dark .market-tabs .input-group-text,
.dark .market-tabs .nav-tabs {
  border: none;
  border-bottom: none;
}

.dark .market-tabs .nav-tabs .nav-link {
  color: #fff;
}

.market-tabs .nav-tabs .nav-link {
  color: #3f3f3f;
}

.dark .market-tabs span.text-dark {
  color: #5e728f !important;
}

.dark .Chart_Active_link .nav-tabs .nav-link.active {
  color: #fff;
  background: #5e728f45;
  border-radius: 5px;
}

.Chart_Active_link .nav-tabs .nav-link.active {
  color: #858585 !important;
  background: #5e728f45;
  border-radius: 5px;
}

.dark .Chart_Active_link .nav-tabs .nav-link {
  color: #6c757d;
}

.dark .profile-left-tab {
  border-right: 1px solid #1c232f;
}

.dark .requirements-body h6 {
  color: #fff;
}

.dark .explore_search_bar {
  background: #1c232f;
}

.dark .explore_search_bar img {
  filter: unset;
}

.dark span.profile-status-personal.badge {
  color: #ccd6e5;
}

// .dark .announcements-section {
//   border: 1px solid #181E29;
// }

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0px 1000px #1c232f inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #1c232f00 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.dark .with_draw input:-webkit-autofill,
.dark .with_draw input:-webkit-autofill:hover,
.dark .with_draw input:-webkit-autofill:focus,
.dark .with_draw textarea:-webkit-autofill,
.dark .with_draw textarea:-webkit-autofill:hover,
.dark .with_draw textarea:-webkit-autofill:focus,
.dark .with_draw select:-webkit-autofill,
.dark .with_draw select:-webkit-autofill:hover,
.dark .with_draw select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0px 1000px #0e121c inset !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #ffffff !important;
}

.dark .market_trade_input_block input:-webkit-autofill,
.dark .Nick_NameTow input:-webkit-autofill,
.dark .Nick_NameTow input:-webkit-autofill:hover,
.dark .Nick_NameTow input:-webkit-autofill:focus,
.dark .Nick_NameTow textarea:-webkit-autofill,
.dark .Nick_NameTow textarea:-webkit-autofill:hover,
.dark .Nick_NameTow textarea:-webkit-autofill:focus,
.dark .Nick_NameTow select:-webkit-autofill,
.dark .Nick_NameTow select:-webkit-autofill:hover,
.dark .Nick_NameTow select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0px 1000px #303641 inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #ffffff !important;
}

.dark .Nick_Name {
  background-color: #3c414c00 !important;
  border: 1px solid #41464d!important;
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  justify-content: end;
}

.dark .close {
  color: #fff;
  text-shadow: 0 1px 0 #fff0;
}

.dark .myassets_card h5 {
  color: #fff;
}

.dark .myassets_card p {
  color: #5e728f;
}

.dark .overview_no_open_orders p {
  color: #5e728f;
}

.dark .AccountStatement_header span {
  // color: rgb(132, 142, 156);
  color: #5e728f;
  font-size: 13px;
}

.dark .AccountStatement_header span.green {
  color: #03ba8e;
}

.AccountStatement_header tr {
  display: block;
  background: none;
}

.dark .myassets_card h6 {
  color: #fff;
}

.dark .spot-balance-section {
  color: #fff;
}

.dark .wallet_crypto_balance_search {
  border: 1px solid #41464d ;
  border-radius: 6px;
  overflow: hidden;
}

.dark .wallet_crypto_balance_search input.form-control {
  background: none;
}

.dark .wallet_crypto_balance_search span.explore_search_bar {
  background: none;
}

.dark .funding_estimated_balance_right button.btn span {
  background-color: rgba(94, 114, 143, 0.4509803922);
}

.dark .account-statement-due-date {
  background: none;
  border-color: #2a2e39;
  border: 1px solid #41464d;
  border-radius: 6px;
  overflow: hidden;
}

.dark .account-statement-due-date input {
  background: none;
  color: #5e728f;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.check {
  display: flex;
  align-items: center;

  &__text {
    user-select: none;
    cursor: pointer;
  }

  &__box {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    margin-right: 10px !important;

    border: 1px solid #fa501c30;
    border-radius: 3px;
    background-color: transparent;
  }

  /* Checked */
  &__input:checked + &__box {
    background: url(https://t3.ftcdn.net/jpg/02/51/82/00/360_F_251820018_wF6q9FnxkQ2ZFuazDkiD0c4IJwrVHlA3.jpg)
      center/cover no-repeat;
    background-color: transparent !important;
  }

  /* Focused */
  &__input:focus + &__box {
    border: 1px solid #fa501c30;
    background-color: transparent !important;
  }
}

.modal-content.Nick_Name_Popup {
  width: 24rem !important;
  margin: auto;
  border-radius: 14px;
  padding-left: 28px;
  padding-right: 28px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.modal-content.Pass_Popup {
  border-radius: 14px;
  padding-left: 28px;
  padding-right: 28px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.dark .input#email {
  background-color: #3c414c00 !important;
  border: 1px solid #41464d!important;
}

.dark input.Nick_Name.Emial_popup {
  border: 1px solid #41464d!important;
}

.form-access.Form_access form {
  padding: 0px;
}

h3.card-estimated-balance {
  display: flex;
}

select.useList_Coin_Dropdown.form-control {
  width: 20px;
  height: 30px;
  margin-left: 5px;
}

.custom_select.Over_View_select__control.css-13cymwt-control {
  background: transparent;
  border: none;
}

.Overview_select_.custom_select-box.Over_View.css-b62m3t-container {
  margin-top: -6px;
}

.custom_select.Over_View_select__indicators.css-1hb7zxy-IndicatorsContainer {
  border: 1px solid grey;
  border-radius: 9px;
  width: 51px;
  height: 40px;
  margin-top: 7px;
}

.dark .custom_select.Over_View_select__single-value.css-1dimb5e-singleValue {
  color: white;
}

.custom_select.Over_View_select__single-value.css-1dimb5e-singleValue {
  color: rgb(0, 0, 0);
}

.dark .custom_select.Over_View_select__control {
  background: #ffffff00 !important;
}

.custom_select.Over_View_select__control {
  background: #ffffff00 !important;
}

.dark .select_button {
  background: #1c232f;
  padding-right: 10px;
  border: 1px solid;
  border-radius: 10px;
}

.select_button {
  background: #ffffff;
  padding-right: 10px;
  border: 1px solid;
  border-radius: 10px;
}

.select_button select#exampleForm\.ControlSelect1 {
  width: 20px;
  height: 28px;
  border: none;
}

.dark.select_button .form-control:focus {
  color: #495057;
  background-color: #fff0;
  border-color: #80bdff00;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

.select_button .form-control:focus {
  color: #495057;
  background-color: rgba(255, 255, 255, 0);
  border-color: #ffffff00;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

.dark .select-main-coins-wallet li {
  color: #fff;
}

.dark .card-avaialble-balance {
  color: #fff;
}

.dark .settings .card-small-title span i.icon.ion-md-copy.btn.btn-secondary {
  background-color: #5e728f5c;
  border-color: #5e728f5c;
}

.dark .mywallet-qrcode.text-center.card-body.card {
  background: #0e121c !important;
}

.mywallet-qrcode.text-center.card-body.card {
  background: #ffffff !important;
}

li.coin_select {
  border: 1px solid #fa501c30;
  padding: 7px 10px;
  border-radius: 7px;
  cursor: pointer;
  color: black;
}

li.coin_select:hover {
  color: #fa501c !important;
  border: 1px solid #fa501c30;
  padding: 7px 10px;
  border-radius: 7px;
  cursor: pointer;
}
.dark li.coin_select:hover {
  color: #fa501c !important;
  border: 1px solid #41464d !important;
  padding: 7px 10px;
  border-radius: 7px;
  cursor: pointer;
}
.dark li.coin_select {
  border: 1px solid #41464d !important;
 
}

.copy_btn {
  // color: #fa501c!important;
  margin: 8px !important;
  cursor: pointer;
  font-size: 27px !important;
}

.copy_btn:hover {
  color: #fa501c!important;
  // margin: 8px;
  // cursor: pointer;
  // font-size: 20px;
}

.dark .qr_show {
  height: 24px;
  background-image: url("../../../../public/img/coin/qrcode.png") !important;
  width: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: 1.5px solid #1ec7f600;
}

.qr_show {
  height: 24px;
  background-image: url("../../../../public/img/coin/qrcode_light.png") !important;
  width: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: 1.5px solid #1ec7f600;
}

.dark .transfer_icon_section {
  color: #fff;
}

.dark .form-group.market_trade_input_block input.form-control {
  border: 1px solid #41464d !important;
}

.dark .withdraw-total-amount h5 {
  color: #fff;
}

.dark .withdraw-total-amount p {
  color: #ffffff73;
}

.dark .withdraw-amount-select-wallet .form-check label.form-check-label {
  color: #5e728f;
}

.dark .withdrawal_confirm_details_info ul li p {
  color: #ffffff38;
}

.dark .withdrawal_confirm_details_info {
  background-color: #0e121c;
}

.dark .coin_transfer {
  background: #5e728f47;
}

.dark .internal-transfers-icons i.icon {
  color: #fff;
}

.dark .cross_margin_section_inner i.ion.ion-md-shuffle {
  color: #ffffff91;
}

.dark .transaction_details_Successful {
  background-color: #0e121c;
  padding: 20px;
  border-radius: 15px;
}

.dark .transaction_details_Successful p {
  color: #5e728f;
}

.dark tr.crypto_balance_table_header {
  background: #1c212e !important;
  border-radius: 16px;
}

.dark tbody.crypto_balance_table_body {
  background: #0e121c !important;
}

.crypto_balance_table_main tr {
  background: #e6e6e600 !important;
}

.dark .Funding_wallet tbody tr {
  color: #ffffff;
  border-bottom: 1px solid #7a7a7a2b !important;
}

.Funding_wallet tbody tr {
  border-bottom: 1px solid #7a7a7a33 !important;
}

tr.crypto_balance_table_header {
  background: #e9ecef !important;
}

.dark .crypto_balance_table_header {
  background: #1c212e !important;
}

.dark .Funding_wallet thead th {
  color: rgb(132, 142, 156);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.Funding_wallet thead th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.modal-content.Chane_Password_Popup {
  width: 24rem !important;
  padding: 0px 20px 0px 16px !important;
  border-radius: 14px;
  margin: auto;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.modal-dialog.modal-dialog-centered {
  margin: auto;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef00 #e9ecef00 #dee2e600;
  border: none !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e600 #dee2e600 #ffffff00 !important;
  border: none !important;
}

.dark .header-img-icon.dropdown {
  background: #2c2e33;
  border: 1px solid #41464d !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  border-radius: 3px !important;
  height: 33px !important;
}
.header-img-icon.dropdown {
  background: #2c2e3300;
  border: 1px solid #fa501c30 !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  border-radius: 3px !important;
  // min-width: 100px !important;
  height: 33px !important;
}

button.dropdown-toggle.btn.btn-default {
  display: flex;
  align-items: center;
  padding: 0px;
}
a {
  color: #fa501c;
}

.dark a.nav-item.p-0.newDesingDrop.dropdown-item {
  margin-top: 0px;
  // background: #2c2e33;
  background: none;
  border-radius: 0px;
  border-bottom: 1px solid #ffffff1a;
}
a.nav-item.p-0.newDesingDrop.dropdown-item:first-child {
  margin-top: 15px;
}
a.nav-item.p-0.newDesingDrop.dropdown-item:last-child {
  border-bottom: none;
}
a.nav-item.p-0.newDesingDrop.dropdown-item {
  margin-top: 0px;
  // background: #fa501c5d;
  background: none;
  border-radius: 0px;
  border-bottom: 1px solid #fedcd2;
}
.page-item.active .page-link {
  z-index: -3 ;
  color: #fa501c !important;
background-color: #007bff00 !important; 

}
