@media only screen and (max-width: 1434px) {
  // .market-pairs tbody {
  //   height: 760px;
  // }
  // .no-fluid .market-pairs tbody {
  //   height: 815px;
  // }
  .settings-profile .custom-file {
    width: 25%;
  }
}

@media only screen and (max-width: 1300px) {
  // .market-pairs tbody {
  //   height: 740px;
  // }
  // .no-fluid .market-pairs tbody {
  //   height: 755px;
  // }

  .account_statement_box .col-xl-2 {
    max-width: 25%;
  }

  .account_statement_box {
    row-gap: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .markets-capital-item {
    height: 289px;
  }
  .userLogout {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 991px) {
  .settings-nav .nav-item {
    width: auto !important;
  }

  .error-page h2 {
    font-size: 140px;
  }

  .settings-nav {
    margin-bottom: 15px;
  }

  .settings-profile .custom-file {
    width: 35%;
  }
  .VipInstitude{
    width: 100%;
  }
  .VIPInstitutionalcontent{
  width: 100%;
  }
  .Spot_Trading_link {
    font-size: 13px;
  }
  .linkkprogram{
    width: 100%;
  }
  .userLogout {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 918px) {
  .mobile_screen {
    display: block;
  }

  .order_book_main_section {
    display: none;
  }

  .market_pairs_section {
    width: 50% !important;
    // order: 3;
  }

  .order_book_section {
    width: 50% !important;
    // order: 2;
  }

  .no-fluid .order-book {
    margin-top: 0px !important;
  }

  .market-history tbody {
    height: 274px;
  }

  .market-trade-buy,
  .market-trade-sell {
    width: 100% !important;
  }
  .linkkprogram{
    width: 100%;
  }
  .VipInstitude{
    width: 100%;
  }
  .VIPInstitutionalcontent{
  width: 100%;
  }
  .Spot_Trading_link {
    font-size: 13px;
  }
  .linkkprogram{
    width: 100%;
  }
}

// @media only screen and (max-width: 792px) {
//   .market-pairs tbody {
//     height: 699px;
//   }
// }
@media only screen and (max-width: 768px) {
  .form-access {
    width: 100%;
    border: none;
  }
  .create_account {
    padding-top: 0rem !important;
  }
  button.btn.btn-primary.signinButton.mt-0 {
    font-size: 12px;
  }
  .header-custom-icon button {
    padding: 0 0px;
    margin-top: 5px;
  }

  .settings-nav.nav.nav-pills {
    padding: 10px;
  }

  .market-trade-sell {
    border-left: none !important;
    padding-left: 0px !important;
  }
  input#search-bar-0{
    display: none !important;
  }
  h3 {
    // font-size: 1rem !important;
  }

  .settings-profile .custom-file {
    width: 45%;
  }

  header button.navbar-toggler i {
    font-size: 30px;
    color: #4a4a4a;
  }

  header button.navbar-toggler:focus {
    outline: none;
  }

  .navbar-collapse .dropdown.menu-avatar-icon {
    margin-top: 10px;
  }

  .no-fluid .market-pairs {
    margin-bottom: 15px;
  }

  .no-fluid .order-book {
    margin-top: 15px;
  }

  .circle-pulse {
    left: 25px;
    right: inherit;
    top: 48px;
  }

  .header-img-icon {
    margin-top: 10px;
  }

  .header-custom-icon button {
    display: block;
  }

  .dropdown-menu::before {
    right: inherit;
    left: 5%;
  }

  .wallet .nav {
    margin-bottom: 30px;
  }

  .wallet-address img {
    margin-top: 40px;
  }

  .wallet-address .wallet_network_select img,
  .wallet-address .select-main-coins-wallet img {
    margin-top: 0px !important;
  }

  #dark header button.navbar-toggler i {
    color: #c5cbce;
  }

  .market-order-item {
    padding: 5px 5px;
    overflow: hidden;
  }

  .market-order-item li {
    font-size: 12px;
  }

  .markets-capital-item {
    height: 304px;
  }

  .account_statement_box .col-xl-2 {
    max-width: 50%;
  }

  .account_statement_width {
    min-width: 46%;
  }
}

@media only screen and (max-width: 576px) {
  .markets-capital-item {
    height: 91.4%;
  }
  button.btn.btn-primary.signinButton.mt-0 {
    font-size: 12px;
  }
  .account_statement_box .col-xl-2 {
    max-width: 100%;
  }
  input#search-bar-0{
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .estimated-balance-btn-postion {
    position: unset !important;
  }
  .settings-nav.nav.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    /* overflow: hidden; */
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .settings .settings-nav .nav-link:last-child {
    margin: 0px 8px;
  }
  a.d-flex.align-items-center.nav-link {
    white-space: nowrap;
  }
  a.d-flex.align-items-center.nav-link.active {
    // padding: 10px 21px;
    background: none !important;
    // border-bottom: 1px solid;
    padding-left: 0px !important;
    color: #fa501d !important;
  }
  nav.Wallet_tab.nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 35px;
  }
  nav.nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .profile-left-tab {
    width: 600px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .mobile_chart {
    min-height: 380px;
    height: 380px;
  }
  input#search-bar-0{
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .settings-nav .nav-link {
    border-radius: 0;
    color: #18214d;
    margin: 2px 0;
    padding: 10px 7px;
  }

  .mobile_chart {
    min-height: 380px;
    height: 380px;
  }

  .account_statement_width {
    min-width: 100%;
  }

  .market_pairs_section {
    min-width: 100% !important;
  }

  .order_book_section {
    min-width: 100% !important;
  }

  .modal-content.Nick_Name_Popup {
    width: 100% !important;
  }

  .market-trade-buy {
    min-width: 100% !important;
  }

  .market-trade-sell {
    min-width: 100% !important;
  }

  a#fill-tab-example-tab-buy {
    position: absolute;
    top: 1px;
    padding: 2px 18px 0px 18px;
    // background: #098168;
    right: 25%;
    top: 3%;
    border-radius: 5px;
    color: gray;
    border: 1px solid #fa501c30;
  }

  a#fill-tab-example-tab-sell {
    position: absolute;
    top: 1px;
    top: 3%;
    padding: 2px 18px 0px 18px;
    // background: #E33442;
    border-radius: 5px;
    right: 3%;
    color: gray;
    border: 1px solid #fa501c30;
  }

  .buy_sell .nav-tabs .nav-link.active {
    background: #fa501c24 !important;
    color: white !important;
  }
  input#search-bar-0{
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .col-md-3.order_book_width {
    max-width: 100% !important;
  }

  .col-md-6.trading_chart_width {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .withdraw-amount-select-wallet input[type="radio"]:checked:before {
    top: -1px;
  }
}

@media only screen and (max-width: 1199px) {
  thead tr th,
  .order-book tbody tr,
  .order-book tbody td,
  .table td {
    padding: 5px !important;
  }

  .order_book_section,
  .market_pairs_section {
    // width: 260px !important;
    min-width: 260px;
  }

  .market-trade-list li {
    width: 25%;
    // min-width: 50%;
  }

  .market-trade-list {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .trading_chart_section {
    order: 1;
  }

  .order_book_section {
    // width: 320px !important;
    order: 2;
  }

  .market_pairs_section {
    // width: 320px !important;
    order: 3;
  }
}

@media (min-width: 576px) {
  .withdrawal_confirm_details_modal .modal-dialog {
    max-width: 590px !important;
  }

  .transaction_details_Successful_modal .modal-dialog {
    max-width: 500px !important;
  }
}

.react-bootstrap-table.table-responsive {
  white-space: nowrap;
  text-overflow: clip;
}

@media only screen and (max-width: 1199px) {
  .vynksafe_new_style .pr-6rem {
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 991px) {
  .our_apps_v_road_card_center {
    margin-top: 0% !important;
  }
  .our_apps_v_road::after {
    display: none;
  }

  .order-lg-2-v {
    order: 2 !important;
  }
  .Spot_Trading_link {
font-size: 12px;
  }
  .linkkprogram{
    width: 100%;
  }
  .VipInstitude{
    width: 100%;
  }
  .VIPInstitutionalcontent{
  width: 100%;
  }
  .Spot_Trading_link {
    font-size: 13px;
  }
  .linkkprogram{
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .Welcome span.text-orange {
    font-size: 1rem;
    font-weight: 400;
  }
  .investment_card_v_img::after {
    display: none;
  }

  .pricing_table_v table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-top: 19px !important;
    justify-content: center !important;
  }
  .subscribe_section_v img {
    width: 50% !important;
  }
  .markets_watch_table_tabs ul {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }
  .markets_overview_section_v table tr td:first-child {
    padding-right: 40px;
  }
  .VipInstitude{
    width: 100%;
  }
  .VIPInstitutionalcontent{
  width: 100%;
  }
  .Spot_Trading_link {
    font-size: 13px;
  }
  .linkkprogram{
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .featured_ul_section_v li {
    padding: 10px 10px 10px 73px !important;
  }
  .manage_profits_v::after,
  .exchange_freedome_v::after,
  .experienced_engineers::after {
    left: -0px !important;
    padding: 33px;
    top: 1px;
  }
  .VipInstitude{
    width: 100%;
  }
  .VIPInstitutionalcontent{
  width: 100%;
  }
  .Spot_Trading_link {
    font-size: 13px;
  }
  .linkkprogram{
    width: 100%;
  }
}

@media (max-width: 767px){
.justify_content_mobile_between {
    justify-content: space-between;
}
.p2p_table_heading{
display: none;
}
.VipInstitude{
  width: 100%;
}
.VIPInstitutionalcontent{
width: 100%;
}
.Spot_Trading_link {
  font-size: 13px;
}
.linkkprogram{
  width: 100%;
}
}