div#content {
  margin-top: -65px !important;
}
.vynksafe_new_style .home_banner_section_v {
  background-image: url(../../../../public/img/images/light-theme/home_bg_light.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8rem 0rem 3rem 0rem;
  display: flex;
  align-items: center;
  background-position: center 70%;
}
body.dark.vynksafe_new_style .home_banner_section_v {
  background-image: url(../../../../public/img/images/home_bg.svg);
}
body.dark.vynksafe_new_style .banner_main_heading_v h1 {
  color: #fff;
  font-weight: normal;
}
.vynksafe_new_style .text-orange {
  color: #fa501c !important;
}

.vynksafe_new_style .banner_main_heading_v h1 {
  color: #131722;
  font-weight: normal;
}
.vynksafe_new_style .banner_main_heading_v h1 span {
  font-weight: 500;
  font-size: 2.5rem;
  font-family: "Poppins";
}
.vynksafe_new_style .home_banner_section_v form .input-group input,
.vynksafe_new_style .home_banner_section_v form .input-group input:focus {
  background: none;
  border: none;
  box-shadow: none;
  color: #9ea4ab;
}
.vynksafe_new_style .home_banner_section_v form .input-group {
  background: #2c2e33;
  border: 1px solid #41464d !important;
  padding: 3px;
  border-radius: 3px !important;
  min-height: 43px;
  align-items: center;
}

.vynksafe_new_style .pr-6rem {
  padding-right: 6rem;
}

.vynksafe_new_style .gray_bg_v {
  background: #fff4f1;
  border: 1px solid #feded4 !important;

  border-radius: 3px !important;
  /*  height: 100%;*/
  transition: 0.3s ease-in-out;
}
body.dark.vynksafe_new_style .gray_bg_v {
  background: #1b1f28;
  border: 1px solid #41464d57 !important;
}
.vynksafe_new_style .gray_bg_v:hover {
  border: 1px solid #fa501c !important;
  background: #fff4f1;
}

.vynksafe_new_style .investment_card_v.gray_bg_v:hover {
  border: 1px solid #fa501c !important;
  background: #fff4f1;
}

body.dark.vynksafe_new_style .gray_bg_v:hover {
  background: #2c2e33c2;
  transition: 0.3s ease-in-out;
}

.investment_card_v {
  height: 100%;
}

.investment_card_v_img::after {
  content: "";
  background-image: url(../../../../public/img/images/easy-investment.svg);
  background-size: contain;
  width: 61%;
  height: 70%;
  display: block;
  position: absolute;
  top: -36%;
  background-repeat: no-repeat;
  left: -28%;
}

.gap-v-4 {
  gap: 30px 0px;
}
.gap-v-5 {
  gap: 10px 0px;
}
.fw-v-3 {
  font-weight: 600;
}
.dark .vynksafe_new_style .btn.btn-gray-v {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #fff;
}

.vynksafe_new_style .btn.btn-gray-v:hover {
  background: #fa501c !important;
  border: 1px solid #fa501c30 !important;
  color: #fff !important;
}

.vynksafe_new_style .btn.btn-gray-v {
  color: #fa501c;
}
.vynksafe_new_style .btn.btn-gray-v.dark_light_theme_btn_v:hover,
.vynksafe_new_style .btn.btn-gray-v:hover {
  background: #fa501c;
  color: #fff;
}
.vynksafe_new_style .btn.btn-gray-v {
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
  color: #fa501c !important;

  padding: 8px;
  border-radius: 3px !important;
  /*  min-height: 43px;*/

  transition: 0.3s ease-in-out;
}

// MARKET TABLE
body.dark .pricing_table_v td {
  color: #fff;
}
.pricing_table_v td {
  word-break: break;
  color: #1b1f28;
}
.pricing_table_v th {
  color: #9ea4ab;
}
.table td,
.table th {
  padding: 0.75rem !important;
  vertical-align: top !important;
  border-top: 0px solid #dee2e6 !important;
}

// .pricing_table_v table.table thead th {
//   border-bottom: 1px solid #2c2e33 !important;
//   font-weight: 500;
// }

.pricing_table_v table.table thead th {
  border-bottom: none !important;
  border-top: none !important;
  font-weight: 500;
}
.PricerHeader th.sortable {
  color: #595959;
  font-size: 12px;
}
.dark .PricerHeader th.sortable {
  color: #9ea4ab;
  font-size: 12px;
}

.dark.pricing_table_v table.table tr th,
.dark.pricing_table_v .form-control.form-control-sm {
  background-color: #2c2e335c;
  border: 1px solid #41464d57 !important;
  border-radius: 3px !important;
  color: #fff;
  max-width: 200px;
}
.pricing_table_v .form-control.form-control-sm:focus {
  box-shadow: none;
}
.pricing_table_v table.table tr button {
  min-width: 90px;
}

.pricing_table_v .dataTables_paginate .page-item.disabled .page-link,
.pricing_table_v .dataTables_paginate .page-item.next .page-link,
.pricing_table_v .dataTables_paginate .page-item.previous .page-link {
  color: #fff;
  background: #20242c;
  border: 1px solid #282c34 !important;
  padding: 8px;
  border-radius: 3px !important;
  font-size: 14px;
}

.pricing_table_v .dataTables_paginate .page-item.active .page-link {
  z-index: 1;
  background-color: #007bff00;
  border-color: #007bff00;
  color: #fa501c;
}

.pricing_table_v .dataTables_paginate .page-item .page-link {
  z-index: 1;
  background-color: #007bff00;
  border-color: #007bff00;
  color: #fff;
}
.pricing_table_v .dataTables_paginate .page-item .page-link:focus {
  box-shadow: none;
}

.pricing_table_v table.table tbody tr:hover {
  background: #fff4f1;
}

body.dark .pricing_table_v table.table tbody tr:hover {
  background: #2c2e33;
}
.pricing_table_v table.table tr td {
  border-top: 0px solid #fff4f1 !important;
  border-bottom: 1px solid #feded4 !important;
  white-space: nowrap;
  font-size: 14px;
}
body.dark .pricing_table_v table.table tr th,
body.dark .pricing_table_v table.table tr td {
  border-top: 0px solid #2c2e33 !important;
  border-bottom: 1px solid #2c2e33 !important;
}
.pricing_table_v table.table tr th,
.pricing_table_v table.table tr td {
  border-top: 0px solid #fff4f1 !important;
  border-bottom: 1px solid #feded4 !important;
  white-space: nowrap;
}

body.dark .pricing_table_v table.table tr th,
body.dark .pricing_table_v table.table tr td {
  border-top: 0px solid #2c2e33 !important;
  border-bottom: 1px solid #2c2e33 !important;
}
body.dark.vynksafe_new_style .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgb(255, 238, 233);
}

body.dark .text-gray-v {
  color: #595959;
}

body.dark .pricing_table_v table.table .btn.btn-gray-v {
  color: #fff !important;
  background: #fff4f1;
}
body.dark .pricing_table_v table.table .btn.btn-gray-v:hover {
  color: #fff !important;
  background: #fa501c;
}
body.dark .pricing_table_v .form-control.form-control-sm {
  color: #595959 !important;
  background: #fff4f1 !important;
  border: 1px solid #fa501c30 !important;
}
body.dark .pricing_section_v {
  color: #595959;
}
.pricing_table_v th {
  color: #595959;
  font-weight: 600;
}

body.dark .pricing_table_v .dataTables_paginate .page-item.disabled .page-link,
body.dark .pricing_table_v .dataTables_paginate .page-item.next .page-link,
body.dark .pricing_table_v .dataTables_paginate .page-item.previous .page-link {
  color: #131722;
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
}

body.dark .pricing_table_v .dataTables_paginate .page-item .page-link {
  color: #131722;
}
body.dark .pricing_table_v .dataTables_paginate .page-item.active .page-link {
  color: #fa501c;
}
.pricing_section_v {
  background: #fff;
  color: #1b1f28;
}

.dark .pricing_section_v {
  background: #131722;
  color: #fff !important;
}
.dark .dark-gray-bg-v {
  background-color: #131722 !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(255, 238, 233) !important;
}
.dark .table-striped tbody tr:nth-of-type(odd) {
  background-color: #131722 !important;
}

.fs-5v {
  font-size: 12px !important;
  margin-left: 5px;
}
.dark .text-gray-v {
  color: #9ea4ab !important;
}
.text-gray-v {
  color: #595959 !important;
}
.text-red-v {
  color: #fe4845 !important;
}
.text-green-v {
  color: #04b98e !important;
}
.pricing_table_v #pageDropDown {
  color: #595959 !important;
  background: #fff4f1 !important;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
}
.pricing_table_v #pageDropDown {
  background-color: #2c2e335c !important;
  border: 1px solid #41464d57 !important;
  border-radius: 3px !important;
  color: #fff !important;
  margin-right: 10px !important;
}

.pricing_table_v #pageDropDown:focus {
  box-shadow: none !important;
}
.pricing_table_v #pageDropDown {
  display: block;
  color: #595959 !important;
  background: #fff4f1 !important;
  border: 1px solid #fa501c30 !important;
}

body.dark .pricing_table_v #pageDropDown {
  display: block;
  background: #2c2e335c !important;
  border: 1px solid #41464d57 !important;
  border-radius: 3px !important;
  color: #fff;
}
.dark .dropdown-menu {
  background: #ffffff;
  box-shadow: 0 0 10px 0 #131722;
  border: 1px solid #1c232f;
}
.pricing_table_v .pagination .page-item .page-link {
  font-size: 14px;
}

.pricing_table_v .pagination .active.page-item .page-link {
  font-size: 15px;
}
.pricing_table_v .pagination .active.page-item .page-link:focus {
  box-shadow: none;
}
.pricing_table_v .page-item.active .page-link,
.pricing_table_v .active.page-item .page-link,
.pricing_table_v .active.page-item:last-child .page-link {
  background-color: rgba(0, 123, 255, 0) !important;
  border-color: rgba(0, 123, 255, 0) !important;
  color: #fa501c !important;
}

.pricing_table_v .dataTables_paginate .page-item.disabled .page-link,
.pricing_table_v .dataTables_paginate .page-item.next .page-link,
.pricing_table_v .dataTables_paginate .page-item.previous .page-link {
  color: #fff;
  background: #20242c;
  border: 1px solid #282c34 !important;
  padding: 8px;
  border-radius: 3px !important;
  font-size: 14px;
}

.pricing_table_v .dataTables_paginate .page-item.active .page-link {
  z-index: 1;
  background-color: #007bff00;
  border-color: #007bff00;
  color: #fa501c;
}

.pricing_table_v .dataTables_paginate .page-item .page-link {
  z-index: 1;
  background-color: #007bff00;
  border-color: #007bff00;
  color: #fff;
}
.pricing_table_v .dataTables_paginate .page-item .page-link:focus {
  box-shadow: none;
}

.text-orange {
  color: #fa501c !important;
}
.dark .text-orange {
  color: #fa501c !important;
}
.vynksafe_new_style .btn.btn-custom {
  min-height: 50px !important;
}

//   Ecosystem
.our_apps_section_v {
  background-image: url(../../../../public/img/images/light-theme/our_apps_bg.svg);

  background-size: contain;
  background-repeat: no-repeat;
  padding: 8rem 0rem 5rem 0rem;
  background-position: top center;
}

body.dark .our_apps_section_v {
  background-image: url(../../../../public/img/images/our_apps_bg.svg);
}

/*slider css end*/

.our_apps_v_road {
  margin-top: 8rem;
}

.our_apps_v_road_card .our_apps_v_road_card_img {
  background-color: #fdece7;
  border-radius: 100%;
  padding: 15px;
  z-index: 1;
  position: relative;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.3s ease-in-out;
}
.our_apps_v_road_card:hover .our_apps_v_road_card_img {
  background-color: #fa501c;
  transition: 0.3s ease-in-out;
}

.our_apps_v_road_card:hover .our_apps_v_road_card_img img {
  filter: brightness(9);
  transition: 0.3s ease-in-out;
}

.our_apps_v_road::after {
  content: "";
  background-image: url(../../../../public/img/images/our_apps_road_b.svg);
  width: 66%;
  height: 100%;
  position: absolute;
  top: 15%;
  background-repeat: no-repeat;
  background-size: contain;
  left: 17%;
}
.our_apps_v_road_card {
  z-index: 1;
  position: relative;
}
.our_apps_v_road_card_center {
  margin-top: 7%;
}

/*===-- Our Apps section end ---==*/
/*slider css strat*/

.our_app_slider_v .slick-slide {
  margin: 0px 15px;
}

.our_app_slider_v .slick-slide img {
  width: 100%;
}

.our_app_slider_v .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.our_app_slider_v .slick-list {
  position: relative;
  display: block;
  overflow-x: clip;
  margin: 0;
  padding: 0;
  overflow-y: visible;
}
.our_app_slider_v .slick-list:focus {
  outline: none;
}
.our_app_slider_v .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.our_app_slider_v .slick-slider .slick-track,
.our_app_slider_v .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.our_app_slider_v .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.our_app_slider_v .slick-track:before,
.our_app_slider_v .slick-track:after {
  display: table;
  content: "";
}
.our_app_slider_v .slick-track:after {
  clear: both;
}
.our_app_slider_v .slick-loading .slick-track {
  visibility: hidden;
}

.our_app_slider_v .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.our_app_slider_v .slick-slide img {
  display: block;
}
.our_app_slider_v .slick-slide.slick-loading img {
  display: none;
}
.our_app_slider_v .slick-slide.dragging img {
  pointer-events: none;
}
.our_app_slider_v .slick-initialized .slick-slide {
  display: block;
}
.our_app_slider_v .slick-loading .slick-slide {
  visibility: hidden;
}
.our_app_slider_v .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.our_app_slider_v .slick-arrow.slick-hidden {
  display: none;
}

.our_app_slider_logos_v .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  gap: 5px;
}

.our_app_slider_logos_v .slick-dots li button {
  background: #363742;
  color: #363742;
  font-size: 0px;
  height: 10px;
  width: 10px;
  border: none;
  border-radius: 100%;
}

.our_app_slider_v .slick-initialized .slick-slide img {
  transition: transform 0.2s ease-in;
}

.our_app_slider_v .slick-initialized .slick-slide img:hover {
  border: 1px solid #fa501c !important;
  transform: scale(1.1) translateY(-10%);
  transition: transform 0.2s ease-in;
}

/*.slide.text-center.slick-slide.slick-current.slick-active img{
  border: 1px solid #FA501C !important;
    transform: scale(1.1) translateY(-10%);
    transition: transform .2s ease-in;
}*/
/*slider css end*/
.our_app_slider_logos_v .slick-dots li button::before,
.our_app_slider_logos_v .slick-dots li.slick-active button::before {
  display: none;
}

.our_app_slider_logos_v .slick-dots li.slick-active button {
  background-color: #fa501c;
  color: #fa501c;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  clear: both;
}
.our_app_slider_logos_v .slick-dots li {
  height: 10px;
  width: 10px;
}
.our_app_slider_logos_v .slick-dots {
  position: unset;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: unset;
  margin: unset;
  list-style: none;
  text-align: center;
}

.vynksafe_on_mobile_v {
  background-image: url(../../../../public/img/images/light-theme/vynksafe_mobile_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffeee9 !important;
}
body.dark .vynksafe_on_mobile_v {
  background-image: url(../../../../public/img/images/vynksafe_mobile_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1b1f28 !important;
}

.dark .our_app_slider_v {
  background: #2c2e335c !important;
  border: 1px solid #41464d57 !important;
}
.our_app_slider_v {
  background: #ffeee9;
  border: 1px solid #feded4 !important;
  border-radius: 3px !important;
  height: 100%;
  padding: 25px 5px;
}

.security_v {
  background-image: url(../../../../public/img/images/light-theme/security_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
body.dark .security_v {
  background-image: url(../../../../public/img/images/security_bg.png);
}

.featured_section_v {
  background-image: url(../../../../public/img/images/light-theme/featured_bg.svg) !important;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 5rem 0rem;
}
body.dark .featured_section_v {
  background-image: url(../../../../public/img/images/featured_bg.svg) !important;
}

/*===--- Featured section start ---===*/
.featured_section_v {
  background-image: url(../../../../public/img/images/featured_bg.svg);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 5rem 0rem;
}

.featured_ul_section_v li {
  background: #ffeee9;
  border: 1px solid #feded4 !important;
  color: #131722;
  padding: 10px 10px 10px 60px;
  border-radius: 100px;
  max-width: 400px;
  width: 100%;
  min-height: 70px;
}
ul.text-white.featured_ul_section_v {
  font-size: 1.1rem;
}

body.dark .featured_ul_section_v li {
  background: #2c2e335c;
  border: 1px solid #41464d57 !important;
  color: white;
}

.manage_profits_v::after {
  content: "";
  background: url(../../../../public/img/images/manage_profits.svg), #fa501c;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -46px;
  top: -10px;
  border-radius: 100%;
  padding: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.exchange_freedome_v::after {
  content: "";
  background: url(../../../../public/img/images/exchange_freedome_v.svg),
    #fa501c;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -46px;
  top: -10px;
  border-radius: 100%;
  padding: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.experienced_engineers::after {
  content: "";
  background: url(../../../../public/img/images/experienced_engineers.svg),
    #fa501c;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -46px;
  top: -10px;
  border-radius: 100%;
  padding: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.featured_in_section_v ul {
  gap: 20px;
}

.featured_in_section_v ul li {
  background: #ffeee9;
  border: 1px solid #feded4 !important;
  padding: 8px;
  border-radius: 3px !important;
  min-height: 60px;
  width: 220px;
  align-items: center;
  display: flex;
  justify-content: center;
}

body.dark .featured_in_section_v ul li {
  background: #20242c;
  border: 1px solid #282c34 !important;
}

/*==--- Featured section end --==*/
/*==--- Subscribe Us section Start ---===*/

.subscribe_section_v {
  background: rgb(27, 31, 40);
  background: linear-gradient(
    0deg,
    rgb(255, 238, 233) 50%,
    rgb(255, 255, 255) 50%
  );
  padding: 5rem 0rem;
}
body.dark .subscribe_section_v {
  background: rgb(27, 31, 40);
  background: linear-gradient(
    0deg,
    rgba(27, 31, 40, 1) 50%,
    rgba(19, 23, 34, 1) 50%
  );
}

.subscribe_inner_section_v {
  background-image: url(../../../../public/img/images/light-theme/get_started_bg.svg);

  background-size: cover;
  background-repeat: no-repeat;
}
.dark .subscribe_inner_section_v {
  background-image: url(../../../../public/img/images/subscribe_us_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.vynksafe_new_style .subscribe_inner_section_v form .input-group {
  background: #ffeee9;
  border: 1px solid #feded4 !important;
  padding: 3px;
  border-radius: 3px !important;
  min-height: 43px;
  align-items: center;
}

.dark.vynksafe_new_style .subscribe_inner_section_v form .input-group {
  background: #2c2e33;
  border: 1px solid #41464d !important;
}

.vynksafe_new_style .subscribe_inner_section_v form .input-group input,
.vynksafe_new_style .subscribe_inner_section_v form .input-group input:focus {
  background: none;
  border: none;
  box-shadow: none;
  color: #9ea4ab;
}

body.dark.vynksafe_new_style
  .getstarted_inner_section_v
  form
  .input-group
  input {
  color: #131722 !important;
}

body.dark .getstarted_inner_section_v {
  background-image: url(../../../../public/img/images/subscribe_us_bg.png);
}
body.dark .our_app_slider_v,
body.dark.vynksafe_new_style .getstarted_inner_section_v form .input-group {
  background: #1b1f28;
  border: 1px solid #41464d !important;
}
.dark.vynksafe_new_style .dark.getstarted_inner_section_v form .input-group {
  background: #2c2e33;
  border: 1px solid #41464d !important;
  padding: 3px;
  border-radius: 3px !important;
  min-height: 43px;
  align-items: center;
}
/*===-- Subscribe Us section end ---===*/
body.dark.vynksafe_new_style
  .getstarted_inner_section_v
  form
  .input-group
  input {
  color: #ffffff !important;
}

body.dark .getstarted_inner_section_v {
  background-image: url(../../../../public/img/images/get_started_bg.svg);
}

.getstarted_inner_section_v {
  background-image: url(../../../../public/img/images/light-theme/get_started_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.vynksafe_new_style .getstarted_inner_section_v form .input-group {
  background: #ffeee9;
  border: 1px solid #feded4 !important;
  padding: 3px;
  border-radius: 3px !important;
  min-height: 43px;
  align-items: center;
}

.vynksafe_new_style .getstarted_inner_section_v form .input-group input,
.vynksafe_new_style .getstarted_inner_section_v form .input-group input:focus {
  background: none;
  border: none;
  box-shadow: none;
  color: #9ea4ab;
} /* Variables */

.pricing_table_v button#pageDropDown {
  position: absolute;
  top: 0px;
}

span.react-bootstrap-table-pagination-total {
  margin-left: 50px;
  // margin-left: 70px;
  // position: absolute;
  /* margin-top: 35px; */
  // top: -35%;
}

.markets_watch_table_tabsv nav {
  gap: 20px;
  border-bottom: 1px solid #41464d;
  width: fit-content;
  margin-bottom: 20px;
}
.markets_watch_table_tabsv nav a {
  font-size: 15px;
  color: #9ea4ab !important;
}

.markets_watch_table_tabsv nav a.active,
.markets_watch_table_tabsv nav a:hover {
  color: #fa501c !important;
  border-bottom: 1px solid #fa501c !important;
  background: none !important;
}
.markets_watch_table_tabsv nav a:focus,
.markets_watch_table_tabsv nav a:focus-visible,
.markets_watch_table_tabsv nav a:hover {
  box-shadow: none !important;
}
.markets_watch_table_tabsv .nav-tabs .nav-link {
  border: none;
}

.markets_watch_table_tabsv nav {
  gap: 20px;
  border-bottom: 1px solid #41464d;
  width: fit-content;
  margin-bottom: 20px;
}
.markets_watch_table_tabsv nav a {
  font-size: 15px;
  color: #9ea4ab !important;
}

.markets_watch_table_tabsv nav a.active,
.markets_watch_table_tabsv nav a:hover {
  color: #fa501c !important;
  border-bottom: 1px solid #fa501c !important;
  background: none !important;
}
.markets_watch_table_tabsv nav a:focus,
.markets_watch_table_tabsv nav a:focus-visible,
.markets_watch_table_tabsv nav a:hover {
  box-shadow: none !important;
}
.markets_watch_table_tabsv .nav-tabs .nav-link {
  border: none;
}

.dark .markets_watch_table_tabsv input,
.dark .markets_watch_table_tabsv input:focus,
.dark .pricing_section_v .dark .pricing_table_v input {
  background-color: #2c2e335c !important;
  border: 1px solid #41464d57 !important;
  border-radius: 3px !important;
  color: #fff !important;
  max-width: 200px;
  box-shadow: none;
}
.markets_watch_table_tabsv input,
.markets_watch_table_tabsv input:focus,
.pricing_section_v .pricing_table_v input {
  color: #595959;
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
  border-radius: 3px !important;
  max-width: 200px;
  box-shadow: none;
}

.dark input#search-bar-0 {
  background-color: #2c2e335c !important;
  border: 1px solid #41464d57 !important;
  border-radius: 3px !important;
  color: #fff !important;
  max-width: 200px;
  box-shadow: none;
}

/*===========---------- Company page css start -----------===============*/

.common_section_v {
  padding: 6rem 0 !important;
}
.team_social_icons {
  gap: 10px;
}
body.dark .our_apps_v_road_card .our_apps_v_road_card_img {
  background-color: #572b1e;
}
.team_content_v .team_social_icons li:hover a {
  background-color: #fff;
  border-radius: 3px;
}

.company_top_sec_v_road::after {
  content: "";
  background-image: url(../../../../public/img/images/our_apps_road_b.svg);
  width: 75%;
  height: 24%;
  position: absolute;
  top: 17%;
  background-repeat: repeat;
  background-size: contain;
  left: 14%;
}

.our_team_card_v .team_v_img {
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 3px;
}
.our_team_card_v .team_v_img img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}
.our_team_card_v:hover .team_v_img img {
  transform: scale(1.1);
}

.company_about_us_section_v {
  background-image: url(../../../../public/img/images/light-theme/company/about_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.company_our_team_section_v {
  background-image: url(../../../../public/img/images/light-theme/company/ourteam_bg.svg);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: 40% 10%;
}

.journey_section_right_inner_card {
  z-index: 0;
  position: relative;
}

.journey_section_right_inner h1 {
  font-size: 90px !important;
  margin-bottom: -25px;
  font-weight: bold;
  text-shadow: 4px 4px #ffffff2e;
  color: #f9f9f9;
}

/*.company_journey_section_right_v {
  border-left: 1px solid #fa501c;
  padding-left: 30px;
  height: 650px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: red #fff;
}*/
.company_journey_section_right_v {
  height: 650px;
  overflow-y: scroll;
  scrollbar-width: none;
  scrollbar-color: #ffffff91 #fa501c;
  direction: rtl;
  overflow-x: hidden;
  /*  border-left: 1px solid #fa501c;*/

  border-width: 3px 0 3px 3px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      100% 0,
      0 0,
      from(#fa501c),
      torgba(248, 110, 69, 0.21)
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(
      to bottom,
      #fa501c,
      rgba(248, 110, 69, 0.21)
    )
    1 100%;
  -moz-border-image: -moz-linear-gradient(
      to bottom,
      #fa501c,
      rgba(248, 110, 69, 0.21)
    )
    1 100%;
  -o-border-image: -o-linear-gradient(
      to bottom,
      #fa501c,
      rgba(248, 110, 69, 0.21)
    )
    1 100%;
  border-image: linear-gradient(to bottom, #fa501c, rgba(248, 110, 69, 0.21)) 1
    100%;
}
.company_journey_section_right_v::-webkit-scrollbar {
  width: 0px;
}

.company_journey_section_right_v::-webkit-scrollbar-track {
  border-radius: 2rem !important;
  background-color: #fa501c;
}

.company_journey_section_right_v::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 2rem !important;
}

.journey_section_right_inner:hover .journey_section_right_inner_card {
  border: 1px solid #fa501c !important;
  background: #fa501c;
  color: #fff;
}
.journey_section_right_inner:hover .journey_section_right_inner_card p {
  color: #fff;
}

.company_journey_section_v {
  background-image: url(../../../../public/img/images/light-theme/company/journey.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.lets_talk_section_v form label {
  color: #9ea4ab;
}
.lets_talk_section_v .gray-bg-v {
  background-color: #ffeee9 !important;
}

.lets_talk_section_v form input,
.lets_talk_section_v form input:focus,
.lets_talk_section_v form textarea,
.lets_talk_section_v form textarea:focus {
  background: #fbe7e1;
  border: 1px solid #ffc9ba !important;
  border-radius: 3px !important;
  color: #131722;
  box-shadow: none;
}
body.dark .lets_talk_section_v form input,
body.dark .lets_talk_section_v form input:focus,
body.dark .lets_talk_section_v form textarea,
body.dark .lets_talk_section_v form textarea:focus {
  background: #20232a;
  border: 1px solid #41464d !important;
  border-radius: 3px !important;
  color: #fff;
  box-shadow: none;
}
/*==========--------------- Company page css start -------------==============*/

body.dark .company_about_us_section_v {
  background-image: url(../../../../public/img/images/company/about_bg.svg);
}

body.dark .company_our_team_section_v {
  background-image: url(../../../../public/img/images/company/ourteam_bg.svg);
}

body.dark.vynksafe_new_style .team_social_icons li.gray_bg_v {
  background: #fff;
}
body.dark.vynksafe_new_style .team_social_icons li.gray_bg_v:hover a {
  background: #fff4f1;
}

body.dark .company_journey_section_v {
  background-image: url(../../../../public/img/images/company/journey.png);
}

body.dark.vynksafe_new_style
  .journey_section_right_inner:hover
  .journey_section_right_inner_card {
  background: #fa501c;
}

body.dark.vynksafe_new_style
  .journey_section_right_inner:hover
  .journey_section_right_inner_card
  h5 {
  color: #fff;
}

body.dark .journey_section_right_inner h1 {
  text-shadow: 4px 4px #9591912e;
  color: #1f232d;
}

body.dark .lets_talk_section_v .gray-bg-v {
  background-color: #1b1f28 !important;
}

body.dark .lets_talk_section_v form label {
  color: #9ea4ab;
}
.company_contact_us_social_icons {
  display: grid;
  gap: 18px;
}
.company_contact_us_social_icons li a {
  color: #9ea4ab;
}
.company_contact_us_social_icons li img {
  height: auto !important;
}
.company_contact_us_social_icons li {
  width: fit-content;
}
.company_contact_us_social_icons li:hover img {
  background-color: #fff;
}

body.dark .company_contact_us_social_icons li a {
  color: #9ea4ab;
}
body.dark .company_contact_us_social_icons li:hover img {
  background-color: #fff !important;
}

body.dark .company_contact_us_social_icons li img {
  background-color: #fbe7e1 !important;
  border: 1px solid #ffc9ba !important;
}
body.dark .company_contact_us_social_icons .gray_bg_v {
  background: #1b1f28 !important;
  border: 1px solid #41464d57 !important;
}
body.dark .lets_talk_section_v form input::placeholder,
body.dark .lets_talk_section_v form textarea::placeholder {
  color: #131722;
}

body.dark.vynksafe_new_style
  .subscribe_inner_section_v
  form
  .input-group
  input {
  color: #131722;
}

.tableTrade:hover{
color: #fa501c !important;
}


/*==========--------------- Company page css end -------------==============*/

tr.text-white.my-2 {
  font-size: 14px;
}

