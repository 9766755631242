.market-history .nav {
  // background: #f5f9fc;
}

.market-history .nav-link {
  color: #4a4a4a;
  padding: 10px 13px;
}

.market-history .nav-link.active {
  color: #1EC6F6;
  background: transparent;
}

.market-history thead tr th,
.market-history tbody tr td {
  font-weight: 400;
  padding: 5px 15px !important;
}

.market-history tbody {
  height: 100%;
  // display: block;
  // min-height: 315px;
  // overflow-y: auto;
}

// .market-history.recent table {
//   height: 346px;
//   overflow: auto;
//   display: block;
// }
.market-history td,
.market-history th {
  white-space: nowrap;
}

// .market-history table,
// .market-history tr,
// .market-history td,
// .market-history th {
//   // width: 100%;
//   // table-layout: fixed;
// }
.market-history.recent table tbody tr td:last-child,
.market-history.recent table tbody tr td:nth-child(2),
.market-history.recent table thead th:last-child,
.market-history.recent table thead th:nth-child(2) {
  text-align: right;
}

// .market-history tr {
//   // float: left;
//   clear: both;
//   overflow: hidden;
// }

.market-history {
  // border: 1px solid #e0e3eb;
  border-radius: 2px;
  // height: 60%;
  height: 418px;
  overflow: hidden;
}

.market-history.recent .tab-pane {
  scrollbar-width: none;
  height: 478px;
  overflow-y: auto;
  overflow-x: auto;
  //max-height: 358px;
  max-height: 482px;
}

.cancel_all_orders {
  height: 20px;
  // color: #4f5966;
  color: #5E728F;
  padding: 0;
  background-color: transparent;
  border: unset;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.cancel_all_orders:hover {
  color: #000;
}

.dark .cancel_all_orders:hover {
  // color : #e0e3eb
  color: #1EC6F6;
}

.cancel_all_orders:disabled:hover {
  // color: #4a4a4a;
  color: #5E728F;
}

.cancel_all_orders:disabled {
  cursor: not-allowed;
  // color: #4a4a4a;
  color: #5E728F;
}

.order_cancel_btn {
  border: 1px solid #959699;
  border-radius: 6px;
  height: 26px;
  display: flex;
  justify-content: center;
  min-width: 80px;
  min-height: 26px;
  align-items: center;
  font-size: 12px;
  color: white;
  background-color: #959699;
}

// .order_cancel_btn:hover {
//   border-color: #000;
//   color: #000;
// }

.dark .order_cancel_btn:hover {
  border-color: #e0e3eb;
  color: #e0e3eb;
}

.order_cancel_btnn {
  border: 1px solid #959699;
  border-radius: 6px;
  height: 26px;
  display: flex;
  justify-content: center;
  min-width: 80px;
  min-height: 26px;
  align-items: center;
  font-size: 12px;
}

.order_cancel_btnn:hover {
  border-color: #000;
  color: #000;
}

.dark .order_cancel_btnn:hover {
  border-color: #e0e3eb;
  color: #e0e3eb;
}

.common_modal .modal-content {
  padding: 40px;
  border-radius: 10px;
}

.modal_body {
  padding: 12px;
  text-align: center;
}

.modal_body h5 {
  text-align: center;
  font-weight: 600;
}

.modal_body p {
  font-size: 14px;
  text-align: center;
}

.button_bar {
  display: flex;
  justify-content: center;
}

.button_bar button {

  margin: 0 10px;
}

.button_bar button {
  border: unset;
  background-color: #4f5966;
  border-radius: 5px;
  color: #fff;
}

.market-history.recent .login_first_block {
  border: unset;
  margin: 20px 0;
}

.button_bar button.modal_delete_btn {
  background-color: red;
}

.button_bar button.modal_delete_btn:hover {
  background-color: rgb(228, 10, 10);
}

@media(max-width:767px) {
  .common_modal .modal-content {
    padding: 40px 20px;
  }

  .common_modal .modal_body h1 {
    font-size: 32px;
  }

}

@media(max-width:450px) {
  .common_modal .modal-content {
    padding: 20px;
  }

  .common_modal .modal_body h1 {
    font-size: 22px;
  }

  .common_modal .modal_body p {
    font-size: 14px;
  }

  .common_modal .button_bar button {
    padding: 5px;
    font-size: 14px;
  }
}

.order_cancel_btn .icon.ion-md-close {
  font-size: 16px;
  margin-right: 7px;
  display: flex;
}
