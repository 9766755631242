ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

// img {
//   max-width: 100%;
//   height: auto;
// }

.mtb15 {
  margin: 15px 0;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mr15 {
  margin-right: 15px;
}

.ptb70 {
  padding: 70px 0;
}

.pb70 {
  padding-bottom: 70px;
}

.pt70 {
  padding-top: 70px;
}

.pb40 {
  padding-bottom: 40px;
}

::-webkit-scrollbar {
  background: transparent;
  width: 3px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.07);
  border: solid rgba(88, 88, 88, 0.12) 2px;
  border-radius: 6px;
}

.dropdown-toggle::after {
  vertical-align: 1px;
}

thead tr th {
  //padding: 10px 15px !important;
  padding: 5px 15px !important;
}

thead th {
  color: #758696;
  font-size: 12px;
  font-weight: 600;
  border: 0 !important;
}

tbody tr {
  color: #4a4a4a;
}

// tbody tr:hover {
//   background: #f6f8f9;
// }

.input-group-text,
.form-control {
  border-radius: 6px;
}

.table tbody + tbody {
  border: none;
}

.heading {
  /*background: #f5f9fc;*/
  font-size: 14px;
  font-weight: 400;
  padding: 13px;
  margin: 0;
  color: #4a4a4a;
}

.green {
  color: rgb(14, 203, 129) !important;
}

.red {
  color: rgb(207, 48, 74);
}

.table td,
.table th {
  //border-top: 1px solid #f0f3fa;
  border-top: none;
}

.light-bb,
.input-group-text {
  border: 1px solid #e0e3eb;
}

.dark-bb {
  border-bottom: 1px solid #2a2e39;
}

#darkDepthChart,
#lightDepthChart {
  width: 100%;
  height: 349px;
}

.sm-gutters {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.sm-gutters > .col,
.sm-gutters > [class*="col-"] {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #18214d59;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #18214d59;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #18214d59;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #18214d59;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.slick-slide > div {
  margin: 0 8px;
}

.slick-list {
  margin: 0 -8px;
}

.page-content {
  margin: 40px 0 100px;
}

.page-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 60px;
}

.page-content p {
  font-size: 16px;
}

.news-details {
  padding: 100px 0;
}

.news-details h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.news-details p {
  font-size: 16px;
}

.spinner-border-sm {
  margin-left: 10px;
  margin-bottom: -3px;
}

.dark .navbar-toggler-icon {
  filter: invert(1);
}

.trade_ctm_header tr td span {
  display: block;
  font-size: 13px;
  color: rgb(132, 142, 156);
}

.trade_ctm_header tr td.btc_currently_open {
  font-size: 20px !important;
}

.table .trade_ctm_header tr td {
  padding: 10px;
  min-width: 95px;
}

.dark .table .trade_ctm_header tr td.red span {
  //color: #41464d;
}

.table .trade_ctm_header tr td.red span {
  //color: #4a4a4a;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  // background-color: #41464d !important;
  // background-color: #fa501c !important;
  border-radius: 0px !important;
  color: #fa501d !important;
}

.dark .nav-pills .nav-link.active,
.dark .nav-pills .show > .nav-link {
  // background-color: #41464d !important;
  border-radius: 0px !important;
  color: #fa501d !important;
}
.profile-left-tab {
  border-right: 1px solid #e0e3eb;
}

.profile-left-tab .nav-pills .nav-link.active img.deactivate {
  display: none;
}

.profile-left-tab .nav-pills .nav-link.active img.active {
  display: block;
}

.profile-left-tab .settings-nav .nav-link img.active {
  display: none;
}

.profile-left-tab .settings-nav .nav-link img {
  width: 16px;
  object-fit: none;
}

.settings_top_title p {
  color: #41464d;
}

.settings .settings_top_title .card-title {
  margin-bottom: 5px;
}

.border-bottom-none {
  border-bottom: none !important;
}

.notification-info-inner-section {
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
}

.text-gray {
  color: #4f5966;
}

.notification-info.notification-info-w50 {
  width: 50%;
  padding-right: 1rem;
}

.notification-info-p-w30 {
  width: 30%;
  padding-left: 1rem;
}

.notification-info-button-w20 {
  width: 20%;
  justify-content: end;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .card-body .settings-notification ul li {
    display: block !important;
  }

  .notification-info.notification-info-w50 {
    width: 100%;
    padding-right: 0rem;
    margin-bottom: 1rem;
  }

  .notification-info-p-w30 {
    width: 100%;
    padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .notification-info-button-w20 {
    width: 100%;
    justify-content: start;
    display: flex;
  }

  .notification-info-inner-section {
    display: block;
  }

  .notification-info-inner-section .notification-info-p-w30 {
    width: 100%;
    padding-left: 0rem;
  }

  .notification-info-inner-section .notification-info-button-w20 {
    padding-left: 0rem;
  }
}

.card-body.requirements-body p {
  color: #41464d;
}

button.btn.verification-btn {
  background-color: #fa501c;
  font-weight: 600;
  padding: 10px 25px;
  height: inherit;
  font-size: 16px;
  color: #fff;
  border-radious: 7px;
}

.card-body.requirements-body {
  min-height: 325px;
}

.advanced-verification-body {
  min-height: 325px;
}

.verified-img {
  min-height: 88px;
  display: flex;
  align-items: end;
  justify-content: center;
}

ul.markit_Trade_list {
  font-size: 12px;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card.security-verification-card .security-verification ul li {
  color: #41464d;
}

.card.security-verification-card .security-verification ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.card.security-verification-card
  .security-verification
  ul
  li
  span.left-li-border {
  width: 1px;
  display: block;
  background-color: #5f738f2e;
  height: 100%;
}

.estimated-balance-btn {
  gap: 10px;
  display: flex;
  color: #fff;
  flex-wrap: wrap;
}

.estimated-balance-btn button {
  background-color: #41464d73;
  border-top: 6px;
  color: #fff !important;
}

.estimated-balance-btn button {
  background-color: #959699;
  // border-top: 6px;
  color: #fff !important;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
}

.estimated-balance-btn button.btn.deposit-btn {
  background-color: #fa501c;
}

.card-estimated-balance-section h3.card-estimated-balance span {
  margin-left: 10px;
}

.notification-info span {
  color: #41464d;
}

.explore_search_bar img {
  filter: grayscale(1);
}

//dashboard right menu css start

button.dashboard-profile-edit {
  border: none;
  background: none;
  padding: 0;
  width: auto;
  height: auto;
}

span.profile-status-personal.badge {
  background: #fa501d;
  color: #fff;
  font-size: 14px !important;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 6px;
}

.dark span.profile-status-personal.badge {
  background: #41464d7a;
  color: #fff;
  font-size: 14px !important;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 6px;
  line-height: 1!important;
}
.announcements-section i.ion.ion-ios-arrow-forward {
  color: #fa501c;
  font-size: 18px;
}

.announcements-section {
  display: flex;
}
.announcements-section.top_border:hover {
  background: #b6bfcc;
}
.dark .announcements-section.top_border:hover {
  background: #2a3443;
}

.announcements-section {
  display: flex;
  // border: 1px solid #ced4da;
  border-radius: 15px;
  padding: 15px;
}

.Chart_Active_link nav.nav.nav-tabs {
  padding-left: 20px;
}

.market-tabs .table_wrapper {
  overflow: auto;
}

//dashboard right menu css end

// .btn-primary {
//   color: #fff;
//   background-color: #fa501c !important;
//   border-color: #fa501c !important;
// }

// .btn-primary:hover {
//   background-color: #0dafdd !important;
//   border-color: #0dafdd !important;
// }

.btn-primary {
  color: #fff;
  background-color: #fa501c !important;
  border-color: #fa501c !important;
}

.btn-primary:hover {
  background-color: white !important;
  color: #fa501c !important;
  border-color: #fa501c30 !important;
}

.myassets_card p > i.ion.ion-ios-arrow-forward {
  color: #fa501c;
  font-size: 18px;
}

.myassets_card p:first-child {
  color: #41464d;
  justify-content: space-between;
  display: flex;
}

.myassets_card img {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.myassets_card {
  border: 1px solid rgba(94, 114, 143, 0.3);
  border-radius: 15px;
  position: relative;
}

.overview_right_recenttransactions {
  display: flex;
  justify-content: space-between;
}

.overview_no_open_orders {
  min-height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.estimated-balance-btn button.btn.account-export-btn {
  background: none;
  border: 1px solid #fa501c;
  color: #fa501c !important;
}

.estimated-balance-btn button.btn.account-export-btn img {
  margin-right: 6px;
  width: 16px;
}

.AccountStatement_header tr:hover {
  background: none !important;
}

.AccountStatement_header tr td:first-child {
  padding-left: 0px;
}

.AccountStatement_totalvalue_header tr p,
.AccountStatement_totalvalue_header tr p span {
  font-size: 22px !important;
}

.account_statement_box .col-xl-2 {
  max-width: 20%;
  flex: auto;
}

.spot-balance-section {
  width: 100%;
}

.spot_day_pnl p > i.ion.ion-ios-arrow-forward {
  color: #fa501c;
  font-size: 18px;
  margin-left: 1rem;
}

.spot_day_pnl h5 {
  color: #03ba8e;
  margin: 0px;
}

.spot_day_pnl p {
  margin: 0px;
  align-items: center;
  display: flex;
}

.spot_balance_small_balannces {
  display: flex;
  column-gap: 20px;
}

.spot_balance_small_balannces button.btn {
  color: #fa501c;
  text-decoration: underline;
  padding: 0px;
}

.wallet_crypto_balance_search input.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.funding_estimated_balance_right button.btn span {
  background-color: rgb(94 114 143 / 19%);
  padding: 0px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.funding_estimated_balance_right button.btn {
  padding: 0px;
  height: max-content;
}

.funding_estimated_balance_right button.btn:focus {
  box-shadow: none;
  border-color: none;
}

.funding_estimated_balance_right {
  gap: 21px;
  display: flex;
}

.account-statement-due-date input::-webkit-calendar-picker-indicator {
  // background-image: url(http://io.pixelsoftwares.com:3000/exchange/v1/img/wallet-overview/calendar.png);
  background-image: url(../../../../public/img/wallet-overview/calendar.png);
}

.transaction_history_filter .form-group .form-control {
  background: none;
  border: 1px solid #fa501c30 !important;
  border-radius: 6px;
  overflow: hidden;
  color: #41464d;
}
.dark .transaction_history_filter .form-group .form-control {
  background: none;
  border: 1px solid #41464d !important;
  border-radius: 6px;
  overflow: hidden;
  color: white;
}

.dark
  .wallet_network_select
  .custom_select__control
  .custom_select__placeholder {
  // color: #41464d !important;
  color: #9ea4ab !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  padding-left: 4px !important;
}
  .wallet_network_select
  .custom_select__control
  .custom_select__placeholder {
  color: #41464d !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  padding-left: 4px !important;
}

.dark .css-tj5bde-Svg {
  color: #fff;
}

.dark .css-1u9des2-indicatorSeparator {
  background-color: #41464d;
}

.account_statement_search_btn button.btn.search-btn {
  background-color: #fa501c;
  padding: 8px 15px;
}

.account_statement_search_btn button.btn.reset-btn {
  background-color: #fff0;
  padding: 0px;
  color: #41464d !important;
}

.estimated-balance-btn.account_statement_search_btn {
  gap: 16px;
}

.modal-content .modal-header {
  border-bottom: 1px solid #4e4f513f !important;
  padding: 1rem 0rem !important;
}

.dark .modal-content .modal-header {
  border-bottom: 1px solid #4e4f513f !important;
  padding: 1rem 0rem !important;
}

.account_statement_width {
  min-width: 19%;
}

tbody.AccountStatement_header tr {
  row-gap: 0px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.account_statement_width {
  width: 19%;
}

.transaction_history_form_filter {
  width: 32%;
}

.crypto_balance_table_main .react-bootstrap-table {
  overflow: auto;
}

table.table.crypto_balance_table {
  min-width: 710px;
}

.dark table.table.crypto_balance_table {
  background-color: #131722;
}

.dark tbody.Table_Body tr {
  background: #0e121c;
  border-bottom: 1px solid #80808045;
}

tbody.Table_Body tr {
  background: #ffffff;
  border-bottom: 1px solid #80808045;
}

tbody.Table_Body tr td {
  font-size: 12px;
  padding: 20px 15px;
  cursor: pointer;
}

tr.Transiction_header th.sortable {
  padding: 10px 15px !important;
}

.Chart_Active_link .recharts-wrapper,
.Chart_Active_link .recharts-wrapper .recharts-surface {
  width: 100% !important;
}

.dark .Select_font {
  font-size: 12px;
  background-color: #0e121c !important;
}

.Select_font {
  font-size: 12px;
}

.btn.deposit-btn.nav-link.active,
.btn.withdraw-btn.nav-link.active {
  background-color: #fa501c !important;
  color: #fff !important;
  padding: 5px 10px;
  font-size: 14px;
}

.btn.withdraw-btn.nav-link,
.btn.deposit-btn.nav-link,
.estimated-balance-btn .nav-item a.btn.history-btn {
  // background-color: rgba(94, 114, 143, 0.4509803922);
  // color: #fff !important;
  border: 1px solid rgba(250, 80, 28, 0.188) !important;
  border-radius: 3px;
  color: #fa501c !important;
  background: #fff4f1 !important;
  padding: 5px 10px;
  font-size: 14px;
}
.dark .btn.withdraw-btn.nav-link,
.dark .btn.deposit-btn.nav-link,
.dark .estimated-balance-btn .nav-item a.btn.history-btn {
  // background-color: rgba(94, 114, 143, 0.4509803922);
  // color: #fff !important;
  border: 1px solid #41464d !important;
  border-radius: 3px;
  color: white !important;
  background: #131722 !important;
  padding: 5px 10px;
  font-size: 14px;
}
.dark .btn.deposit-btn.nav-link.active,
.dark .btn.withdraw-btn.nav-link.active {
  border: 1px solid #41464d !important;
  background-color: #fa501c30 !important;
  color: #fa501c !important;
  padding: 5px 10px;
  font-size: 14px;
}
.estimated-balance-btn-postion {
  position: absolute;
  top: -53px;
  right: 14px;
}

.settings .card-small-title {
  font-size: 16px;
  margin-bottom: 30px;
}

ul.mywallet-address-liststyle li::before {
  content: "•";
  color: #fa501c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 20px;
  line-height: 0;
  margin-left: -17px;
}

ul.mywallet-address-liststyle {
  margin-left: 1rem;
}

ul.mywallet-address-liststyle li span {
  color: #fa501c;
}

ul.mywallet-address-liststyle li {
  line-height: 30px;
  font-size: 14px !important;
}

.mywallet-qrcode {
  width: 240px;
  max-width: 240px;
  border: 1px solid #fa501c !important;
  border-radius: 6px;
  padding: 15px 20px;
}

ul.select-main-coins-wallet {
  gap: 8px;
}

nav.Wallet_tab.nav.nav-tabs a.nav-item.nav-link:first-child {
  padding-left: 0px;
}

.form-group.market_trade_input_block input.form-control {
  background: none !important;
  // border: 1px solid rgba(30, 198, 246, 0.4588235294) !important;
  border-radius: 6px !important;
  overflow: hidden !important;
  padding: 20px 15px !important;
}

.Qr_mytooltip {
  background-color: transparent;
}

.Qr_mytooltip > .tooltip-inner {
  max-width: 100% !important;
  background-color: transparent !important;
}

.mytooltip > .tooltip-arrow {
  background-color: transparent !important;
}

.qrin_Dark {
  background-color: #0e121c9c;
  height: 38px;
  width: 33px;
  border-radius: 2px;
}

.dark .qrin_Dark {
  background-color: #0e121c00;
  height: 38px;
  width: 33px;
  border-radius: 2px;
}

.btn-info {
  color: #fff;
  background-color: #fa501c !important;
  border-color: #fa501c !important;
}

.btn-info:hover {
  color: #fa501c;
  background-color: #fff !important;
  border-color: #41464d  !important;
}

.form-group.market_trade_input_block button.btn.btn-info {
  position: absolute;
  right: 20px;
  top: 11px;
  background: none !important;
  border: none !important;
  font-size: 14px;
  color: #fa501c;
  padding: 0px !important;
}

.form-group.market_trade_input_block.withdraw-amount-input-block
  input.form-control {
  padding-right: 4rem !important;
}

.form-group.market_trade_input_block button.btn.btn-info:focus {
  box-shadow: none !important;
}

.withdraw-amount-select-wallet input[type="radio"] {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  padding: 6px !important;
  border: 1px solid #fa501c !important;
  margin-top: 2px !important;
}

.withdraw-amount-select-wallet input[type="radio"]:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: 20% auto;
  border-radius: 50%;
  left: 0px;
  top: 0;
  position: absolute;
}

.withdraw-amount-select-wallet input[type="radio"]:checked:before {
  background: #fa501c;
  position: absolute;
  top: 0px;
  right: 0px;
}

.withdraw-amount-select-wallet .form-check label.form-check-label {
  margin-left: 7px;
}

.withdrawal_confirmation_modal .modal-content {
  border-radius: 14px;
  margin: auto;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.withdrawal_confirmation_modal .modal-footer button.btn {
  width: 45%;
  padding: 10px;
}

.withdrawal_confirmation_modal .modal-footer button.btn.btn-secondary {
  color: #ffffff !important;
  background-color: #41464d87;
  border: none;
}

.withdrawal_confirmation_modal .modal-footer button.btn.btn-secondary:hover {
  color: #ffffff !important;
  background-color: #41464d9c !important;
  border-color: #545b62;
}

.withdrawal_confirmation_modal .modal-footer,
.withdrawal_confirm_details_modal .modal-footer {
  border-top: none !important;
}

.dark .withdrawal_confirm_details_modal .modal-header,
.withdrawal_confirm_details_modal .modal-header,
.transaction_details_Successful_modal .modal-header,
.dark .transaction_details_Successful_modal .modal-header {
  border-bottom: none !important;
  padding: 1rem !important;
}

.withdrawal_confirm_details_info ul li p {
  min-width: 130px;
}

.withdrawal_confirm_details_info ul li {
  text-align: left;
}

.withdrawal_confirm_details_info,
.transaction_details_Successful {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
}

.withdrawal_confirm_details_info ul {
  position: relative;
  width: 100%;
  overflow: auto;
}

.withdrawal_confirm_details_info ul:after {
  content: "";
  background: #ffffff24;
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 6rem;
}

.withdrawal_confirm_details_modal .modal-footer button.btn,
button.btn.btn-info.btn-withdraw-w {
  min-width: 160px;
  padding: 10px;
  background-color: #fa501c !important;
  border: none !important;
}

.withdrawal_confirm_details_modal .modal-content,
.transaction_details_Successful_modal .modal-content {
  padding: 0px 1rem !important;
}

.coin_transfer {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
}

i.ion.ion-ios-arrow-back.back_icon {
  cursor: pointer;
}

.internal-transfers-icons i.icon {
  font-size: 30px;
}

.internal-transfers-icons img {
  transform: rotate(90deg);
  height: 100%;
  width: 20px;
}

.coin_transfer .ion-md-arrow-dropdown:before {
  line-height: 0;
}

.coin_transfer i.ion.ion-md-arrow-dropdown {
  color: #41464d;
  font-size: 34px;
}

.cross_margin_section_inner i.ion.ion-md-shuffle {
  color: #5e718f;
  font-size: 24px;
}

.internal_transfers_icon_w {
  min-width: 30px;
  text-align: center;
}

.internal_transfers_form_w {
  min-width: 40px;
}

.transaction_details_Successful h5 {
  color: #3cb550;
}

.transaction_details_Successful_modal ul {
  width: 100%;
  overflow: auto;
  line-height: 25px;
}

.transaction_details_Successful_modal ul li {
  white-space: nowrap;
}

.transaction_details_Successful_modal ul li p {
  min-width: 110px;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0) !important;
}

.dark .disAble_popup {
  background-color: #0e121c;
  padding: 20px;
  border-radius: 15px;
}

.disAble_popup {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
}

button.btn.withdraw-btn {
  background: #41464d73;
}

.arrow_button {
  padding: 5px 9px 5px 9px;
  border-radius: 7px;
  background-color: #41464d00;
  border: none;
}

.arrow_button:hover {
  padding: 5px 9px 5px 9px;
  border-radius: 7px;
  background-color: #41464d73;
  transition: 0.5s;
}

.modal-content.modal_content_Size {
  width: 430px;
  border-radius: 20px;
}

.modal-content.modal_content_Size_tow {
  width: 500px;
  border-radius: 20px;
}

.content_Size {
  border-radius: 20px;
}

.content_Size_disable {
  border-radius: 20px;
  width: 400px;
}

.dark .white_accordian {
  color: white;
  text-decoration: none;
}

.white_accordian {
  color: black;
  text-decoration: none;
}

button.text-secondary.text_decoration.btn.btn-link {
  text-decoration: none;
}

.text-secondary.text_decoration.d-flex.justify-content-between.card-header {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.notifications .card-header {
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.dark .notifications .card {
  border-radius: 0px !important;
  padding: 20px;
  border-bottom: 1px solid #1c232f !important;
}

.notifications .card {
  border-radius: 0px !important;
  padding: 20px;
  border-bottom: 1px solid #f0f3fa !important;
}

.notifications .card-header {
  background: transparent;
}

.security-container {
  text-align: center;
}

.activity-table {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.activity-table.show {
  max-height: 1000px;
  /* Set max-height to a large enough value */
  transition: max-height 1s ease-in-out;
}

.dark .light_text {
  color: white;
}

.light_text {
  color: rgb(19, 19, 19);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
}

input.form-control.phone_number.form-control {
  font-size: 14px !important;
}

.Android {
  background-color: #1ec6f640 !important;
  height: 44px;
  border-radius: 10px;
  width: 45%;
  font-size: 11px;
}

span.copy_Auth {
  cursor: pointer;
  // color: #848e9c !important;
}

span.copy_Auth:hover {
  color: #fa501c !important;
}
.container {
  // max-width: 1300px !important;
}
.btn_size {
  height: 44px;
}

.normal-cursor-row:hover {
  cursor: default !important;
}

td {
  cursor: default !important;
}

td:hover {
  cursor: default !important;
}

.Withdraw_otp_conf {
  width: 68%;
  border: 1px solid #fa501c !important;
}
.dark .notification_title {
  color: white !important;
}
.notification_title {
  font-size: 22px;
  color: black !important;
}
.dark .notificationBody {
  color: white !important;
}
.notificationBody {
  color: black !important;
}

.mobile_screen .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #1dabd6;
}
.mobile_screen .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: gray;
}

.mobile_screen {
  display: none;
}

.mobile_chart {
  min-height: 480px;
  height: 480px;
}
.userLogout {
  margin-left: -300px !important;
}
a.nav-item.nav-link:focus-visible,
a.nav-item.nav-link:focus,
a.nav-item.nav-link:hover,
a.nav-item.nav-link {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.market-order .nav-link.active {
  color: #fa501c !important;
  border: none !important;
}
// button.pagination_button.btn.btn-primary {
//   color: #fa501c;
//   background: #fff4f1 !important;
// }
.filter_button {
  background-color: transparent !important;
  border: none !important;
  color: #131722 !important;
}
.dark .filter_button {
  background-color: transparent !important;
  border: none !important;
  color: #9ea4ab !important;
}

div#popover-contained\ popover_contained {
  min-width: 410px !important;
  background: #ffeee9;
}
.dark div#popover-contained\ popover_contained {
  min-width: 410px !important;
  background: #2d3340;
}
.profile-left-tab .nav-link.active {
  color: #fa501d !important;
  // background: rgba(250, 80, 28, 0.1411764706) !important;
  background-color: #fedcd2 !important;
}
.dark .profile-left-tab .nav-link.active {
  color: #fa501d !important;
  background: rgba(250, 80, 28, 0.1411764706) !important;
  // background-color: #fedcd2 !important;
}

//VYNKSAFE API SECTION STAT 

.hero_section{
  background: url('../../../../public/img/Vynksafe_Api/Banner BG light.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 0rem 0rem 0rem;
  align-items: center;
  background-position: center 70%;
}
.dark .hero_section{
  background: url('../../../../public/img/Vynksafe_Api/Banner BG.png');
}
.Spot_Trading{
  background-color: #ffe3db;
  border-radius: 10px 0px 0px 10px;
  height: auto;
}
.dark .Spot_Trading{
  background-color: #1a202d;
}

.Derivatives_Trading{
  background-color: #ffc9ba;
  border-radius: 0px 10px 10px 0px;
  height: auto;
}
.dark .Derivatives_Trading{
  background-color: #232a38;
 
}
.Spot_Trading_link{
  padding: 5px 10px 5px 5px;
    border-radius: 8px;
    border: 2px solid;
    border-color: #b6b5b5;
    height: 50px;
}
.dark .Spot_Trading_link{
    border-color: #4f5560;
}

.Spot_Trading_link:hover {
  border-color: #fa501d;
  color: #fa501d;
  transition: all 0.3s ease-in-out;
}
.Spot_Trading_link:hover .image_background{
 background-color: #fa501d;
 transition: all 0.3s ease-in-out;
}
.Spot_Trading_link:hover .image_background img{
  // background-color: #fa501d;
  filter:brightness(9);
  transition: all 0.3s ease-in-out;
}


.image_background {
  background-color: white;
  border-radius: 5px;
  // margin: 2px;
  // margin-bottom: 4px;
  margin-right: 5px;
  width: 40px;
  height: 35px;
  padding: 5px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.dark .image_background{
  background-color: #131722;
}

.Market_Data{
  background: #ffeee9;
}
.dark .Market_Data{
  background: #1b1f28;
}

.markit_data_card{
  background-color: #ffe3db;
border-radius: 8px;
}
.dark .markit_data_card{
  background-color: #131722;
  border-radius: 8px;
  padding: 20px;
}
.investment_Services{
  background: #ffeee9;
  border-radius: 8px;
}
.dark .investment_Services{
  background: #1b1f28;
}
.VIPInstitutional{
  background-color: #ffeee9;
}

.dark .VIPInstitutional{
  background-color: #1b1f28;
}
button.developerbutton {
  background: transparent !important;
  border: 1px solid;
  color: #fa501d;
}
button.developerbutton:hover {
  background: #fa501d !important;
  border: 1px solid;
  color: white !important;
}
.FAQs.card-header  {
  background: #fff4f1;
  border: 1px solid #fa501c30;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px !important;
}
.FAQs.card-body {
  background: #fff4f1;
  border: 1px solid #fa501c30;
  padding-top: 5px;
  }


// .mt-3.cardBorder {
//   border-bottom: 1px solid #fa501c30 !important;
//   border-radius: 8px !important;
// }
// .dark .mt-3.cardBorder {
//   border: 1px solid #282a33 !important;
//   border-radius: 8px;
// }
.cardBorder{
  border-bottom: 1px solid #fa501c30;
}
.dark .FAQs.card-header , .dark .FAQs.card-body {
  background: #1c1e27;
  border: 1px solid #282a33;
}
.VipInstitude{
  width: 85%;
  float:right 
}
.VIPInstitutionalcontent{
width: 80%;
}
.linkkprogram{
  width: 70%;
}
.linkkprogram_conent{
  display: inline-block;
}

.form-access span {
  font-size: 14px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-weight: 400;
}

.getqrcode {
  background-color: #ffeee9 !important;
  border: 1px solid #fedcd2 !important;
  color: black !important;
  position: absolute;
  top: 38%;
  font-size: 14px !important;
  z-index: 99;
  left: 14%;
  font-weight: 500 !important;
}
.dark .getqrcode{
  background-color: #474D57 !important;
  border: 1px solid #474D57  !important;
  color: #EAECEF !important;
}

.set_font{
  font-size: 2rem !important;
}
.custom_select__input-container.css-qbdosj-Input {
  height: 33px !important;
  padding: 0px !important;
}
.wallet_network_select input {
  border: none !important;
  background: transparent !important;
}