/* #### Generated By: http://www.cufonfonts.com #### */

    /* @font-face {
    font-family: 'IBM Plex Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Regular'), url('IBMPlexSans-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Italic'), url('IBMPlexSans-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Thin';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Thin'), url('IBMPlexSans-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Thin Italic'), url('IBMPlexSans-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans ExtraLight'), url('IBMPlexSans-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans ExtraLight Italic'), url('IBMPlexSans-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Light';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Light'), url('IBMPlexSans-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Light Italic'), url('IBMPlexSans-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Medium'), url('IBMPlexSans-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Medium Italic'), url('IBMPlexSans-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans SemiBold'), url('IBMPlexSans-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans SemiBold Italic'), url('IBMPlexSans-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Bold'), url('IBMPlexSans-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'IBM Plex Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Sans Bold Italic'), url('IBMPlexSans-BoldItalic.woff') format('woff');
    } */
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-ExtraLight.eot');
        src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
            url('../fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-ExtraLight.woff2') format('woff2'),
            url('../fonts/Poppins-ExtraLight.woff') format('woff'),
            url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-Medium.eot');
        src: local('Poppins Medium'), local('Poppins-Medium'),
            url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-Medium.woff2') format('woff2'),
            url('../fonts/Poppins-Medium.woff') format('woff'),
            url('../fonts/Poppins-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-Regular.eot');
        src: local('Poppins Regular'), local('Poppins-Regular'),
            url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-Regular.woff2') format('woff2'),
            url('../fonts/Poppins-Regular.woff') format('woff'),
            url('../fonts/Poppins-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('Poppins-SemiBold.eot');
        src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
            url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
            url('../fonts/Poppins-SemiBold.woff') format('woff'),
            url('../fonts/Poppins-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-Black.eot');
        src: local('Poppins Black'), local('Poppins-Black'),
            url('../fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-Black.woff2') format('woff2'),
            url('../fonts/Poppins-Black.woff') format('woff'),
            url('../fonts/Poppins-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-Light.eot');
        src: local('Poppins Light'), local('Poppins-Light'),
            url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-Light.woff2') format('woff2'),
            url('../fonts/Poppins-Light.woff') format('woff'),
            url('../fonts/Poppins-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-Bold.eot');
        src: local('Poppins Bold'), local('Poppins-Bold'),
            url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-Bold.woff2') format('woff2'),
            url('../fonts/Poppins-Bold.woff') format('woff'),
            url('../fonts/Poppins-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    
    
    @font-face {
        font-family: 'Poppins';
        src: url('../fonts/Poppins-ExtraBold.eot');
        src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
            url('../fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
            url('../fonts/Poppins-ExtraBold.woff') format('woff'),
            url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
    }
    
    