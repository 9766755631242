body {
  color: #fff;
}
.active_trades_right_btn .create_new_offer_btn {
  padding: 8px;
  border-radius: 3px;
  font-size: 14px;
  line-height: normal;
  background: #fa501c;
  min-width: 170px;
  color: #fff;
  display: block;
  border: 1px solid #fa501c00;
}

.dark .active_trades_right_btn .create_new_offer_btn:hover {
  background: #fff !important;
  color: #fa501c !important;
  border: 1px solid #fff !important;
}

.dark #MyoffersnTable thead tr th {
  border-top: none;
  font-weight: 400;
  color: #9ea4ab;
  border-bottom: none;
  font-size: 14px;
  white-space: nowrap;
}
.dark #MyoffersnTable tbody tr td {
  border-top: 1px solid #2c2e33 !important;
  padding: 15px 10px !important;
  vertical-align: middle !important;
  font-size: 14px;
}
.myoffer_status_btns_section {
  gap: 10px;
}
.dark .myoffer_status_btns_section li .myoffer_status_btns {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  padding: 2px;
  border-radius: 3px !important;
  transition: 0.3s ease-in-out;
  display: block;
}
.myoffer_status_btns_section li .myoffer_status_edit_btns {
  background: #fa501c;
  border: 1px solid #fa501c !important;
  padding: 2px 8px 2px 5px;
  border-radius: 3px !important;
  transition: 0.3s ease-in-out;
  color: #fff !important;
  min-width: 90px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.dark .active_trades_right_btn .find_an_offer_btn {
  padding: 8px;
  min-width: 170px;
  border-radius: 3px;
  font-size: 14px;
  background: #2c2e3369 !important;
  border: 1px solid #41464d80 !important;
  color: #9ea4ab !important;
  line-height: normal;
}
.tab.active_trades_left_btn {
  border: none;
  background-color: unset !important;
  gap: 10px;
  display: flex;
}
.dark .tab.active_trades_left_btn button.active,
.dark .tab.active_trades_left_btn button:hover {
  background: rgba(250, 80, 28, 0.1411764706) !important;
  color: #fa501c;
  border: 1px solid rgba(65, 70, 77, 0.5019607843) !important;
}
.dark .tab.active_trades_left_btn button {
  padding: 8px;
  min-width: 140px;
  background: rgba(44, 46, 51, 0.4117647059) !important;
  border: 1px solid rgba(65, 70, 77, 0.5019607843) !important;
  border-radius: 3px !important;
  color: #9ea4ab !important;
  transition: 0.3s ease-in-out;
  font-size: 14px;
}
.theme-btn,
.theme-btn-blank {
  background: #fa501c;
  padding: 12px 40px;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  color: #fff;
  border-radius: 3px;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.active_trades_right_btn .find_an_offer_btn:hover {
  background-color: #fa501c !important;
  color: #fff !important;
}
.p2p_main_banner_section .tabcontent.show {
  display: block;
}
.p2p_main_banner_section .tabcontent {
  border: none !important;
  display: none;
}
.show {
  display: block;
}
.dark .vynksafe_new_style h1,
.dark.vynksafe_new_style h2,
.dark.vynksafe_new_style h3,
.dark.vynksafe_new_style h4,
.dark.vynksafe_new_style h5,
.dark.vynksafe_new_style h6 {
  font-weight: 400;
  margin: 0px;
  color: #fff;
}
.vynksafe_new_style h1,
.vynksafe_new_style h2,
.vynksafe_new_style h3,
.vynksafe_new_style h4,
.vynksafe_new_style h5,
.vynksafe_new_style h6 {
  font-weight: 400;
  margin: 0px;
  color: #212529;
}
#PastTradesTable {
  color: #fff !important;
}
.dark #PastTradesTable thead tr th {
  border-top: none;
  font-weight: 400;
  color: #9ea4ab;
  border-bottom: none;
  font-size: 14px;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background: none;
}
.dark #PastTradesTable tbody tr td {
  border-top: 1px solid #2c2e33 !important;
  padding: 15px 10px !important;
  vertical-align: middle !important;
  font-size: 14px;
}
#PastTradesTable tbody tr td a {
  color: #fa501c !important;
}
.dark #PastTradesTable tbody tr td a.trade_view {
  color: #fff !important;
}
.dark .trade_view {
  width: max-content;
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 14px;
  background: #2c2e3369 !important;
  border: 1px solid #41464d80 !important;
  line-height: normal;
}
.payment_made {
  color: #ed419e;
}

.dark .dark-gray-bg-v {
  background-color: #131722;
}
// .dark-gray-bg-v {
//   background-color: #ffffff !important;
// }
body.dark.vynksafe_new_style .table-striped tbody tr:nth-of-type(2n + 1),
body.dark.vynksafe_new_style .table-striped tbody tr:nth-of-type(odd) {
  background: none !important;
}

.cancelled {
  color: #ed4141 !important;
}

.dark .p2pOfferViewModal table tbody tr td {
  border-top: 1px solid #2c2e3382;
  border-bottom: 1px solid #2c2e3382;
  border-right: 1px solid #2c2e3382;
  padding: 10px 20px;
}
.p2pOfferViewModal table tbody tr td p {
  white-space: nowrap;
}

.p2pOfferViewModal p {
  color: #9e9e9e;
  margin: 0px;
  line-height: 28px;
}
.dark .p2pOfferViewModal .modal-dialog .modal-header {
  background: #131722;
  border-bottom: none !important;
  padding: 1rem 1rem !important;
}
.dark .p2pOfferViewModal .modal-dialog .modal-footer {
  background: #131722;
  border: none !important;
  padding: 1rem 1rem 1.5rem 1rem !important;
  justify-content: center;
}

.dark .p2pOfferViewModal .modal-dialog .modal-body {
  background: #131722;
}
.p2pOfferViewModal table tbody tr td:last-child {
  border-right: 0px solid #2c2e3382;
}
.p2pOfferViewModal .active_trades_table_mobile_scroll {
  width: 100%;
  overflow: auto;
}
.fade.p2pOfferViewModal.modal {
  z-index: 99999999;
}
// .p2pOfferViewModal .modal-dialog .close {
//   color: #ffffff6e !important;
//   opacity: 1;
//   font-weight: 100;
//   float: right;
//   right: 1em;
//   position: absolute;
//   font-size: 40px;
//   text-shadow: 0 1px 0 #ffffff12;
//   padding: 0;
//   line-height: normal;
// }

.p2pOfferViewModal .modal-dialog .close {
  color: rgba(255, 255, 255, 0.431372549) !important;
  opacity: 1;
  font-weight: 100;
  float: right;
  right: 1rem;
  top: 1rem;
  position: relative;
  font-size: 40px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.0705882353);
  padding: 0;
  line-height: 0;
  overflow: hidden;
  height: 20px;
}
.p2pOfferViewModal {
  z-index: 99999;
}
.dark .p2pOfferViewModal .close span {
  color: #ffffff6e;
  background: none;
  border: none;
  text-shadow: none;
  font-weight: 100;
  font-size: 35px;
  padding: 0;
  opacity: 1;
  margin: 0px;
  line-height: 20px;
}
.p2pOfferViewModal .select__control {
  background: rgba(44, 46, 51, 0.4117647059);
  border: 1px solid #282c34 !important;
  border-radius: 3px;
  overflow: hidden;
  padding: 2px;
  box-shadow: none !important;
  font-size: 14px;
}
.p2pOfferViewModal .select__control .select__placeholder {
  color: #687079;
}

.p2pOfferViewModal .select__control .select__input-container {
  color: #fff;
  font-size: 14px;
}
.p2pOfferViewModal .select__menu {
  background: #2d3340;
  border: 1px solid #282c34 !important;
  border-radius: 3px;
  overflow: hidden;
  padding: 2px;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .active_trades_table_mobile_scroll {
    width: 100%;
    overflow: scroll;
  }
}

// create offer page css start

.p2p_buy_create_offer_main .card {
  height: auto;
  width: 100%;
  background-color: #fff0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
}
.p2p_buy_create_offer_main .card .form {
  width: 100%;
  height: 100%;
  display: flex;
}
.p2p_buy_create_offer_main .card .left-side {
  width: 100%;
  height: 100%;
}
.p2p_buy_create_offer_main .steps {
  width: 100%;
  counter-reset: css-counter 0;
  padding: 0px 10px;
  display: flex;
}
.p2p_buy_create_offer_main li:first-child::after {
  display: none;
}
.dark .p2p_buy_create_offer_main .steps li::after {
  content: "";
  position: absolute;
  width: 47px;
  height: 3px;
  top: 15px;
  border-bottom: 2px dotted #ffffff3d;
  left: -44px;
}
.p2p_buy_create_offer_main li.li-active::before {
  background-color: #fa501c !important;
  border-color: #fa501c !important;
  color: #fff !important;
}
.dark .p2p_buy_create_offer_main .steps li::before {
  content: counter(css-counter);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: block;
  text-align: center;
  margin: 0px 12px;
  line-height: 28px;
  background: #2c2e3369;
  background-color: rgba(44, 46, 51, 0.41);
  border: 1px solid #41464d80 !important;
  color: #9ea4ab;
}
.p2p_buy_create_offer_main .steps li:first-child {
  margin-left: 0px !important;
}
.dark .p2p_buy_create_offer_main .steps li {
  list-style: none;
  width: auto;
  float: left;
  position: relative;
  text-align: center;
  counter-increment: css-counter 1;
  margin-left: 60px;
  color: #fff;
}
.p2p_buy_create_offer_main .card {
  border-top: none !important;
}
.p2p_buy_create_offer_main .active {
  display: block !important;
}
.p2p_buy_create_offer_main .main {
  padding: 10px 30px;
  // display: none;
  margin-top: 5px;
  border-radius: 20px;
  overflow: hidden;
}
.dark .step_1_btn h6,
.dark .p2p_create_offer_assets h6,
.dark .set_amount_payment_method_section h6,
.dark .set_amount_payment_method_section h6 span.coin_code_ord_limit {
  color: #9ea4ab !important;
  clear: both;
  font-size: 14px;
}
.dark .switch6 {
  width: 250px;
  border: 1px solid #ffffff30 !important;
  border-radius: 3px;
}
.switch6-light {
  display: block;
  height: 36px;
  position: relative;
  overflow: visible;
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.switch6-light input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}
.switch6-light > span {
  position: absolute;
  left: -100px;
  width: 100%;
  margin: 0px;
  padding-right: 100px;
  text-align: left;
}
.switch6-light label,
.switch6-light > span {
  line-height: 30px;
  vertical-align: middle;
}
.switch6-light > span,
.switch-toggle > span {
  color: #000000;
}
.switch6-light * {
  box-sizing: border-box;
}
.switch6-light > span span {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 100px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 0px;
}
.switch-toggle a,
.switch6-light span span {
  // display: none;
}
.dark .switch6-light span span,
.dark .switch6-light label,
.dark .switch-toggle span span,
.dark .switch-toggle label {
  color: #9ea4ab;
}
.switch6-light > span span:last-child {
  left: 50%;
}
.dark .switch6-light a {
  position: absolute;
  right: 50%;
  top: 0px;
  z-index: 4;
  display: block;
  width: 50%;
  height: 100%;
  padding: 0px;
  border-radius: 3px;
  border: none;
  background-color: #04b98e3d !important;
  color: #04b98e !important;
  transition: all 0.3s ease-out 0s;
}
.dark .vynksafe_new_style hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top-width: 0px;
  border-top-style: none;
  border-top-width: 0px;
  border-top-style: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.p2p_create_offer_assets {
  clear: both;
}
.saving-transactions-form .transaction-type input,
.saving-transactions-form .saving-time-period input,
.saving-transactions-form .payment-type input {
  display: none;
}
.dark .p2p_create_offer_form .p2p_create_offer_type input:checked + label {
  // padding: 6px !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  background: #fa501c24 !important;
  color: #fa501c !important;
  border: 1px solid #3b2626 !important;
}
.saving-transactions-form .transaction-type input:checked + label,
.saving-transactions-form .saving-time-period input:checked + label,
.saving-transactions-form .payment-type input:checked + label {
  border: 1px solid #5468bc;
  background: none;
  padding: 4px 25px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
}
.dark .saving-transactions-form.p2p_create_offer_form label {
  height: auto;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px !important;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 400;
  float: left;
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #9ea4ab !important;
}
.saving-transactions-form label {
  background: none;
  height: 100%;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px;
  margin-right: 5px;
  border-radius: 50px;
  cursor: pointer;
  padding: 4px 25px;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 400;
  padding: 4px !important;
  min-width: 80px;
  border: 1px solid #fff0 !important;
}
.p2p_search_refresh.p2p_create_offer_location_opetion li {
  width: 100%;
}
ul.p2p_search_refresh li select.form-control {
  font-size: 13px;
}
.create_new_order_page .form-control {
  border-radius: 3px;
  box-shadow: none !important;
  font-weight: 400;
  height: 65px;
  padding: 5px 15px;
  border-color: #282c34;
  color: #fff !important;
  background-color: #2c2e3369;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.p2p_search_refresh .select2.select2-container.select2-container--default {
  width: 100% !important;
}
.p2p_search_refresh
  .select2.select2-container.select2-container--default
  .selection::after {
  content: "";
  color: #fff;
  right: 7px;
  top: 0px;
  height: 43px;
  padding: 6px;
  position: absolute;
  pointer-events: none;
  background-image: url(../../../../public/img/images/arrow_down.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.p2p_search_refresh.p2p_create_offer_location_opetion
  .select2.select2-container.select2-container--default
  span.selection {
  width: 100%;
}
.p2p_search_refresh .select2-container--default .select2-selection--single {
  min-width: 180px;
  color: #ced4da !important;
  padding: 4px 6px;
  height: auto;
  display: flex;
  align-items: center;
  background: #2c2e3369;
  border: 1px solid #282c34 !important;
  border-radius: 3px !important;
  min-height: 43px;
}
.p2p_search_refresh
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
  line-height: 28px;
  font-size: 14px;
}
.text-danger {
  color: #dc3545 !important;
}
.p2p_buy_create_offer_main .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.p2p_buy_btn_bg {
  background: #04b98e !important;
  background-color: rgb(4, 185, 142);
  background-size: auto;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  width: 110px;
  border-radius: 3px;
  line-height: normal;
  color: #fff !important;
}
.p2p_create_offer_step_btn .p2p_buy_btn_bg {
  background-color: #fa501c;
}

.dark .offer_status_summary_section {
  border-radius: 3px;
  overflow: hidden;
  background-color: #2d3340;
}

.p2p_create_offer_input {
  width: auto;
  position: relative;
  align-items: center;
  display: flex;
}
.create_new_order_page input::placeholder,
textarea::placeholder {
  color: #ced4da !important;
  transition: color 0.4s ease;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  -ms-transition: color 0.4s ease;
  -o-transition: color 0.4s ease;
}
.dark .p2p_create_offer_input input {
  width: 100%;
  min-width: 180px;
  color: #ced4da !important;
  padding: 8px 15px;
  height: auto;
  display: flex;
  align-items: center;
  background: #2c2e3369 !important;
  border: 1px solid #282c34 !important;
  border-radius: 3px !important;
  min-height: 43px;
}
.p2p_create_offer_type.transaction-type.payment_methods_you_accept label {
  width: fit-content;
  padding: 8px !important;
  margin: 0;
}
.p2p_create_offer_type.transaction-type.payment_methods_you_accept {
  gap: 10px;
  display: flex;
}
.dark .vynksafe_new_style .btn.btn-gray-v {
  background: #2c2e3369;
  border: 1px solid #41464d80 !important;
  padding: 8px;
  border-radius: 3px !important;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.p2p_create_offer_form .add_payment_methods_btn_v {
  width: 35px;
  height: 35px;
  line-height: 18px;
}
body.vynksafe_new_style.dark .btn.btn-gray-v:hover {
  background: #fa501c !important;
  color: #fff !important;
}
.saving-transactions-form .p2p_create_offer_percentage_type label {
  padding: 4px !important;
  width: fit-content !important;
}
.switch6-light input:checked ~ a {
  right: 0% !important;
  border-radius: 3px !important;
  background-color: #fa501c24 !important;
  border: none !important;
  color: #fa501c !important;
}
.fs-6v {
  font-size: 10px !important;
}
.fs-4v {
  font-size: 14px !important;
}
.p2p_create_offer_input span {
  right: 0;
  padding-left: 0;
  padding-right: 15px;
  position: absolute;
  font-size: 20px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus-visible,
.create_new_order_page input:focus-visible {
  outline-offset: 0px;
  outline: none !important;
  box-shadow: none !important;
}
.dark .p2p_create_offer_select_location::after {
  content: "";
  color: #fff;
  right: 11px;
  top: 5px;
  height: 34px;
  padding: 8px;
  position: absolute;
  pointer-events: none;
  background-image: url(../../../../public/img/images/arrow_down.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  filter: unset;
}

.p2p_create_offer_select_location {
  position: relative;
  float: left;
  min-width: 25%;
}
.p2p_create_offer_select_location label {
  width: 100%;
  float: left;
  position: relative;
}
.dark .p2p_create_offer_select_location select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  max-width: 100%;
  float: left;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 1.75;
  color: #fff !important;
  cursor: pointer;
  -ms-word-break: normal;
  word-break: normal;
  background: #2c2e3369 !important;
  border: 1px solid #282c34 !important;
  border-radius: 3px !important;
}
.dark
  .p2p_offer_create_remarks_conditions_form
  .p2p_create_offer_input
  textarea {
  padding: 12px 15px;
  border-radius: 3px;
  color: #fff !important;
  width: 100%;
  min-height: 150px;
  background: #2c2e3369 !important;
  border: 1px solid #282c34 !important;
  border-radius: 3px !important;
}
.p2p_offer_create_remarks_conditions_form
  .p2p_create_offer_input
  textarea:focus-visible {
  box-shadow: none;
  outline: none;
  border: none;
}
.dark .add_payment_methods_btn_v i {
  color: #595959 !important;
}
// create offer page css end

// Add Payment Method Page css start

.text-green {
  color: #04b98e !important;
}
.text-red {
  color: #fe4845 !important;
}
.dark p,
.dark span {
  font-size: 14px;
  font-weight: normal;
  line-height: 28px;
  color: #e4e4e4;
  margin: 0px;
  // font-family: "Poppins Light";
  transition: color 0.4s ease;
}

.p2p_user_merchant_cards {
  gap: 10px;
}
.dark .p2p_user_merchant_cards li {
  width: 13.6%;
  min-width: 150px;
  padding: 8px 12px;
  border-radius: 3px !important;
  background: #2c2e3369 !important;
  border: 1px solid #41464d61 !important;
  color: #9ea4ab;
}
.p2p_main_banner_section .p2p_user_merchant p {
  font-size: 14px;
}
.dark .vynksafe_new_style p {
  color: #9ea4ab;
  letter-spacing: 0.2px;
  font-size: 0.9rem;
}
.p2p_user_accordion {
  width: 100%;
}
.online_ads_heading {
  // font-family: "Poppins Light";
}
.dark .user_payment_method_box {
  background: #131722 !important;
  border-radius: 3px;
  padding: 20px;
  border: 1px solid #131722 !important;
}

.user_payment_method_box_img_circle {
  border-radius: 100%;
  background: #ffffff1a;
  padding: 4px;
  width: 50px;
  height: 50px;
  max-width: 50px;
  min-width: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_payment_method_box_img_circle img {
  object-fit: scale-down;
  width: 100%;
}
.user_payment_method_box_upi .user_payment_method_box_upi_edit {
  gap: 10px;
}
.dark .gray_colour {
  color: #9ea4ab !important;
  font-size: 14px;
}
.user_payment_method_box h5 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.p2p_user_table_heading h5 {
  // font-family: "Poppins Light";
}
.profile_feedbacks_right {
  gap: 10px;
  border: none;
}
.profile_feedbacks_right .nav-link.active {
  background-color: #fa501c24 !important;
  color: #fa501c !important;
  border: 1px solid #fa501c24 !important;
}
.dark .profile_feedbacks_right .nav-link {
  padding: 8px;
  min-width: 170px;
  border-radius: 3px;
  font-size: 14px;
  background-color: #2c2e3369 !important;
  border: 1px solid #41464d80 !important;
  color: #9ea4ab !important;
  line-height: normal;
  text-align: center;
}
.dark .profile_feedbacks_right .nav-link:hover {
  border: 1px solid #41464d80 !important;
}
.profile_feedbacks_right .nav-link.active {
  background-color: #fa501c24 !important;
  color: #fa501c !important;
}
.card_p2p_online_ads .p2p_user_table_heading .nav-tabs {
  border-bottom: 1px solid #ffffff1a;
  width: fit-content;
}
.card_p2p_online_ads .p2p_user_table_heading .nav-tabs .nav-item.show .nav-link,
.card_p2p_online_ads .p2p_user_table_heading .nav-tabs .nav-link.active {
  color: #fa501c;
  background: none;
  border: none !important;
  border-bottom: 1px solid #fa501c !important;
}

.card_p2p_online_ads .p2p_user_table_heading .nav-tabs .nav-link {
  color: #9ea4ab;
  border: none !important;
  cursor: pointer;
}
.dark .p2p_user_payment_feedback {
  width: fit-content;
  padding: 25px 15px;
  border-radius: 3px !important;
  background: #2c2e3369 !important;
  border: 1px solid #41464d61 !important;
  color: #9ea4ab;
}
.dark .p2p_user_payment_feedback .reviews {
  border-right: 1px solid #ffffff5e !important;
  padding-right: 20px;
  margin-right: 20px;
}
.progress_bar {
  width: 300px;
  border-radius: 10px;
}
.dark .progress_bar .progress {
  border-radius: 15px;
  background: #131722;
}
.progress-bar.progress-bar-green {
  background: #1f9d78;
  border-radius: 10px;
}
.progress-bar.progress-bar-red {
  background: #f54b62;
  border-radius: 10px;
}
.dark .p2p_user_accordion .border {
  border: 1px solid #41464d3b !important;
  margin: 0px 60px !important;
}
.progress.progressbar_height {
  height: 10px;
}
// .progress.progressbar_height .progress-bar.progress-bar-green {
//   width: 100%;
// }
// .progress.progressbar_height .progress-bar.progress-bar-red {
//   width: 10%;
// }
.white_space_nowrap {
  white-space: nowrap;
}
.p2p_user_accordion .user_payment_method_box_bg_none .tab-content .border {
  margin: 24px 0px !important;
}

.dark .btn.disable_btn_bg {
  padding: 9px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  background-size: 200% auto;
  width: 150px;
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #fff !important;
}

@media only screen and (min-width: 240px) and (max-width: 575px) {
  .p2p_user_merchant_cards li {
    width: 48%;
  }
}
@media (max-width: 400px) {
  .p2p_user_merchant_cards li {
    width: 100%;
    min-width: 100%;
  }
  .p2p_user_payment_feedback {
    width: 100%;
  }
  .p2p_user_payment_feedback .progress_bar {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .p2p_main_banner_section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .p2p_user_payment_feedback .reviews {
    border-right: 0px solid #ffffff5e;
    padding-right: 0px;
    margin-right: 0px;
  }
}

// Add Payment Method Page css end

// Create offer Page css start

.p2p_my_offers_page_main {
  overflow: hidden;
}
.p2p_img_org_circle {
  background-color: #572b1e;
  border-radius: 100%;
  padding: 15px;
  z-index: 1;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.3s ease-in-out;
}
.countdown {
  font-size: 16px;
  padding: 0px 7px;
  background: #bd0303;
  border-radius: 6px;
  width: 54px;
  text-align: center;
}
.p2p_offer_cancelled #order_status_subhead {
  font-size: 14px;
}
.p2p_offer_cancelled_order_details,
.p2p_offer_cancelled_order_details span {
  font-size: 14px;
}
.p2p_offer_cancelled_buying_section,
.p2p_offer_cancelled_buying_fees_section,
.p2p_my_trade_tab_payment_main {
  width: 100%;
  display: flex;
}
.p2p_offer_cancelled_buying_section ul {
  width: 100%;
  float: left;
  gap: 5px 15px;
  flex-wrap: wrap;
}
.dark .p2p_my_trade_tab_payment_main {
  background: #2d3340;
}
.p2p_my_trade_tab_payment.tab {
  float: left;
  background-color: #131722;
  width: 30%;
  overflow: hidden;
  border-right: 0px;
  height: auto;
}
.cancelled_trades_right_btn {
  gap: 10px;
}
.cancelled_trades_right_btn button {
  display: block;
  width: 200px !important;
  margin-bottom: 10px;
  font-size: 14px;
}
.p2p_dlt_confirm_btn,
.p2p_cancel_btn_bg {
  padding: 10px !important;
  min-width: 150px;
}
.dark .p2p_limited_btn_bg {
  background: none;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  width: 150px;
  border: 1px solid #ffffff54;
  border-radius: 3px;
  color: #fff !important;
}
ul.p2p_cancelled_seller_feedback_right {
  gap: 20px;
}
.p2p_list_style.p2p_list_style_banktransfer {
  gap: 3px 30px;
  display: flex;
  flex-wrap: wrap;
}

.p2p_cancelled_seller_feedback_right li {
  font-size: 14px;
}
.dark .p2p_list_style.p2p_list_style_banktransfer label {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  position: relative;
  padding-left: 22px;
}
.p2p_list_style.p2p_list_style_banktransfer label::before {
  content: "";
  background-image: url(../../../../public/img/images/circle_bullet.svg);
  background-size: contain;
  background-position: center;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 4px;
}

.dark .order_status_chat_section {
  border-radius: 3px;
  overflow: hidden;
  background-color: #2d3340;
}
.dark .p2p_cancelled_user_pro {
  background-color: #1b1f28;
  border-radius: 3px;
  color: #fff;
}
.p2p_trade_cancelled_seller_feedback {
  margin-left: 38px;
  line-height: 35px;
}
.dark #chat2.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #2d3340;
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 0.25rem;
}
#chat2 .card-body #messages {
  position: relative;
  height: 300px;
  overflow-y: auto;
  padding-right: 20px;
  margin-bottom: 10px;
}
.order_status_chat_section #messages {
  min-height: 216px;
}
.order_status_chat_section .card-footer {
  padding: 0px;
  border: none;
  background: none;
}
.chat_not {
  font-size: 14px;
  margin-top: 15px;
}

.dark .p2p_my_trade_tab_payment.tab {
  float: left;
  background-color: #131722;
  width: 30%;
  overflow: hidden;
  border-right: 0px;
  height: auto;
}
.dark
  .p2p_my_trade_tab_payment_main
  .p2p_my_trade_tab_payment
  .nav-item
  .nav-link.active {
  background-color: #2d3340 !important;
  color: #fff !important;
}
.dark
  .p2p_my_trade_tab_payment_main
  .p2p_my_trade_tab_payment
  .nav-item
  .nav-link.active
  img {
  filter: unset;
}

.dark .p2p_cancel_btn_bg {
  padding: 9px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  min-width: 150px;
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  margin-top: 5px;
  border-radius: 3px;
  color: #fff !important;
}

.dark .p2p_cancel_btn_bg:hover {
  background: #fff !important;
  color: #fa501c !important;
  border-color: #fff !important;
}

.p2p_my_trade_tab_payment.tab a {
  display: block;
  background-color: inherit;
  color: #fff;
  padding: 12px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
}
.theme-btn:hover,
.theme-btn:focus {
  color: #fa501c !important;
  background-color: #fff;
}

.user_payment_id p i {
  font-size: 20px;
  margin-left: 5px;
}

// Create offer Page css end

// User Profile Page css start

.dark .p2p_user_profile_feedback {
  width: 100%;
  padding: 15px;
  border-radius: 3px !important;
  background: #2c2e3369 !important;
  color: #9ea4ab;
  border: 1px solid #2c3037 !important;
}
.dark .user_profile_trade_card {
  padding: 8px 12px;
  border-radius: 3px !important;
  background: #2c2e3369 !important;
  border: 1px solid #41464d61 !important;
  color: #9ea4ab;
  width: 19.2%;
  min-width: 200px;
}

.user_follow_section_left {
  gap: 10px;
  color: #fa501c;
}
.user_follow_btn {
  background: #fa501c;
  color: #fff;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
}

.user_follow_btn:hover {
  background: #fff;
  color: #fa501c;
}

.user_follow_section_right {
  gap: 20px;
  color: #9ea4ab;
}
.user_follow_section_right li {
  align-items: center;
  display: flex;
}

.dark .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

@media (max-width: 767px) {
  .user_profile_trade_card {
    width: 48%;
    min-width: 48%;
  }
}
@media (max-width: 500px) {
  .user_profile_trade_card {
    width: 100% !important;
    min-width: 100%;
  }
}
// User Profile Page css end

// Order Completed Page css start

.order_completed_right_btn {
  gap: 10px;
}
.order_completed_right_btn button {
  display: block;
  // width: 200px;
  margin-bottom: 10px;
  font-size: 14px;
}
.dark .order_completed_payment_method label {
  padding: 5px 12px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  background: rgba(250, 80, 28, 0.1411764706);
  color: #fa501c;
  border: 1px solid #3b2626 !important;
}
.text-orange {
  color: #fa501c !important;
}
.reference_message_section {
  gap: 0px 10px;
}

.my_feedback_edit_section {
  gap: 15px;
}

// Order Completed Page css end

.dark .offer_status_summary_section .border {
  border: 1px solid #13172261 !important;
}
.dark .text-white {
  color: #fff !important;
}
.dark .set_amount_payment_method_section i {
  color: #fff;
}

.dark .p2p_create_offer_input input::placeholder {
  color: #9ea4ab !important;
}
.dark .my_feedback_edit_section li a img {
  filter: unset;
}
.dark .p2p_offer_cancelled_order_details li,
.dark .p2p_offer_cancelled_order_details span {
  color: #fff;
}
// .dark button.p2p_dlt_confirm_btn.theme-btn-2.theme-btn.btn.btn-primary:hover, .dark button.btn.disable_btn_bg.harvest_btn_pools.theme-btn-2.theme-btn.btn.btn-secondary:hover {
//   border: 1px solid #fff !important;
// }

.dark button.p2p_dlt_confirm_btn.theme-btn-2.theme-btn.btn.btn-primary:active {
  color: #fff !important;
}
.dark button.p2p_dlt_confirm_btn.theme-btn-2.theme-btn.btn.btn-primary:hover {
  color: #fa501c !important;
  border-color: #fff !important;
}
.dark button.p2p_dlt_confirm_btn.theme-btn-2.theme-btn.btn.btn-primary:focus {
  background-color: #fa501c !important;
  color: #fff !important;
  border: 1px solid #fa501c !important;
}
.dark
  button.p2p_dlt_confirm_btn.theme-btn-2.theme-btn.btn.btn-primary:focus-visible {
  box-shadow: none;
  border: none;
}
.dark .myoffer_status_btns_section li .myoffer_status_btns img {
  filter: unset;
}
.dark .light-theme-img {
  display: none !important;
  margin: 0 auto;
}
.dark .dark-theme-img {
  display: block !important;
  margin: 0 auto;
}
button#step_1_btn {
  background-color: #fa501c !important;
}
button#step_1_btn:hover,
.theme-btn:focus {
  color: #fa501c !important;
  background-color: #fff !important       {
    width: 100px;
    border: 1px solid;
    padding: 5px;
    text-align: center;
    background: green;
    border-radius: 3px;
  }
}
// .showBuy {
//   width: 100px;
//   border: 1px solid rgba(255, 255, 255, 0.1882352941) !important;
//   padding: 5px;
//   text-align: center;
//   background: #164440;
//   color: #9ea4ab;
//   border-radius: 3px;
// }
// .showSell {
//   width: 100px;
//   border: 1px solid rgba(255, 255, 255, 0.1882352941) !important;
//   padding: 5px;
//   text-align: center;
//   background: #3a2626;
//   color: #9ea4ab;
//   border-radius: 3px;
// }

.showBuy {
  color: #04b98e;
}
.showSell {
  color: #fa501c;
}
.user_chat_chat p.text-white {
  color: #fff !important;
  background: #ffffff0f;
  border-radius: 8px 8px 0px 8px;
  text-align: left;
  line-height: normal;
  width: fit-content;
  float: right;
  max-width: 344px;
  margin-left: 8px;
  white-space: pre-wrap;
  word-break: break-word;
}
.msg_img {
  max-width: 170px;
  max-height: 170px;
  object-fit: cover;
  padding: 5px;
}

// #tradeFeedbackModal .radio_group {
//   margin-left: 10px;
//   position: relative;
//   text-align: left;
//   font-size: 20px;
// }
.p2pOfferViewModal .radio_group {
  margin-left: 10px;
  position: relative;
  text-align: left;
  font-size: 20px;
}
.p2pOfferViewModal .radio_group input[type="radio"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: pointer;
}
.p2pOfferViewModal .radio_group input[type="radio"] + label {
  color: #95a5a6;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -1;
  transform: scale(0.8);
}
.p2pOfferViewModal .radio_group input[type="radio"] + label {
  color: #95a5a6;
}
.p2pOfferViewModal
  .radio_group.radio_group_negative
  input[type="radio"]:checked
  + label {
  color: #f00;
  transform: scale(0.8);
}
.p2pOfferViewModal
  .radio_group.radio_group_positive
  input[type="radio"]:checked
  + label {
  color: #0cb518;
  transform: scale(0.8);
}
.dark .tableText {
  color: white !important;
}
