// body {
//   //font-family: 'Overpass', sans-serif;
//   //font-family: 'Roboto Condensed', sans-serif;
//   //font-family: 'Roboto', sans-serif;
//   font-family: 'IBM Plex Sans Regular';
//   font-size: 14px;
//   line-height: 1.5;
//   color: #18214D;
//   background: #FFFFFF;
//   font-weight: 400;
//   overflow-x: hidden;
// }

body {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  position: relative;
  overflow-x: hidden;
  background: #1b1f28;
  font-size: 14px;
  line-height: 1.5;
  color: #18214D;
  background: #FFFFFF;
  font-weight: 400;
  overflow-x: hidden;
}
