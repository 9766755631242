.circle-loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 999;
  clip: rect(0px, 40px, 40px, 20px);
  -webkit-animation: loader-animation 1s linear infinite;
  -moz-animation: loader-animation 1s linear infinite;
  -ms-animation: loader-animation 1s linear infinite;
  -o-animation: loader-animation 1s linear infinite;
  animation: loader-animation 1s linear infinite;
}

.circle-loader:after {
  content: "";
  -webkit-box-shadow: inset 0 0 0 4px #13a4ff;
  -moz-box-shadow: inset 0 0 0 4px #13a4ff;
  box-shadow: inset 0 0 0 4px #13a4ff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  clip: rect(0px, 40px, 40px, 20px);
  -webkit-animation: loader-animation2 1s ease-in-out infinite;
  -moz-animation: loader-animation2 1s ease-in-out infinite;
  -ms-animation: loader-animation2 1s ease-in-out infinite;
  -o-animation: loader-animation2 1s ease-in-out infinite;
  animation: loader-animation2 1s ease-in-out infinite;
}

@keyframes loader-animation {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loader-animation2 {
  0% {
    transform: rotate(-180deg);
    -webkit-box-shadow: inset 0 0 0 8px #13a4ff;
    -moz-box-shadow: inset 0 0 0 8px #13a4ff;
    box-shadow: inset 0 0 0 8px #13a4ff;
  }

  100% {
    transform: rotate(180deg);
    -webkit-box-shadow: inset 0 0 0 2px #0089df;
    -moz-box-shadow: inset 0 0 0 2px #0089df;
    box-shadow: inset 0 0 0 2px #0089df;
  }
}