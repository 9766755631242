body {
  font-family: "Poppins" !important;
  font-weight: normal;
  font-style: normal;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased!important;
}
html {
  scroll-behavior: smooth;
}
body {
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  line-height: 1.5;
}

h3 {
  color: #EAECEF;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 40px;
}


.dark.vynksafe_new_style h1,
.dark.vynksafe_new_style h2,
.dark.vynksafe_new_style h3,
.dark.vynksafe_new_style h4,
.dark.vynksafe_new_style h5,
.dark.vynksafe_new_style h6 {
  font-weight: 400;
  margin: 0px;
  color: #fff;
}
@media only screen and (max-width: 1199px) {
  .vynksafe_new_style .navbar-toggler {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    height: 58px;
    width: 58px;
    z-index: 9;
    box-shadow: none !important;
    outline: none !important;
    padding: 0 0.75rem;
    transition: all 0.6s;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -o-transition: all 0.6s;
  }
  .vynksafe_new_style .navbar-toggler .icon-bar {
    height: 2px;
    width: 35px;
    margin-bottom: 6px;
    display: block;
    background-color: #fff;
    transition: all 400ms linear;
    cursor: pointer;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    -o-transition: all 400ms linear;
  }
  .vynksafe_new_style .navbar-toggler span:last-child {
    margin-bottom: 0;
  }
  .vynksafe_new_style .navbar-toggler span:nth-child(2) {
    width: 22px;
    margin-left: 0;
  }
  .vynksafe_new_style .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1;
  }
  .vynksafe_new_style .navbar-toggler.active span:first-child {
    transform: rotate(-45deg);
    top: 6px;
    position: relative;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
  .vynksafe_new_style .navbar-toggler.active span:nth-child(2) {
    opacity: 0;
  }
  .vynksafe_new_style .navbar-toggler.active span:last-child {
    transform: rotate(45deg);
    bottom: 10px;
    position: relative;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
  .vynksafe_new_style header.site_header .navbar-collapse {
    position: fixed;
    top: 0;
    left: -290px;
    width: 235px;
    height: 100vh !important;
    background: #fff;
    display: flex !important;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    visibility: visible !important;
    /*    overflow-y: auto !important;*/
    padding: 25px 25px 30px;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
  }
  @media only screen and (max-width: 1199px) {
    body.dark.vynksafe_new_style .navbar-toggler .icon-bar {
      background-color: #fa501c;
    }
    body.dark.vynksafe_new_style header.site_header .navbar-collapse {
      background: #1b1f28;
    }
  }
  .vynksafe_new_style header.site_header .navbar-collapse.active {
    left: 0;
  }

  span.p-0.linkStyle {
    color: #595959 !important;
    font-size: 14px !important;
  }
  .linkStyle:hover {
    color: #fa501c !important;
}
span.p-0.linkStyle:hover {
  color: red !important;
}
  a {
    // color: #fa501c !important;
    text-decoration: none;
    background-color: transparent;
  }

  .vynksafe_new_style header.site_header .navbar-collapse.collapse.show {
    left: 0 !important;
  }
  .vynksafe_new_style header.site_header .navbar-nav .nav-item .nav-link {
    padding: 0;
    color: #fff !important;
  }
  .vynksafe_new_style header .navbar-collapse .navbar-nav {
    gap: 24px !important;
  }
  .vynksafe_new_style
    header.site_header
    .navbar-nav
    .nav-item
    .nav-link::after {
    bottom: 0;
  }
  /*header.site_header .navbar-nav .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
}*/
}

@media only screen and (max-width: 520px) {
  .vynksafe_new_style header.site_header .navbar-brand img {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .vynksafe_new_style header.site_header .container {
    max-width: 1300px;
  }
}
.vynksafe_new_style header.site_header {
  background-color: #ffffff;
  border-bottom: 1px solid #feded4;
  //   padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  display: grid;
  z-index: 999;
  /*  box-shadow: 0px 3px 33px 0 rgb(19 24 44 / 69%);*/
  min-height: 66px;
}
.vynksafe_new_style header.site_headerrr {
  background-color: #ffeee900;
  border-bottom: 1px solid #feded4;
  //   padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: grid;

  /*  box-shadow: 0px 3px 33px 0 rgb(19 24 44 / 69%);*/
  min-height: 66px;
}
header.site_headerrr {
  align-items: center !important;
}
header.site_header{
  align-items: center !important;
}
// .vynksafe_new_style header.site_header {
//   background: #feded4 !important;
// }
.dark.vynksafe_new_style header.site_headerrr {
  background-color: #ffeee900;
  border-bottom: 1px solid #41464d00;
}
.dark.vynksafe_new_style header.site_header {
  background: #131722 !important;
  border-bottom: 1px solid #41464d00;
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: #fa501c !important;
}
.header_equal_v .dropdown-menu {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  max-width: 350px;
  padding: 20px 15px;
  border-radius: 3px;
  min-width: 350px;
  border: none !important;
  position: absolute;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.dark .header_equal_v .dropdown-menu {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  background-color: #2d3340;
}
.header_equal_v .dropdown-menu li img {
  background-color: #131722;
  border-radius: 3px !important;
  width: 30px;
  height: 30px;
}

.header_equal_v .dropdown-menu li:first-child {
  margin-top: 0px;
}
.header_equal_v .dropdown-menu li:last-child {
  margin-bottom: 0px;
}

.header_equal_v .language_selectpicker .dropdown-menu li {
  margin: 0px !important;
}

.header_equal_v .dropdown-menu {
  margin: 20px 0px;
}
.header_equal_v .dropdown-menu .header_nav_dropdown_v h5 {
  font-size: 14px;
  color: #131722;
}
.dark .header_equal_v .dropdown-menu .header_nav_dropdown_v h5 {
  font-size: 14px;
  color: #fff !important;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none !important;
  background-color: #007bff00 !important;
}
.header_equal_v .dropdown-menu .header_nav_dropdown_v p {
  // font-weight: 300;
  // font-size: 14px !important;
  // color: #595959;
  font-size: 12px;
    font-weight: 400 !important;
    line-height: 16px inherit;
}
a {
  text-decoration: none !important;
}
.dark .header_equal_v .dropdown-menu .header_nav_dropdown_v p {
  color: #9ea4ab !important;
}
.vynksafenew_header_dropdown .header_nav_dropdown_v p {
  white-space: normal;
}
.header_equal_v .dropdown-menu li:hover img {
  background-color: #fff;
}
.vynksafe_new_style .header_equal_v .dropdown-menu img {
  background: #fff4f1;
  border-radius: 3px;
  border: 1px solid #fa501c30;
}
.header_equal_v .dropdown-menu li img {
  background: #fff4f1 !important;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  border: 1px solid #fa501c30 !important;
}
.dark.vynksafe_new_style .header_equal_v .dropdown-menu img {
  background-color: #131722;
  border-radius: 3px;
  border: 1px solid #131722;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;
}
.dark.vynksafe_new_style.dropdown-item:focus,
.dark .dropdown-item:hover {
  background-color: transparent !important;
  color: #ffffff;
}
.vynksafe_new_style header.site_header .menu_button img {
  width: 25px;
}
.vynksafe_new_style header .navbar .menu_button .navbar-nav {
  gap: 12px !important;
}
.me-auto.align-items-xl-center.mt-5.mt-xl-0.navbar-nav {
  gap: 12px;
}

button.btn.btn-gray-v {
  height: 33px;
}
.vynksafe_new_style .btn.btn-gray-v.dark_light_theme_btn_v {
  width: 33px !important;
  height: 33px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark.vynksafe_new_style .btn.btn-gray-v {
  background: #2c2e33;
  border: 1px solid #41464d !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
}
// body.dark.vynksafe_new_style header .language_selectpicker .selectpicker, body.dark.vynksafe_new_style header .language_selectpicker .btn-group.bootstrap-select .show, body.dark.vynksafe_new_style header .btn.btn-gray-v{
//     background: #fff;
//     border: 1px solid #FA501C30 !important;
//   }
.vynksafe_new_style header .language_selectpicker .selectpicker,
.vynksafe_new_style
  header
  .language_selectpicker
  .btn-group.bootstrap-select
  .show,
.vynksafe_new_style header .btn.btn-gray-v {
  background: #fff;
  border: 1px solid #fa501c30 !important;
}

.vynksafe_new_style .btn.btn-primary-v {
  background: #fa501c !important;
  border: 0px solid !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: 3px !important;
  min-height: 43px;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  min-width: 100px;
  max-width: fit-content;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
}
.Logn_butotn {
  background: #fa501c !important;
  border: 0px solid !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 3px !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  min-width: 70px;
  font-weight: 500;
  max-width: fit-content;
  border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
}


.dark.vynksafe_new_style .btn.btn-primary-v {
  background: #fff;
  color: #fa501c;
}
.vynksafe_new_style .btn.btn-primary-v:hover {
  background: #fff !important;
  color: #fa501c !important;
  border: 1px solid #fa501c30 !important;
}
.dark.vynksafe_new_style .btn.btn-primary-v:hover {
  background: #fff !important;
  color: #fa501c !important;
  border: 1px solid #fa501c00 !important;
}
.me-auto.mr-auto.vynksafenew_header_main_section_first.navbar-nav {
  gap: 0px 10px;
}
.vynksafe_new_style header.site_header .navbar-nav .nav-item .nav-link {
  font-size: 15px;
  color: #595959 !important;
  /* font-weight: 600; */
  //   position: relative;
  text-transform: capitalize;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.dark.vynksafe_new_style header.site_header .navbar-nav .nav-item .nav-link {
  font-size: 14px !important;
  color: #eaecef !important;
  /* font-weight: 600; */
  //   position: relative;
  text-transform: capitalize;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.vynksafe_new_style .btn.btn-gray-v:hover img {
  filter: brightness(9);
}
.vynksafe_new_style .btn.btn-gray-v img {
  transition: 0.3s ease-in-out;
}
.vynksafe_new_style a.nav-link {
  // color: #595959 !important;
  color: #1e2329 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
}
// .dark a.nav-link {
//   color: #9ea4ab !important;
// }
.dark a.nav-link {
  color: #eaecef !important;
}
.dark.vynksafe_new_style .btn.btn-gray-v.dark_light_theme_btn_v:hover {
  background: #20242c;
}
.vynksafe_new_style .btn.btn-gray-v:hover {
  background: #fa501c;
  color: #fff !important;
}

body.dark.vynksafe_new_style .header_equal_v .dropdown-menu :hover img {
  background-color: #fff;
}
.dark.vynksafe_new_style.dropdown-item:focus,
.dark.dropdown-item:hover img {
  background-color: #fff;
}

// *********************************FOOTER PAGE START******************************
.footer_v {
  background: #ffeee9;
}
.dark .footer_v {
  background: #1b1f28 !important;
}
.dark.vynksafe_new_style footer ul li a {
  color: #9ea4ab !important;
}
.vynksafe_new_style footer ul li a {
  color: #595959 !important;
}
.vynksafe_new_style .footer_v ul {
  gap: 7px;
  display: grid;
  font-size: 14px;
}
.vynksafe_new_style .footer_v ul li a:hover {
  color: #fa501c !important;
}
.dark.vynksafe_new_style .footer_v ul li a:hover {
  color: white !important;
}
.dark.vynksafe_new_style hr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.vynksafe_new_style hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top-width: 0px;
  border-top-style: none;
  border-top-color: currentcolor;
  border-top: 1px solid rgb(89, 89, 89);
}
.vynksafe_new_style footer.footer_v h5 {
  font-size: 1rem;
}
.vynksafe_new_style p {
  color: #595959;
  letter-spacing: 0.2px;
  font-size: 0.9rem;
}
.dark.vynksafe_new_style p {
  color: #9ea4ab;
}
.vynksafe_new_style footer.footer_v h5 {
  font-size: 1rem !important;
  font-weight: 400;
}
p {
  margin-top: 0;
  margin-bottom: 14px;
}
a.page-link {
  border: none !important;
}
.pricing_table_v{
  background: transparent !important;
}
.pricing_table_v .page-item:last-child .page-link,
.page-item:first-child .page-link {
  color: #131722 !important;
  // background-color: #fff4f1 !important;
  // border: 1px solid rgba(250, 80, 28, 0.1882352941) !important;
  padding: 8px;
  border-radius: 3x !important;
  font-size: 14px;
}

li.active.page-item {
  background: #ffeee9;
  border-radius: 5px;
}
.dark li.active.page-item {
  background: #424957;
  border-radius: 5px;
}
li.page-item{
  border-radius: 5px;
  margin: 7px;
  width: 29px;
  margin: 7px;
  text-align: center;
}
li.page-item:hover{
  background: #ffeee9;
  color: #fa501c;
}
.dark li.page-item:hover{
  background: #424957;
  color: white;
}
.page-link:hover {
  text-decoration: none;
  background-color: #e9ecef00 !important;
  border-color: #dee2e600 !important;
}
.pricing_table_v .page-item .page-link {
  color: #131722 !important;
}
.dark .pricing_table_v .page-item .page-link {
  color: #fff !important;
}
body.dark .pricing_table_v .page-item:last-child .page-link,
body.dark .page-item:first-child .page-link {
  color: #fff !important;
  // background-color: #20242c !important;
  // border: 1px solid #282c34 !important;
}

.markets_pair_pricing_section nav.nav.nav-tabs {
  background: none;
  border: none !important;
}
.markets_pair_pricing_section a.nav-item.nav-link.active {
  border: 1px solid;
  border-radius: 3px;
  // height: 25px;
  // padding-top: 2px;
  width: 100px;
  text-align: center;
  background: #fa501c !important;
  color: #fff !important;
  /* border-bottom: none; */
}
.markets_pair_pricing_section a.nav-item.nav-link {
  border: 1px solid;
  border-radius: 3px;
  // height: 25px;
  // padding-top: 2px;
  width: 100px;
  text-align: center;
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
  color: #fa501c !important;
}
.dark .markets_pair_pricing_section a.nav-item.nav-link {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #fff !important;
}
.dark .markets_pair_pricing_section a.nav-item.nav-link.active {
  background: #fa501c24 !important;
  color: #fa501c !important;
  /* border-bottom: none; */
}
.markets_pair_pricing_section span.react-bootstrap-table-pagination-total {
  margin-left: 45px;
  position: absolute;
  /* margin-top: 35px; */
  top: 2px;
}

// SAVING PAGE

.markets_overview_section_v {
  padding: 9rem 0rem 3rem 0rem;
  background: #ffeee9;
}
.dark-gray-bg-v {
  background-color: #fff !important;
}

body.dark
  .markets_overview_section_v
  table
  tr
  td
  button.btn.deposit-btn-v:hover,
body.dark
  .markets_overview_section_v
  table
  tr
  td
  button.btn.withdraw-btn-v:hover {
  color: #fff !important;
}
body.dark .markets_overview_section_v {
  background-color: #131722;
}

.markets_overview_section_v table tr td {
  padding: 7px 7px 7px 0px;
  white-space: nowrap;
}

.markets_overview_section_v table tr td button.btn {
  color: #fa501c ;
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
}
.dark .vynksafe_new_style .markets_overview_section_v table tr td button.btn {
  background: #2c2e33;
  border: 1px solid #41464d !important;
  padding: 7px;
  border-radius: 3px !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
}
body.dark .benefits_main_section {
  background: #1b1f28;
}
.benefits_main_section {
  background: #ffeee9;
}
.normal_safepro_user_section p span {
  color: #131722 !important;
}
.dark .normal_safepro_user_section p span {
  color: #ffffff !important;
}
.apy_portfolio_details_v li {
  color: #595959;
}
.apy_portfolio_details_v ul {
  gap: 15px;
  display: grid;
}
body.dark .apy_portfolio_form_v label,
body.dark .apy_portfolio_details_v li {
  color: #9ea4ab;
}

body.dark .apy_portfolio_details_v li span {
  color: #ffffff !important;
}

.apy_portfolio_details_v {
  gap: 20px;
  display: grid;
  width: 75%;
}

.create_account {
  padding-top: 8rem !important;
}

.apy_portfolio_form_v label {
  color: #282929;
  white-space: nowrap;
  margin-right: 15px;
  margin-bottom: 0px;
}
.apy_portfolio_form_v input {
  text-align: right;
  color: #131722;
}
.apy_portfolio_form_v .form-group input:focus {
  box-shadow: none;
}
.apy_portfolio_form_v .form-group input {
  color: #fff;
}
.apy_portfolio_details_v li span {
  color: #131722 !important;
}
body.dark .apy_portfolio_form_v label,
body.dark .apy_portfolio_details_v li {
  color: #9ea4ab;
}
body.dark .apy_portfolio_form_v .form-group input {
  color: #ffffff;
}
.vynksafe_new_style hr {
  border-top: 1px solid rgb(89, 89, 89);
}
// body.dark.vynksafe_new_style hr {
//   border-top: 1px solid rgb(167, 166, 166);
// }
.dark ul.upper_header {
  border-top: 1px solid rgb(89, 89, 89);
}
ul.upper_header {
  border-top: 1px solid rgb(167, 166, 166);
}
span#basic-addon1 {
  background-color: #fa501c !important;
}

/*===========---------- Saving page css start -----------===============*/

.company_savings_left_img_v img {
  background-color: #572b1e;
  border-radius: 100%;
  z-index: 1;
  position: relative;
}

.transactions_pricing_table_v thead tr th {
  min-width: 20%;
}

.apy_portfolio_form_v label {
  color: #9ea4ab;
  white-space: nowrap;
  margin-right: 15px;
  margin-bottom: 0px;
}
.apy_portfolio_form_v input {
  text-align: right;
}
.apy_portfolio_details_v li {
  color: #9ea4ab;
}

.apy_portfolio_details_v {
  gap: 20px;
  display: grid;
  width: 75%;
}
.apy_portfolio_details_v ul {
  gap: 15px;
  display: grid;
}

footer.short_footer_v .short_footer_social_icons li a:hover img {
  background: #f6f9ff;
}

.apy_portfolio_form_v .form-group input:focus {
  box-shadow: none;
}
.apy_portfolio_form_v .form-group input {
  color: #fff;
}
.filters_section_v a {
  color: #9ea4ab;
}

#TransactionFilters {
  // display: none;
  // position: absolute;
  right: 0;
  z-index: 1;
  // background-color: #2d3340;
  color: #fff !important;
  border-radius: 3px;
}
#TransactionFilters.show {
  display: flex;
  flex-direction: column;
}
#TransactionFilters #close {
  background: none;
  border: none;
}
#TransactionFilters p {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 15px;
}
#TransactionFilters .markets_watch_table_tabs_content_radiobtn .btn.btn-gray-v {
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 100px !important;
  color: #9ea4ab;
}

#TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn-check:checked
  + .btn,
#TransactionFilters .markets_watch_table_tabs_content_radiobtn .btn.active,
#TransactionFilters .markets_watch_table_tabs_content_radiobtn .btn.show,
#TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn:first-child:active,
:not(.btn-check) + .markets_watch_table_tabs_content_radiobtn .btn:active {
  background: #fa501c24;
  color: #fa501c !important;
}

#TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn.btn-gray-v:hover {
  background: #fa501c24;
  color: #fa501c;
}
.date_transaction_v input {
  background: #fff4f1;
  border: 1px solid #fa501c30 !important;
  color: #595959 !important;
  padding: 8px;
  border-radius: 100px !important;

  transition: 0.3s ease-in-out;
  font-size: 12px;
  width: 100%;
}

body.dark .date_transaction_v input {
  background: #2c2e33;
  border: 1px solid #41464d;
  color: #fff;
}
/*===========---------- Saving page css end -----------===============*/
.markets_watch_table_tabs_content_radiobtn .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.markets_watch_table_tabs_content_radiobtn .btn-check:checked + .btn,
.markets_watch_table_tabs_content_radiobtn .btn.active,
.markets_watch_table_tabs_content_radiobtn .btn.show,
.markets_watch_table_tabs_content_radiobtn .btn:first-child:active,
:not(.btn-check) + .markets_watch_table_tabs_content_radiobtn .btn:active {
  background: #fa501c;
  color: #fff !important;
}
body.dark
  #TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn-check:checked
  + .btn,
body.dark
  #TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn.active,
body.dark
  #TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn.show,
body.dark
  #TransactionFilters
  .markets_watch_table_tabs_content_radiobtn
  .btn:first-child:active,
:not(.btn-check) + .markets_watch_table_tabs_content_radiobtn .btn:active {
  background: #fa501c24;
  color: #fa501c;
}

.filter_check {
  border-radius: 100px !important;
  padding: 4px 10px !important;
  height: 29px !important;
  border: 1px solid #41464d !important;
  font-size: 12px !important;
  width: 72px !important;
  color: #9ea4ab !important;
}

#TransactionFilters p {
  color: #131722 !important;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 15px;
}
.dark #TransactionFilters p {
  color: #fff !important;
  font-weight: normal;
}

.vynksafe_new_style .btn.btn-gray-v {
  background: #fff4f1 !important;

  padding: 8px;
  border-radius: 3px !important;
  /* min-height: 43px; */

  border: 1px solid #fa501c30 !important;
  color: #fa501c !important;
  transition: 0.3s ease-in-out;
}
body.dark.vynksafe_new_style .btn.btn-gray-v {
  background: #2c2e33 !important;
  border: 1px solid #41464d !important;
  color: #fff;
}

.pricing_table_v {
  height: 100%;
}

.bg-green-v {
  background: #04b98e !important;
  color: #fff;
}
.transactions_pricing_table_v th {
  font-size: 14px !important;
}
input.react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__day.react-daterange-picker__inputGroup__input--hasLeadingZero {
  background: transparent;
  border: none !important;
}
input.react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__month {
  background: transparent;
  border: none !important;
}
.react-daterange-picker__inputGroup input {
  background: transparent !important;
  border: none !important;
}
button.pagination_button.btn.btn-primary {
  color: #fb7730;
}
.deposit_button:hover {
  background-color: #04b98e !important;
  color: white !important;
}
.deposit_button {
  background-color: #04b98e !important;
  color: white !important;
}
.withdraw_button {
  background-color: #fe4845 !important;
  color: white !important;
}
.withdraw_button:hover{
  background-color: #fe4845 !important;
  color: white !important;
}
span.react-bootstrap-table-pagination-total.mt-4 {
  /* display: flex; */
  position: absolute;
  top: -22px;
  left: 68px;
}


.terms_content h3 {
  // color: #1dc0f4;
  font-size: 28px;
  padding-bottom: 30px !important;
  margin-bottom: 30px !important;
  line-height: 38px;
  border-bottom: 1px solid rgb(255 255 255 / 32%);
}
.terms_content p {
  line-height: 25px;
  margin-top: 10px!important;
  margin-bottom: 10px!important;
  font-family: 'poppins', sans-serif;
}
.terms_content p {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 36px;
  text-align: justify;
}
ol {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}
ol li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
  color: #000000;
}
.dark ol li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
  color: white;
}
ol li::before {
  content: counter(my-awesome-counter);
  color: #000000;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  text-align: center;
}
.dark ol li::before {
  content: counter(my-awesome-counter);
  color: #ffffff;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  text-align: center;
}

.boxShadowforMarket {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.marketDepositButton{
  background: transparent !important; 
    border: none!important; 
    padding: none; 
    border-radius: 3px !important; 
    color: #fff !important; 
    transition: 0.3s ease-in-out;
}
.marketDepositButton:hover{
  color: #04b98e !important;
}
.marketWithdrawButto{
  background: transparent !important; 
    border: none!important; 
    padding: none; 
    border-radius: 3px !important; 
    color: #fff !important; 
    transition: 0.3s ease-in-out;
}
.marketWithdrawButto:hover{
  color: #F6465D !important;
}

.Top_Gainers{
  padding: 5px;
}


.dark .card {
  width: 100%;
  border-radius: 1rem;
  background: #fff0 !important;
  box-sizing: border-box;

 .placeholder {
    background: #282d3c; 
    border-radius: 1rem;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100px;
      background: linear-gradient(90deg, rgb(19 23 34 / 0%), rgb(74 80 98 / 5%), rgb(19 23 34 / 5%));
      animation: gradient 0.5s infinite ease-in-out;
    }
  }
  
  .image-placeholder {
    aspect-ratio: 16 / 12;
    margin-bottom: 1rem;
  }

  .title-placeholder {
    aspect-ratio: 16 / 1.5;
    margin-bottom: 2rem;
  }

  .text-placeholder {
    aspect-ratio: 16 / 1;
    margin-top: 15px;
    margin-bottom: 0;
  }

  @keyframes gradient {
    from {
      left: 0%;
    }

    to {
      left: 100%;
    }
  }
}


.card {
  width: 100%;
  // border-radius: 1rem;/
  background: #fff0 !important;
  box-sizing: border-box;
  border: none !important;

  .placeholder {
    background: #b5b9bd; 
    border-radius: 1rem;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100px;
      background: linear-gradient(90deg, rgb(19 23 34 / 0%), rgb(19 23 34 / 2%), rgb(19 23 34 / 0%));
      animation: gradient 0.5s infinite ease-in-out;
    }
  }

  .image-placeholder {
    aspect-ratio: 16 / 12;
    margin-bottom: 1rem;
  }

  .title-placeholder {
    aspect-ratio: 16 / 1.5;
    margin-bottom: 2rem;
  }

  .text-placeholder {
    aspect-ratio: 16 / 1;
    margin-top: 15px;
    margin-bottom: 0;
  }

  @keyframes gradient {
    from {
      left: 0%;
    }

    to {
      left: 100%;
    }
  }
}



img.vert-move {
  -webkit-animation: mover 1.5s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1.5s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
}


div#accordionExample {
  border-bottom: 1px solid #41464d;
  padding-bottom: 10px;
  margin-bottom: 10px;
}