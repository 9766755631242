.order-book table,
.order-book tr,
.order-book td,
.order-book th {
  // width: 100%;
  // table-layout: fixed;
  overflow: auto;
}

.order-book tr {
  // float: left;
  // clear: both;
  overflow: hidden;
}

.order-book .table thead th {
  font-weight: 400;
  font-size: 12px;

}

.order-book thead tr {
  // border-bottom: 1px solid #e0e3eb;
  border-bottom: 1px solid #42464D;
}

.order-book tbody tr,
.order-book tbody td {
  border: none;
  position: relative;
  z-index: 2;
  line-height: 12px;
  padding: 4px 15px !important;
}

.order-book tbody.ob-heading tr td {
  line-height: 20px;
}

.order-book {
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  height: 100%;
}

.red-bg-80,
.red-bg-60,
.red-bg-51,
.red-bg-40,
.red-bg-20,
.red-bg-10,
.red-bg-8,
.red-bg-5,
.red-bg,
.green-bg-80,
.green-bg-60,
.green-bg-51,
.green-bg-40,
.green-bg-20,
.green-bg-10,
.green-bg-8,
.green-bg-5,
.green-bg {
  position: relative;
}

.red-bg-100:after,
.red-bg-95:after,
.red-bg-90:after,
.red-bg-85:after,
.red-bg-80:after,
.red-bg-75:after,
.red-bg-70:after,
.red-bg-65:after,
.red-bg-60:after,
.red-bg-55:after,
.red-bg-50:after,
.red-bg-45:after,
.red-bg-40:after,
.red-bg-35:after,
.red-bg-30:after,
.red-bg-25:after,
.red-bg-20:after,
.red-bg-15:after,
.red-bg-10:after,
.red-bg-8:after,
.red-bg-5:after,
.red-bg:after {
  position: absolute;
  content: "";
  background: #de26262e !important;
  right: 0;
  top: 0;
  width: 280px;
  height: 100%;
}

.red-bg-100:after {
  width: 100%;
}

.red-bg-95:after {
  width: 95%;
}

.red-bg-90:after {
  width: 90%;
}

.red-bg-85:after {
  width: 85%;
}

.red-bg-80:after {
  width: 80%;
}

.red-bg-75:after {
  width: 75%;
}

.red-bg-70:after {
  width: 70%;
}

.red-bg-65:after {
  width: 65%;
}

.red-bg-60:after {
  width: 60%;
}

.red-bg-55:after {
  width: 55%;
}

.red-bg-50:after {
  width: 50%;
}

.red-bg-45:after {
  width: 45%;
}

.red-bg-40:after {
  width: 40%;
  //  background: #de26262e !important;
}

.red-bg-35:after {
  width: 35%;
}

.red-bg-30:after {
  width: 30%;
}

.red-bg-25:after {
  width: 25%;
}

.red-bg-20:after {
  width: 20%;
}

.red-bg-15:after {
  width: 15%;
}

.red-bg-10:after {
  width: 10%;
  background-color: #ff231f2e;
}

.red-bg-5:after {
  width: 5%;
}

.red-bg:after {
  width: 2%;
}

.red-bg:after {
  width: 30px;
}

.green-bg-100:after,
.green-bg-95:after,
.green-bg-90:after,
.green-bg-85:after,
.green-bg-80:after,
.green-bg-75:after,
.green-bg-70:after,
.green-bg-65:after,
.green-bg-60:after,
.green-bg-55:after,
.green-bg-50:after,
.green-bg-45:after,
.green-bg-40:after,
.green-bg-35:after,
.green-bg-30:after,
.green-bg-25:after,
.green-bg-20:after,
.green-bg-15:after,
.green-bg-10:after,
.green-bg-8:after,
.green-bg-5:after,
.green-bg:after {
  position: absolute;
  content: "";
  background: #26de812e;
  right: 0;
  top: 0;
  width: 280px;
  height: 100%;
  display: flex;
}

.green-bg-100:after {
  width: 100%;
}

.green-bg-95:after {
  width: 95%;
}

.green-bg-90:after {
  width: 90%;
}

.green-bg-85:after {
  width: 85%;
}

.green-bg-80:after {
  width: 80%;
}

.green-bg-75:after {
  width: 75%;
}

.green-bg-70:after {
  width: 70%;
}

.green-bg-65:after {
  width: 65%;
}

.green-bg-60:after {
  width: 60%;
}

.green-bg-55:after {
  width: 55%;
}

.green-bg-50:after {
  width: 50%;
}

.green-bg-45:after {
  width: 45%;
}

.green-bg-40:after {
  width: 40%;
}

.green-bg-35:after {
  width: 35%;
}

.green-bg-30:after {
  width: 30%;
}

.green-bg-25:after {
  width: 25%;
}

.green-bg-20:after {
  width: 20%;
}

.green-bg-15:after {
  width: 15%;
}

.green-bg-10:after {
  width: 10%;
}

.green-bg-5:after {
  width: 5%;
}

.green-bg:after {
  width: 2%;
}

.ob-heading span {
  color: #4a4a4a;
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.ob-heading td {
  //padding: 10px 15px;
  padding: 5px;
  cursor: default;
}

.ob-heading td:nth-child(2),
.ob-heading td:last-child {
  text-align: right;
}

.ob-heading tr {
  border-top: 1px solid #f0f3fa !important;
  border-bottom: 1px solid #f0f3fa !important;
}

.ob-heading tr:hover {
  background: #fff;
}

// .ob-heading td:first-child {
//   width: 65%;
// }

.order-book tbody.f_tbody {
  height: 300px;
}

.order-book tbody {
  overflow-y: auto;
  // height: 50px;
  position: relative;

}

.dark .order-book tr td.no-data {
  color: #696a6a;
}

.orderbook_scroll {
  scrollbar-width: none;
  overflow: auto;
  position: relative;
  // height: 500px !important;

}

.order-book tbody.f_tbody tr {
  z-index: -3;
}

.order-book tbody tr td.no-data {
  // top: 110px;
}

.order-book tbody tr td.td_align {
  text-align: right;
}

.order-book .table thead th:nth-child(2) {
  text-align: right;
  //width:39%;
  //padding: 10px !important;
}

.order-book .table thead th:last-child {
  text-align: right;
}

.order-book .table thead th:first-child {
  width: 100px;
}

.order-book tbody tr td:nth-child(2) {
  text-align: right;
  width: 100px;
  //padding: 10px !important;
}

.order-book tbody tr td:last-child {
  text-align: right;
}


tbody.ob-heading {
  height: inherit;
}

.table td,
.table th {
  vertical-align: middle;
}

.order_book {
  // min-height: 450px !important;
  // max-height: 450px;
  // height: 405px !important;
  height: 450px !important;
  padding-bottom: 70px;
  // overflow-y: auto;
  width: 100%;
  // padding-bottom: 40px;
  // overflow-x: hidden;
}


.order_book.order_book_left_top{
    // justify-content: space-between;
    // display: grid;
    // align-content: space-between;
}

.order_book.order_book_left_top .table:first-class{
    justify-content: space-between;
    display: grid;
}

// .order_book.order_book_left_top tr{
//   display: revert;
// }
.order-book tbody tr {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  }
 
  .order_book.order_book_left_top tr.no_data_tr {
    display: grid;
    height: 100%;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: baseline;
}
.order_book.order_book_left_top {
  overflow: hidden;
}